import { REHYDRATE } from "redux-persist";
import {
  GET_WEAPON_CALIBRE,
  GET_WEAPON_CONDITION,
  GET_WEAPON_NOSERIAL_REASON,
  GET_WEAPON_OWNERSHIP,
  GET_WEAPON_STATUS,
  GET_WEAPON_TYPES,
  GET_WEAPON_JVT_LIST,
  GET_WEAPON_MSD,
  RESET_WEAPON_MSD,
  GET_MANUFACTURER_LIST,
  GET_MANUFACTURING_COUNTRY_LIST,
  GET_STOCKPILE,
  GET_TECHNICAL_STATUS,
  GET_HAZARD_DIVISION,
  COMPATIBILITY_GROUP,
  AMMUNITION_ROLES,
  AMMUNITION_CALIBRE,
  AMMUNITION_TYPES,
  AMMUNITION_STATUS,
  GET_MODEL_LIST,
  RESET_MODEL_LIST,
  GET_CAT1_LIST,
  RESET_CAT1_LIST,
  GET_CAT2_LIST,
  RESET_CAT2_LIST,
  GET_CAT3_LIST,
  RESET_CAT3_LIST,
} from "../ActionTypes/weaponAmmoDropDown";

interface InitialState {
  weaponTypes: any;
  weaponCalibre: any;
  weaponStatus: any;
  weaponCondition: any;
  weaponOwnership: any;
  weaponNoSerialNoReasons: any;
  weaponJVTList: any;
  weaponMsdList: any;
  weaponMsdListLoading: boolean;
  manufacturerList: any;
  countryList: any;
  stkPileList: any;
  technicalStatus: any;
  hazardDivision: any;
  compatibilityGrps: any;
  ammunitionRoles: any;
  ammunitionCalibre: any;
  ammunitionTypes: any;
  ammunitionStatus: any;
  cat1List: any;
  cat2List: any;
  cat3List: any;
  modelList: any;
  modelListLoading: boolean;
}
const initialState: InitialState = {
  weaponTypes: [],
  weaponCalibre: [],
  weaponStatus: [],
  weaponCondition: [],
  weaponOwnership: [],
  weaponNoSerialNoReasons: [],
  weaponJVTList: [],
  weaponMsdList: [],
  weaponMsdListLoading: true,
  manufacturerList: [],
  countryList: [],
  stkPileList: [],
  technicalStatus: [],
  hazardDivision: [],
  compatibilityGrps: [],
  ammunitionRoles: [],
  ammunitionCalibre: [],
  ammunitionTypes: [],
  ammunitionStatus: [],
  cat1List: [],
  cat2List: [],
  cat3List: [],
  modelList: [],
  modelListLoading: true,
};

const WeaponAmmoDropDownReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_WEAPON_TYPES:
      return {
        ...state,
        weaponTypes: action.payload,
      };
    case GET_WEAPON_CALIBRE:
      return {
        ...state,
        weaponCalibre: action.payload,
      };
    case GET_WEAPON_STATUS:
      return {
        ...state,
        weaponStatus: action.payload,
      };
    case GET_WEAPON_CONDITION:
      return {
        ...state,
        weaponCondition: action.payload,
      };
    case GET_MODEL_LIST:
      return {
        ...state,
        modelList: action.payload,
        modelListLoading: false,
      };
    case RESET_MODEL_LIST:
      return {
        ...state,
        modelList: [],
        modelListLoading: true,
      };
    case GET_WEAPON_OWNERSHIP:
      return {
        ...state,
        weaponOwnership: action.payload,
      };
    case GET_WEAPON_NOSERIAL_REASON:
      return {
        ...state,
        weaponNoSerialNoReasons: action.payload,
      };
    case GET_WEAPON_JVT_LIST:
      return {
        ...state,
        weaponJVTList: action.payload,
      };
    case GET_WEAPON_MSD:
      return {
        ...state,
        weaponMsdList: action.payload,
        weaponMsdListLoading: false,
      };
    case RESET_WEAPON_MSD:
      return {
        ...state,
        weaponMsdList: [],
        weaponMsdListLoading: true,
      };
    case GET_MANUFACTURER_LIST:
      return {
        ...state,
        manufacturerList: action.payload,
      };
    case GET_MANUFACTURING_COUNTRY_LIST:
      return {
        ...state,
        countryList: action.payload,
      };
    case GET_STOCKPILE:
      return {
        ...state,
        stkPileList: action.payload,
      };
    case GET_TECHNICAL_STATUS:
      return {
        ...state,
        technicalStatus: action.payload,
      };
    case GET_HAZARD_DIVISION:
      return {
        ...state,
        hazardDivision: action.payload,
      };
    case COMPATIBILITY_GROUP:
      return {
        ...state,
        compatibilityGrps: action.payload,
      };
    case AMMUNITION_ROLES:
      return {
        ...state,
        ammunitionRoles: action.payload,
      };
    case AMMUNITION_CALIBRE:
      return {
        ...state,
        ammunitionCalibre: action.payload,
      };
    case AMMUNITION_TYPES:
      return {
        ...state,
        ammunitionTypes: action.payload,
      };
    case AMMUNITION_STATUS:
      return {
        ...state,
        ammunitionStatus: action.payload,
      };
    case GET_CAT1_LIST:
      return {
        ...state,
        cat1List: action.payload,
      };
    case GET_CAT2_LIST:
      return {
        ...state,
        cat2List: action.payload,
      };
    case GET_CAT3_LIST:
      return {
        ...state,
        cat3List: action.payload,
      };
    case RESET_CAT1_LIST:
      return {
        ...state,
        cat1List: [],
      };
    case RESET_CAT2_LIST:
      return {
        ...state,
        cat2List: [],
      };
    case RESET_CAT3_LIST:
      return {
        ...state,
        cat3List: [],
      };

    case REHYDRATE:

    default:
      return state;
  }
};

export default WeaponAmmoDropDownReducer;
