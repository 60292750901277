import {
  GET_TRANSFER_AMMUNITION_PENDING,
  GET_MORE_TRANSFER_AMMUNITION_PENDING,
  GET_TRANSFER_AMMUNITION_SUCCESS,
  GET_MORE_TRANSFER_AMMUNITION_SUCCESS,
  RESET_TRANSFER_AMMUNITION,
  GET_MORE__TRANSIT_TRANSFER_AMMUNITION_PENDING,
  GET_MORE__TRANSIT_TRANSFER_AMMUNITION_SUCCESS,
  GET_TRANSIT_TRANSFER_AMMUNITION_PENDING,
  GET__TRANSIT_TRANSFER_AMMUNITION_SUCCESS,
  RESET__TRANSIT_TRANSFER_AMMUNITION,
} from "../ActionTypes/simpleTransferAmmo";
import { REHYDRATE } from "redux-persist";

interface InitialState {
  simpleTransferAmmoLoading: boolean;
  moreSimpleTransferAmmoLoading: boolean;
  simpleTransferAmmoList: any;
  simpleTransferAmmoListCount: number;
  simpleTransferAmmoPage: number;
  simpleTransferAmmoExportQuery: any;

  simpleTransitAmmunitionLoading: boolean;
  moreSimpleTransitAmmunitionLoading: boolean;
  simpleTransitAmmunitionList: any;
  simpleTransitAmmunitionListCount: number;
  simpleTransitAmmunitionPage: number;
  simpleTransitExportQuery: any;
}

const initialState: InitialState = {
  simpleTransferAmmoLoading: false,
  moreSimpleTransferAmmoLoading: false,
  simpleTransferAmmoList: [],
  simpleTransferAmmoListCount: 0,
  simpleTransferAmmoPage: 1,
  simpleTransferAmmoExportQuery: [],

  simpleTransitAmmunitionLoading: false,
  moreSimpleTransitAmmunitionLoading: false,
  simpleTransitAmmunitionList: [],
  simpleTransitAmmunitionListCount: 0,
  simpleTransitAmmunitionPage: 1,
  simpleTransitExportQuery: [],
};

const SimpleTransferAmmoReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_TRANSFER_AMMUNITION_PENDING:
      return {
        ...state,
        simpleTransferAmmoLoading: true,
      };
    case GET_MORE_TRANSFER_AMMUNITION_PENDING:
      return {
        ...state,
        moreSimpleTransferAmmoLoading: true,
      };
    case GET_TRANSFER_AMMUNITION_SUCCESS:
      return {
        ...state,
        simpleTransferAmmoLoading: false,
        simpleTransferAmmoList: action.payload.ammunition_details,
        simpleTransferAmmoListCount: action.payload.count,
        simpleTransferAmmoExportQuery: action.payload.dynamic_filter_query,
        simpleTransferAmmoPage: 2,
      };
    case GET_MORE_TRANSFER_AMMUNITION_SUCCESS:
      const newSimpleTransferAmmoList = action.payload.ammunition_details;
      const { simpleTransferAmmoList } = state;
      return {
        ...state,
        moreSimpleTransferAmmoLoading: false,
        simpleTransferAmmoList: [
          ...simpleTransferAmmoList,
          ...newSimpleTransferAmmoList,
        ],
        simpleTransferAmmoListCount: action.payload.count,
        simpleTransferAmmoExportQuery: action.payload.dynamic_filter_query,
        simpleTransferAmmoPage: state.simpleTransferAmmoPage + 1,
      };
    case RESET_TRANSFER_AMMUNITION:
      return {
        ...state,
        simpleTransferAmmoLoading: false,
        moreSimpleTransferAmmoLoading: false,
        simpleTransferAmmoList: [],
        simpleTransferAmmoListCount: 0,
        simpleTransferAmmoExportQuery: [],
        simpleTransferAmmoPage: 1,
      };
    case GET_TRANSIT_TRANSFER_AMMUNITION_PENDING:
      return {
        ...state,
        simpleTransitAmmunitionLoading: true,
      };
    case GET_MORE__TRANSIT_TRANSFER_AMMUNITION_PENDING:
      return {
        ...state,
        moreSimpleTransitAmmunitionLoading: true,
      };
    case GET__TRANSIT_TRANSFER_AMMUNITION_SUCCESS:
      return {
        ...state,
        simpleTransitAmmunitionLoading: false,
        simpleTransitAmmunitionList: action.payload.ammunition_details,
        simpleTransitAmmunitionListCount: action.payload.count,
        simpleTransitExportQuery: action.payload.export_query,
        simpleTransitAmmunitionPage: 2,
      };
    case GET_MORE__TRANSIT_TRANSFER_AMMUNITION_SUCCESS:
      const newTransitAmmunitionList = action.payload.ammunition_details;
      const { simpleTransitAmmunitionList } = state;
      return {
        ...state,
        moreSimpleTransitAmmunitionLoading: false,
        simpleTransitAmmunitionList: [
          ...simpleTransitAmmunitionList,
          ...newTransitAmmunitionList,
        ],
        simpleTransitAmmunitionListCount: action.payload.count,
        simpleTransitExportQuery: action.payload.export_query,
        simpleTransitAmmunitionPage: state.simpleTransitAmmunitionPage + 1,
      };
    case RESET__TRANSIT_TRANSFER_AMMUNITION:
      return {
        ...state,
        simpleTransitAmmunitionLoading: false,
        moreSimpleTransitAmmunitionLoading: false,
        simpleTransitAmmunitionList: [],
        simpleTransitAmmunitionListCount: 0,
        simpleTransitExportQuery: [],
        simpleTransitAmmunitionPage: 1,
      };
    case REHYDRATE:
    default:
      return state;
  }
};

export default SimpleTransferAmmoReducer;
