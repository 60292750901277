import { REHYDRATE } from "redux-persist";
import {
  GET_REGIONS,
  GET_DISTRICTS,
  GET_STATESLIST,
  SET_REGIONS,
  SET_DISTRICTS,
  CLEAR_DISTRICTS,
  CLEAR_REGIONS,
  CLEAR_STATELIST,
} from "../ActionTypes";

interface initialState {
  regions: any;
  regionsLoading: boolean;
  districts: any;
  districtsLoading: boolean;
  stateList: any;
  stateListLoading: boolean;
  armouries: any;
  armouriesLoading: boolean;
}
const initialState = {
  regions: [],
  regionsLoading: true,
  districts: [],
  districtsLoading: true,
  stateList: [],
  stateListLoading: true,
  armouries: [],
  armouriesLoading: true,
};

const LocationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_REGIONS:
    case GET_REGIONS:
      return {
        ...state,
        regions: action.payload,
        regionsLoading: false,
      };

    case CLEAR_REGIONS:
      return {
        ...state,
        regions: action.empty,
        regionsLoading: true,
      };

    case SET_DISTRICTS:
    case GET_DISTRICTS:
      return {
        ...state,
        districts: action.payload,
        districtsLoading: false,
      };
    case CLEAR_DISTRICTS:
      return {
        ...state,
        districts: action.empty,
        districtsLoading: true,
      };

    case GET_STATESLIST:
      return {
        ...state,
        stateList: action.payload,
        stateListLoading: false,
      };
    case CLEAR_STATELIST:
      return {
        ...state,
        stateList: action.empty,
        stateListLoading: true,
      };
    case REHYDRATE:

    default:
      return state;
  }
};

export default LocationReducer;
