import { get } from "lodash";
import { toast } from "react-toastify";
import request from "../../services/HttpMethods";
import store from "..";
import {
  GET_LOCATORS,
  RESET_LOCATORS,
  GET_COUNT,
  GET_IMPORT_AMMUNITION_COUNT,
  GET_IMPORT_WEAPON_COUNT,
} from "../ActionTypes";
import moment from "moment";
import { errorNotify } from "../../constants/common";

const { dispatch }: any = store || {};

export const getImportWeaponCount = async (importId: any) => {
  try {
    const queryStr = `/api/imports/total_counts?module=Weapons&import_id=${importId}`;

    const response = await request.get(queryStr);
    if (response.status === 200) {
      const count = get(response, "data");
      dispatch({
        type: GET_IMPORT_WEAPON_COUNT,
        payload: count,
      });
    }
  } catch (error: any) {
    if (
      error?.response?.status !== 502 ||
      (error?.response?.status !== 422 &&
        error?.response?.data.detail !== "Signature has expired")
    ) {
      errorNotify();
    }
  }
};

export const getImportAmmunitionCount = async (importId: any) => {
  try {
    const queryStr = `/api/imports/total_counts?module=Ammunitions&import_id=${importId}`;

    const response = await request.get(queryStr);
    if (response.status === 200) {
      const count = get(response, "data");
      dispatch({
        type: GET_IMPORT_AMMUNITION_COUNT,
        payload: count,
      });
    }
  } catch (error: any) {
    // console.log(error) --error handled elsewhere
  }
};

export const getUnitListCount = async (sfId: any, divId: any, unitId: any) => {
  try {
    let queryStr = "/api/homepage_module_count?module=Unit";
    if (sfId) {
      queryStr = `${queryStr}&sf_id=${sfId}`;
    }
    if (divId) {
      queryStr = `${queryStr}&div_id=${divId}`;
    }
    if (unitId) {
      queryStr = `${queryStr}&unit_id=${unitId}`;
    }

    const response = await request.get(queryStr);
    if (response.status === 200) {
      const count = get(response, "data");
      dispatch({
        type: GET_COUNT.UNITS,
        payload: count,
      });
    }
  } catch (error: any) {
    // console.log(error) --error handled elsewhere
  }
};

export const getFacilityListCount = async (
  sfId: any,
  divId: any,
  unitId: any
) => {
  try {
    let queryStr = "/api/homepage_module_count?module=Facility";
    if (sfId) {
      queryStr = `${queryStr}&sf_id=${sfId}`;
    }
    if (divId) {
      queryStr = `${queryStr}&div_id=${divId}`;
    }
    if (unitId) {
      queryStr = `${queryStr}&unit_id=${unitId}`;
    }

    const response = await request.get(queryStr);
    if (response.status === 200) {
      const count = get(response, "data");
      dispatch({
        type: GET_COUNT.FACILITY,
        payload: count,
      });
    }
  } catch (error: any) {
    // console.log(error) --error handled elsewhere
  }
};

export const getWeaponListCount = async (
  sfId: any,
  divId: any,
  unitId: any
) => {
  try {
    let queryStr = "/api/homepage_module_count?module=Weapon";
    if (sfId) {
      queryStr = `${queryStr}&sf_id=${sfId}`;
    }
    if (divId) {
      queryStr = `${queryStr}&div_id=${divId}`;
    }
    if (unitId) {
      queryStr = `${queryStr}&unit_id=${unitId}`;
    }
    const response = await request.get(queryStr);
    if (response.status === 200) {
      const count = get(response, "data");
      dispatch({
        type: GET_COUNT.WEAPONS,
        payload: count,
      });
    }
  } catch (error: any) {
    // console.log(error) --error handled elsewhere
  }
};

export const getPersonnelListCount = async (
  sfId: any,
  divId: any,
  unitId: any
) => {
  try {
    let queryStr = "/api/homepage_module_count?module=Personnel";
    if (sfId) {
      queryStr = `${queryStr}&sf_id=${sfId}`;
    }
    if (divId) {
      queryStr = `${queryStr}&div_id=${divId}`;
    }
    if (unitId) {
      queryStr = `${queryStr}&unit_id=${unitId}`;
    }
    const response = await request.get(queryStr);
    if (response.status === 200) {
      const count = get(response, "data");
      dispatch({
        type: GET_COUNT.PERSONNEL,
        payload: count,
      });
    }
  } catch (error: any) {
    // console.log(error) --error handled elsewhere
  }
};

export const getAmmunitionListCount = async (
  sfId: any,
  divId: any,
  unitId: any
) => {
  try {
    let queryStr = "/api/homepage_module_count?module=Ammunition";
    if (sfId) {
      queryStr = `${queryStr}&sf_id=${sfId}`;
    }
    if (divId) {
      queryStr = `${queryStr}&div_id=${divId}`;
    }
    if (unitId) {
      queryStr = `${queryStr}&unit_id=${unitId}`;
    }
    const response = await request.get(queryStr);
    if (response.status === 200) {
      const count = get(response, "data");
      dispatch({
        type: GET_COUNT.AMMUNITION,
        payload: count,
      });
    }
  } catch (error: any) {
    // console.log(error) --error handled elsewhere
  }
};

export const getUserListCount = async (
  levelId: any,
  sfId: any,
  divId: any,
  unitId: any
) => {
  try {
    let queryStr = "/api/homepage_module_count?module=User";

    queryStr = `${queryStr}&level_id=${levelId}`;

    if (sfId) {
      queryStr = `${queryStr}&sf_id=${sfId}`;
    }
    if (divId) {
      queryStr = `${queryStr}&div_id=${divId}`;
    }
    if (unitId) {
      queryStr = `${queryStr}&unit_id=${unitId}`;
    }
    const response = await request.get(queryStr);
    if (response.status === 200) {
      const count = get(response, "data");
      dispatch({
        type: GET_COUNT.USERS,
        payload: count,
      });
    }
  } catch (error: any) {
    // console.log(error) --error handled elsewhere
  }
};

export const getRoleListCount = async (sfId: any, divId: any, unitId: any) => {
  try {
    let queryStr = "/api/homepage_module_count?module=Role";
    if (sfId) {
      queryStr = `${queryStr}&sf_id=${sfId}`;
    }
    if (divId) {
      queryStr = `${queryStr}&div_id=${divId}`;
    }
    if (unitId) {
      queryStr = `${queryStr}&unit_id=${unitId}`;
    }
    const response = await request.get(queryStr);
    if (response.status === 200) {
      const count = get(response, "data");
      dispatch({
        type: GET_COUNT.ROLES,
        payload: count,
      });
    }
  } catch (error: any) {
    // console.log(error) --error handled elsewhere
  }
};

export const getTransferListCount = async (
  sfId: any,
  divId: any,
  unitId: any,
  levelId: any
) => {
  try {
    let queryStr = "/api/homepage_module_count?module=Transfer";
    if (sfId) {
      queryStr = `${queryStr}&sf_id=${sfId}`;
    }
    if (divId) {
      queryStr = `${queryStr}&div_id=${divId}`;
    }
    if (unitId) {
      queryStr = `${queryStr}&unit_id=${unitId}`;
    }
    if (levelId) {
      queryStr = `${queryStr}&level_id=${levelId}`;
    }
    const response = await request.get(queryStr);
    if (response.status === 200) {
      const count = get(response, "data");
      dispatch({
        type: GET_COUNT.TRANSFERS,
        payload: count,
      });
    }
  } catch (error: any) {
    // console.log(error) --error handled elsewhere
  }
};

export const getSpotcheckListCount = async (
  sfId: any,
  divId: any,
  unitId: any
) => {
  try {
    let queryStr = "/api/homepage_module_count?module=Spotcheck";
    if (sfId) {
      queryStr = `${queryStr}&sf_id=${sfId}`;
    }
    if (divId) {
      queryStr = `${queryStr}&div_id=${divId}`;
    }
    if (unitId) {
      queryStr = `${queryStr}&unit_id=${unitId}`;
    }
    const response = await request.get(queryStr);
    if (response.status === 200) {
      const count = get(response, "data");
      dispatch({
        type: GET_COUNT.SPOTCHECKS,
        payload: count,
      });
    }
  } catch (error: any) {
    // console.log(error) --error handled elsewhere
  }
};

export const getImportListCount = async () => {
  try {
    const queryStr = "/api/homepage_module_count?module=Imports";

    const response = await request.get(queryStr);
    if (response.status === 200) {
      const count = get(response, "data");
      dispatch({
        type: GET_COUNT.IMPORTS,
        payload: count,
      });
    }
  } catch (error: any) {
    // console.log(error) --error handled elsewhere
  }
};

export const getLocators = async (sfId: any, divId: any, unitId: any) => {
  try {
    let queryStr = "/api/home_page/facility_list?";

    if (sfId) {
      queryStr = `${queryStr}&sf_id=${sfId}`;
    }
    if (divId) {
      queryStr = `${queryStr}&div_id=${divId}`;
    }
    if (unitId) {
      queryStr = `${queryStr}&unit_id=${unitId}`;
    }

    const response = await request.get(queryStr);
    if (response.status === 200) {
      const locators = get(response, "data");
      dispatch({
        type: GET_LOCATORS,
        payload: locators,
      });
    }
  } catch (error: any) {
    // console.log(error) --error handled elsewhere
  }
};

export const resetLocators = () => {
  dispatch({
    type: RESET_LOCATORS,
  });
};
