export default {
  Home: {
    All: "All",
    Facility: "Facilities",
    Weapon: "Weapons",
    Ammunition: "Ammunition",
  },
  Sidebar: {
    Home: "Home",
    Units: "Units",
    Facilities: "Facilities",
    Weapons: "Weapons",
    Ammunitions: "Ammunition",
    Personnel: "Personnel",
    Imports: "Imports",
    Transfers: "Transfers",
    SpotCheck: "Spot Checks",
    Reports: "Reports",
    Admin: "Admin",
    Users: "Users",
    Roles: "Roles",
    AuditLog: "Audit Log",
    Dropdown: "Dropdown List Data",
    PersonalDetails: "Personal Details",
    SignOut: "Sign Out",
    LanguageSelection: "Language selections",
    LogoutConfirm: "Are you sure you want to logout?",
    LanguageChangeConfirm:
      "Are you sure you want to switch the language to Somali?",
    Confirmation: "Confirmation",
    Yes: "Yes",
    No: "No",
  },

  Roles: {
    Name: "Name",
    SecurityForce: "Security Force",
    SectorDivision: "Sector/Division",
    Unit: "Unit",
    Status: "Status",
    Role: "Role",
    Level: "Level",
    Division: "Division",
    Personnel: "Personnel",
    User: "User",
    Roles: "Roles",
    Armoury: "Facility",
    Facility: "Facility",
    Weapons: "Weapons",
    Ammunition: "Ammunition",
    Import: "Import",
    "Spot Check": "Spot Check",
    SpotCheck: "Spot Check",
    Transfer: "Transfer",
    Settings: "Settings",
    Report: "Report",
    CreatePersonnel: "Create Personnel",
    UpdatePersonnel: "Update Personnel",
    ViewPersonnel: "View Personnel",
    ListPersonnel: "List Personnel",
    ListUser: "List User",
    ViewUser: "View User",
    UpdateUser: "Update User",
    ListRoles: "List Roles",
    CreateRoles: "Create Roles",
    CreateRole: "Create Role",
    UpdateRoles: "Update Roles",
    UpdateRole: "Update Role",
    EditRole: "Edit Role",
    ViewRoles: "View Roles",
    CreateFacility: "Create Facility",
    UpdateFacility: "Update Facility",
    ViewFacility: "View Facility",
    ListFacility: "List Facility",
    CreateWeapons: "Create Weapon",
    UpdateWeapons: "Update Weapon",
    ViewWeapons: "View Weapons",
    ListWeapons: "List Weapons",
    AssignWeapon: "Assign Weapon",
    CreateAmmunition: "Create Ammunition",
    UpdateAmmunition: "Update Ammunition",
    ViewAmmunition: "View Ammunition",
    ListAmmunition: "List Ammunition",
    ListAmmunitions: "List Ammunition",
    AssignAmmunition: "Assign Ammunition",
    CreateUnit: "Create Unit",
    UpdateUnit: "Update Unit",
    ViewUnit: "View Unit",
    ListUnit: "List Unit",
    CreateImport: "Create Import",
    UpdateImport: "Update Import",
    ViewImport: "View Import",
    ListImports: "List Imports",
    CreateSpotCheck: "Create Spot Check",
    UpdateSpotCheck: "Update Spot Check",
    ViewSpotCheck: "View Spot Check",
    ListSpotChecks: "List Spot Checks",
    CreateTransfer: "Create Transfer",
    UpdateTransfer: "Update Transfer",
    ViewTransfer: "View Transfer",
    ListTransfer: "List Transfer",
    CreateReport: "Create Report",
    UpdateReport: "Update Report",
    ViewReport: "View Report",
    ListReport: "List Report",
    ViewWeaponReports: "View Weapon Reports",
    ExportWeaponReports: "Export Weapon Reports",
    ViewAmmunitionReports: "View Ammunition Reports",
    ExportAmmunitionReports: "Export Ammunition Reports",
    ViewImportReports: "View Import Reports",
    ViewSpotCheckReports: "View SpotCheck Reports",
    ExportImportReports: "Export Import Reports",
    ExportSpotCheckReports: "Export SpotCheck Reports",
    ChoosePermissionLevel: "Choose Permission Level",
    AvailablePermissionsFor: "Available Permissions",
    AllPermissionsGranted: "All Permissions Granted",
    ThisRole: "this role ?",
    Permissions: "Permissions",
  },

  Personnel: {
    Image: "Image",
    Personnel: "Personnel",
    Name: "Name",
    Rank: "Rank",
    ForcesIDNumber: "Forces ID Number",
    securityForce: "Security Force",
    SectorDivision: "Sector/Division",
    Unit: "Unit",
    ForcesAPNo: "Forces AP Number",
    BiometricRegistrationNo: "Biometric Registration Number",
    PhoneNo: "Phone number",
    Status: "Status",
    PersonnelDetails: "Personnel Details",
    firstName: "First Name",
    middleName: "Middle Name",
    lastName: "Last Name",
    telephoneNumber: "Telephone Number",
    Guarantor: "Guarantor",
    ResponsiblePersonName: "Responsible Person Name",
    ResponsiblePersonPhoneNo: "Responsible Person Phone No.",
    ModalHeader: "Personnel added successfully!",
    ModalSubHeader:
      "Do you want to assign Weapons and Ammunition / Create another Personnel?",
    AssignLater: "Assign Later",
    AssignWeaponORAmmunition: "Assign Weapon/Ammunition",
    EditPersonnel: "Edit Personnel",
    Weapons: "Weapons",
    Ammunitions: "Ammunition",
    WeaponType: "Weapon Type",
    Calibre: "Calibre",
    SerialNo: "Serial No",
    Condition: "Condition",
    WeaponOwnership: "Weapon Ownership",
    WeaponStatus: "Weapon Status",
    AssignWeapon: "Assign Weapon",
    AmmunitionType: "Ammunition Type",
    BatchORLotNumber: "Batch / Lot Number",
    AmmunitionStatus: "Ammunition Status",
    TechnicalStatus: "Technical Status",
    HazardDivision: "Hazard Division",
    AssignAmmunition: "Assign Ammunition",
    AssignAmmunitionModalEmptyText:
      "No Ammunition has been assigned to this personnel yet.",
    AssignWeaponModalEmptyText:
      "No weapon has been assigned to this personnel yet.",
    ViewAndEdit: "View Details",
    UnitDetails: "Unit Details",
    AreYouSureYouWantToUpdateThisPersonnel:
      "Are you sure you want to update this personnel?",
    PersonnelCreationConfirmation: "Do you want to create another personnel",
    PersonnelNotes: "Personnel Notes",
    PersonnelUpdatedSuccessfully: "Personnel updated successfully",
    DoneAssigning: "Done Assigning",
  },
  Save: "Save",
  Create: "Create",
  Action: "Action",
  Cancel: "Cancel",
  Update: "Update",
  Edit: "Edit",
  ActivityLog: "Activity Log",
  Search: "Search",
  Enter: "Enter",
  Yes: "Yes",
  No: "No",
  Confirmation: "Confirmation",
  Export: "Export",
  ItemsPerPageOf: "Items per page of",
  Entries: "entries",
  Issue: "Issue",
  To: "to",
  Areyousureyouwantto: "Are you sure you want to",
  Clear: "Clear",
  Apply: "Apply",
  FillMandatoryFields: "Please fill the mandatory fields",
  Submit: "Submit",
  DateCreated: "Created",
  DateModified: "Updated",
  Version: "Version",
  FacilityID: "Facility ID",
  FacilityName: "Facility Name",
  ForcesIDNumber: "Forces ID Number",
  Rank: "Rank",
  Name: "Name",
  Notes: "Notes",
  CreateS: "create",
  UpdateS: "update",
  Charaters: "characters",
  ViewDetails: "View Details",
  Upload: "Upload",
  Type: "Type",
  Value: "Value",
  AssignWeaponOrAmmunition: "Assign Weapon/Ammunition for ",
  Assign: "Assign",
  ClickHereForDetailedView: "Click here for detailed view",
  SomethingWentWrong: "Something Went Wrong!",
  ErrorInExporting: "Error in exporting!",
  OnlyPdfFilesAccepted: "Only pdf files are accepted",
  PleaseTryToLoginAgain: "Please try to login again!",
  Maximum6Imagesuploaded: "Maximum 6 images can be uploaded",
  Ok: "Ok",
  NoData: "No data",
  Done: "Done",
  IsRequired: "is required",
  AmmunitionAssignedSuccessfully: "Ammunition assigned successfully !",
  AmmunitionUpdatedSuccessfully: "Ammunition updated successfully !",
  FacilityUpdated: "Facility updated successfully !",
  FacilityCreated: "Facility created successfully !",
  ImportIDGeneratedSuccessfully: "Import ID generated successfully !",
  ShipmentConfirmedSuccessfully: "Shipment confirmed successfully !",
  TransferSentSuccessfully: "Transfer sent successfully !",
  TransferSavedSuccessfully: "Transfer saved successfully !",
  UserUpdatedSuccessfully: "User updated successfully !",
  UserLoggedOutSuccessfully: "User logged out successfully !",
  UserDeletedSuccessfully: "User deleted successfully !",
  WeaponUpdatedSuccessfully: "Weapon updated successfully !",
  WeaponAssignedSuccessfully: "Weapon assigned successfully !",
  FieldsUpdatedBy: "fields updated by",
  FieldName: "Field Name",
  OldValue: "Old Value",
  NewValue: "New Value",
  Available: "Available",
  IssuedDate: "Issued Date",
  ChangedBy: "Changed By",
  ChangedOn: "Changed On",
  RecoveryTown: "Recovery Town",
  AddedSuccessfully: "added successfully!",
  Unit: {
    Unit: "Unit",
    Units: "Units",
    UnitCreate: "Unit",
    securityForce: "Security Force",
    SectorDivision: "Sector/Division",
    EnglishTranslation: "English Translation",
    Region: "Region",
    District: "District",
    Town: "Town",
    Status: "Status",
    Basicdetails: "Basic Details",
    BASICDETAILS: "Basic Details",
    UnitName: "Unit Name",
    State: "State",
    LatitudeAndLongitude: "Latitude & Longitude",
    SetPositionOnMap: "Set Position On Map",
    EnterLocation: "Enter location / location co-ordinates",
    ConfirmLocation: "Confirm Location",
    AreyousureyouwantCreateUnit: "Are you sure you want to create this unit ?",
    AreyousureyouwantUpdateUnit: "Are you sure you want to update this unit ?",
  },
  facility: {
    facilityImage: "Facility Image",
    Sketchmapfacility: "Sketch map facility",
    facility: "Facility",
    facilityId: "Facility Id",
    facilityName: "Facility Name",
    facilityType: "Facility Type",
    SecurityForce: "Security Force",
    SectorDivision: "Sector/Division",
    Unit: "Unit",
    ConstructionType: "Construction Type",
    SALWCapacity: "SALW Capacity",
    NEQRating: "NEQ Rating",
    OwnershipofCompound: "Ownership of Compound",
    Status: "Status",
    AssessedUnitsofSpace: "Assessed Units of Space",
    Region: "Region",
    District: "District",
    Town: "Town",
    FacilityIdImplementer: "Facility ID - Implementer",
    DateConstructed: "Date Constructed",
    ConstructedBy: "Constructed by",
    Donor: "Donor",
    UploadImages: "Upload Images",
    Uploadupto2images: "upload UPTO two images",
    LicenseNumberName: "License Number/ Name",
    LicenseDocument: "License Document",
    Licensefileisrequired: "License file is required",
    BASICDETAILS: "Basic Details",
    UnitofSpace: "Units of Space",
    Length: "Length (m)",
    Width: "Width (m)",
    Height: "Height (m)",
    LocationDetails: "Location Details",
    State: "State",
    LatitudeAndLongitude: "Latitude & Longitude",
    SetPositionOnMap: "Set Position On Map",
    EnterLocation: "Enter location / location co-ordinates",
    ConfirmLocation: "Confirm Location",
    OfficerinCharge: "Officer in Charge",
    selectOIC: "Select OIC",
    OtherDetails: "Other Details",
    OIC: "OIC",
    UploadedImages: "Uploaded Images",
    Weapons: "Weapons",
    Ammunitions: "Ammunition",
    ThisFacility: "this Facility ?",
    Division: "Division",
    DateCreated: "Created",
    Action: "Action",
    ExportingState: "Exporting State",
    GovernmentUserMark: "Government User Mark",
    OtherUniqueIdentifyingMark: "Other Unique Identifying Mark",
    FacilityImageNotAvailable: "Facility Image Not Available",
    SktechMapFacilityNotAvailable: "Sktech Map Facility Not Available",
    Licenseupload: "Upload",
    License: "License",
  },
  weapon: {
    OtherDetails: "Other Details",
    Rank: "Rank",
    UploadedImages: "Uploaded Images",
    Weapon: "Weapon",
    WeaponStatus: "Weapon Status",
    WeaponType: "Weapon Type",
    Calibre: "Calibre",
    Serialnumber: "Serial number",
    GovernmentUserMark: "Government User Mark",
    OtherUniqueIdentifyingMark: "Other Unique Identifying Mark",
    Condition: "Condition",
    Ownership: "Ownership",
    Region: "Region",
    SecurityForce: "Security Force",
    SectorDivision: "Sector/Division",
    Unit: "Unit",
    Capturedweapon: "Captured weapon",
    ModelSystemDesignation: "Model System Designation",
    GenericCategory1: "Generic Category 1",
    GenericCategory2: "Generic Category 2",
    GenericCategory3: "Generic Category 3",
    YearofManufacturing: "Year of Manufacturing",
    ManufacturingCountry: "Manufacturing Country",
    Manufacturer: "Manufacturer",
    QANote: "QA Note",
    AcceptRejectRecord: "Accept/Reject",
    ReasonforRejection: "Reason for Rejection",
    QABy: "QA By",
    JVTVerificationStatus: "JVT Verification Status",
    Import: "Import",
    YearofImport: "Year of Import",
    ExportingState: "Exporting Country",
    UNNoteNumber: "UN Note Number",
    BasicDetails: "Basic Details",
    WeaponCalibre: "Weapon Calibre",
    WeaponCondition: "Weapon Condition",
    Serialnumberavailable: "Serial No Available",
    Enterserialnumber: "Enter serial number",
    GovernmentUserMarkAvailable: "Government User Mark Available",
    ReasonfornoSerialNo: "Reason for no Serial No",
    GenerateSerialNo: "Generate Serial No#",
    Description: "Description",
    Weaponownership: "Weapon ownership",
    WeaponValue: "Weapon Value(in $)",
    UploadImages: "Upload Images",
    Uploadupto6images: "upload UPTO six images",
    Upload: "Upload",
    UploadText: "Upload",
    OneorMulitpleImages: "One or Multiple Images",
    LocationDetails: "Location Details",
    facility: "Facility",
    FacilityName: "Facility Name",
    State: "State",
    District: "District",
    Town: "Town",
    Destroyeddetails: "Destroyed details",
    Datedestroyed: "Date destroyed",
    Locationdestroyed: "Location destroyed",
    Destroyedby: "Destroyed by",
    AssignCustodian: "Assign Custodian",
    Custodiandetails: "Custodian details",
    Custodianname: "Custodian name",
    SearchPersonnel: "Search Personnel",
    TelephoneNumber: "Telephone Number",
    Lostdetails: "Lost details",
    LostDate: "Lost Date",
    Descriptionofloss: "Description of loss",
    Lostdescription: "Lost description",
    MarkedtoDestroydetails: "Marked to Destroy details",
    DateofMarking: "Date of Marking",
    MethodofMarking: "Method of Marking",
    Methodname: "Method name",
    Notes: "Notes",
    Reclaimeddetails: "Reclaimed details",
    Reclaimeddate: "Reclaimed date",
    Date: "Date",
    SoldDetails: "Sold Details",
    DateSold: "Date Sold",
    Unknowndetails: "Unknown details",
    RecordUpdated: "Record Updated",
    UpdatedDate: "Updated Date",
    UpdatedbyRank: "Updated by Rank",
    UpdatedbySecurityForceID: "Updated by Security Force ID",
    UpdatedbyName: "Updated by Name",
    Isthisweaponcaptured: "Is this weapon captured?",
    CapturedFrom: "Captured From",
    CapturedDate: "Captured Date",
    Recoverystate: "Recovery State",
    RecoveryRegion: "Recovery Region",
    RecoveryDistrict: "Recovery District",
    TownORVillage: "Town/Village",
    DescriptiononRecovery: "Description of Recovery",
    QualityAssurance: "Quality Assurance",
    QualityAssuranceNote: "Quality Assurance Note",
    Verification: "Verification",
    Stateofrecovery: "State of recovery",
    Regionofrecovery: "Region of recovery",
    Districtofrecovery: "District of recovery",
    Weapondetails: "Weapon details",
    Shipment: "Shipment",
    TransferID: "Transfer Id",
    ImportID: "Import ID",
    ShipmentID: "Shipment ID",
    ViewWeapon: "View Weapon",
    WeaponWithSerialNo: "weapon with serial no",
    AndGovernmentUserMark: "and government user mark ",
    WeaponCreated: "Weapon created successfully",
    WeaponCreationConfirmation: "Do you want to create another weapon",
    FacilityId: "Facility Id",
    Status: "Status",
    Images: "Images",
    LatitudeAndLongitude: "Latitude & Longitude",
    ForcesIDNumber: "Forces ID Number",
    AcceptReject: "Accept/ Reject",
    DescriptionOfRecovery: "Description Of Recovery",
    WeaponNotes: "Weapon Notes",
    AddAnotherModelSystemDesignation: "Add another model system designation",
    WeaponWithSameSpecificationFound:
      "Weapons with same specifications exists.",
    UnknownDate: "Unknown Date",
    SoldDate: "Sold Date",
    SoldNotes: "Sold Notes",
    UnknownNotes: "Unknown Notes",
    MarkedToDestroyNotes: "Marked to Destroy Notes",
    QACalibre: "QA Calibre",
  },
  Ammunition: {
    AmmunitionStatus: "Ammunition Status",
    AmmunitionType: "Ammunition Type",
    AmmunitionCalibre: "Ammunition Calibre",
    AmmunitionRole: "Ammunition Role",
    Quantity: "Quantity",
    BatchLotNumber: "Batch / Lot Number",
    GovernmentUserMark: "Government User Mark",
    OtherUniqueIdentifyingMark: "Other Unique Identifying Mark",
    Description: "Description",
    Region: "Region",
    SecurityForce: "Security Force",
    SectorDivision: "Sector / Division",
    Unit: "Unit",
    FacilityName: "Facility Name",
    Stockpile: "Stockpile",
    TechnicalStatus: "Technical Status",
    HazardDivision: "Hazard division",
    CompatibilityGroup: "Compatibility group",
    Category1: "Category",
    Category2: "Category 2",
    ModelOrHeadstamp: "Ammunition Model / Headstamp",
    Calibre: "Calibre",
    NetExplosiveQuantity: "Net explosive quantity (NEQ)",
    YearofManufacture: "Year of Manufacture",
    ManufacturingCountry: "Manufacturing country",
    Manufacturer: "Manufacturer",
    QANote: "QA Note",
    AcceptRejectrecord: "Accept/Reject",
    ReasonForRejection: "Reason for rejection",
    QABy: "QA By",
    JVTVerificationStatus: "JVT Verification Status",
    Import: "Import",
    YearofImport: "Year of Import",
    ExportingState: "Exporting Country",
    UNNoteNumber: "UN Note Number",
    AmmunitionDetails: "Ammunition Details",
    QualityAssurance: "Quality Assurance",
    ExpendbyDate: "Expend by Date",
    ImportID: "Import ID",
    ShipmentID: "Shipment ID",
    AmmunitionDescription: "Ammunition Description",
    BasicDetails: "Basic Details",
    LocationDetails: "Location Details",
    State: "State",
    District: "District",
    TownVillage: "Town / Village",
    DestroyedDetails: "Destroyed Details",
    DateDestroyed: "Date Destroyed",
    LocationDestroyed: "Location Destroyed",
    Destroyedby: "Destroyed by",
    OtherDetails: "Other Details",
    IsthisAmmunitioncaptured: "Is this Ammunition captured?",
    CapturedFrom: "Captured From",
    CapturedDate: "Captured Date",
    RecoveryState: "Recovery State",
    RecoveryRegion: "Recovery Region",
    RecoveryDistrict: "Recovery District",
    LostDetails: "Lost Details",
    LostDate: "Lost Date",
    Descriptionofloss: "Description of loss",
    MarkedtoDestroyDetails: "Marked to Destroy Details",
    DateOfMarking: "Date Of Marking",
    MethodofMarking: "Method of Marking",
    Notes: "Notes",
    AcceptReject: "Accept / Reject",
    QualityAssuranceNote: "Quality Assurance Note",
    RecordUpdated: "Record Updated",
    UpdatedDate: "Updated Date",
    UpdatedbyRank: "Updated by Rank",
    UpdatedbySecurityForceID: "Updated by Security Force ID",
    UpdatedbyName: "Updated by Name",
    Verification: "Verification",
    UpdateAmmunition: "Update Ammunition",
    CreateAmmunition: "Create Ammunition",
    Areyousureyouwantto: "Are you sure you want to",
    ThisAmmunition: "this Ammunition",
    StateofRecovery: "State of Recovery",
    RegionofRecovery: "Region of Recovery",
    DistrictofRecovery: "District of Recovery",
    DescriptiononRecovery: "Description on Recovery",
    MethodName: "Method Name",
    AcceptedRejected: "Accepted / Rejected",
    ViewAmmunition: "View Ammunition",
    CustodianDetails: "Custodian Details",
    AssignCustodian: "Assign Custodian",
    SearchPersonnel: "Search Personnel",
    TelephoneNumber: "Telephone Number",
    Searching: "Searching",
    Search: "Search",
    ResultsFound: "Results Found",
    CustodianName: "Custodian Name",
    Rank: "Rank",
    ViewDetails: "View Details",
    TransferID: "Transfer Id",
    UploadImages: "Upload Images",
    Uploadupto6images: "upload UPTO six images",
    Upload: "Upload",
    UploadText: "Upload",
    OneorMulitpleImages: "One or Multiple Images",
    NoAmmunitionData: "No Ammunition available under this facility.",
    AmmunitionCreated: "Ammunition created successfully",
    AmmuniCreationConfirmation: "Do you want to create another ammunition",
    FacilityId: "Facility Id",
    StackNumber: "Stack Number",
    AmmunitionNotes: "Ammunition Notes",
    Container: "Container",
    BoxOrCaseNumber: "Crate Number",
    PalletSerialNumber: "Pallet Serial Number",
    AmTagApplied: "AmTag Applied",
    AmTagCode: "AmTag Code",
    Role: "Role",
    Nature: "Nature",
    Facility: "Facility",
    LsaClassification: "LSA Classification",
    PalletSerialNo: "Pallet Serial No",
    CapturedAmmunition: "Captured Ammunition",
    Model: "Ammunition Model",
    Headstamp: "Ammunition Headstamp",
    Status: "Status",
    Images: "Images",
    LatitudeAndLongitude: "Latitude & Longitude",
    Town: "Town",
    Lostdescription: "Lost description",
    DescriptionofRecovery: "Description of Recovery",
    Exportingstate: "Exporting State",
    AddModelSystemDesignation: "Add Model System Designation",
    AddAnotherAmmunitionModel: "Add another Ammunition Model",
    AddAnotherAmmunitionHeadstamp: "Add another Ammunition Headstamp",
  },
  spotcheck: {
    spotcheckidfacility: "Spotcheck ID",
    spotcheckidweapon: "Spotcheck ID",
    SpotcheckID: "Spotcheck ID",
    Dateofspotcheck: "Date of spotcheck",
    WeaponType: "Weapon Type",
    SerialNumber: "Serial Number",
    GovernmentUserMark: "Government User Mark",
    AssosiatedimportId: "Associated Import Id",
    SpotchekedBy: "Spotchecked by",
    SecurityForce: "Security Force",
    Force: "Force",
    Outcome: "Outcome",
    BatchOrlotnumber: "Batch / Lot number",
    AmmunitionCalibre: "Ammunition Calibre",
    Quantity: "Quantity",
    Description: "Description",
    facilityId: "Facility Id",
    SectorDivision: "Sector/Division",
    Unit: "Unit",
    Quantityofweaponsspotcheked: "Qty of weapons spotcheked",
    QuantityofAmmunitionspotcheked: "Qty of Ammunition spotcheked",
    PercentageTotalFacilityWeapons: "% Total Facility Weapons",
    PercentageTotalFacilityAmmunition: "% Total Facility Ammunition",
    SpotChecks: "Spot Checks",
    SpotCheckDetails: "Spot Check Details",
    WeaponStatus: "Weapon Status",
    MatchesFound: "Matches Found %",
    MisMatcheFound: "MisMatch Found %",
    SpotCheckDateTime: "Spot Check Date & Time",
    SpotCheckBy: "Spot Check by",
    Images: "Images",
    SpotCheckVerificationDetails: "Spot Check Verification Details",
    WeaponImages: "Weapon Images",
    Match: "Match",
    Stored: "Stored",
    WeaponCalibre: "Weapon Calibre",
    GovernmentUserMarkAvailable: "Government User Mark Available",
    OtherUniqueIdentifyingMark: "Other Unique Identifying Mark",
    WeaponCondition: " Weapon Condition",
    WeaponOwnership: "Weapon Ownership",
    Facility: "Facility",
    Edited: "Edited",
    IssuedTo: "Issued to",
    AmmunitionType: "Ammunition Type",
    BatchORLotNumber: "Batch / Lot Number",
    Status: "Status",
    facilityImage: "Facility Image",
    MapFacilityImage: "Map Facility Image",
    MapImage: "Map Image",
    facilityName: "Facility Name",
    SALWCapacity: "SALW Capacity",
    OwnershipofCompound: "Ownership of Compound",
    Latitude: "Latitude",
    Longitude: "Longitude",
    NEQRating: "NEQ Rating",
    OfficerinCharge: "Officer in Charge",

    Stockpile: "Stockpile",
    TechnicalStatus: "Technical Status",
    HazardDivision: "Hazard division",
    AmmunitionStatus: "Ammunition Status",
    AmmunitionDescription: "Ammunition Description",
    AmmunitionImages: "Ammunition Images",
    CompatibilityGroup: "Compatibility Group",
    ExpendByDate: "Expend By Date",
    AmmunitionRole: "Ammunition Role",
    MatchesFoundWeapon: "Matches Found %",
    MisMatcheFoundWeapon: "MisMatch Found %",
    SpotCheckDateTimeWeapon: "Spot Check Date & Time",
    SpotCheckVerificationDetailsWeapon: "Spot Check Verification Details",
    MatchFacility: "Match",
  },
  Import: {
    Imports: "Imports",
    ImportId: "Import ID",
    DateCreated: "Created",
    ExportingState: "Exporting Country",
    ShipmentID: "Shipment ID",
    DeliveryDate: "Delivery Date",
    DeliveryLocation: "Delivery Location",
    EndUser: "End User",
    TotalWeapons: "Total Weapons",
    TotalAmmunition: "Total Ammunition",
    PDNSubmitted: "PDN Submitted",
    InitiateImport: "Initiate Import",
    GenerateImportID: "Generate Import ID",
    ConfirmPDN: "Confirm PDN",
    Step1: "Step 1",
    ExportingCountry: "Exporting Country",
    EstimatedDateofArrival: "Estimated Date of Arrival",
    PlannedDeliveryLocation: "Planned Delivery Location",
    MethodofShipment: "Method of Shipment",
    IntendedEndUser: "Intended End User",
    DescriptionofItemsImported: "Description of Items Imported",
    AssociatedPreDeliveryDocuments: "Associated Pre-Delivery Documents",
    AirwayBillsandBillsofLading: "Airway Bills and Bills of Lading",
    AdvancedDeliveryDocuments: "Advanced Delivery Documents (ADN)",
    ConfirmShipment: "Confirm Shipment",
    ConfirmTheShipment: "confirm the shipment",
    Step2: "Step 2",
    PartoftheImporthasarrivedAddremainingitemsviaanothershipment:
      "Part of the Import has arrived? Add remaining items via another shipment",
    AddShipment: "Add Shipment",
    ActualDateofArrival: "Actual Date of Arrival",
    ShippingDocuments: "Shipping Documents",
    Shipment: "Shipment",
    receivedon: "received on ",
    ViewShipment: "View Shipment",
    ShippingDocument: "Shipping Document",
    RegisterConfirmPDN: "Register & Confirm PDN",
    Step3: "Step 3",
    Weapon: "Weapon",
    Ammunition: "Ammunition",
    Notes: "Notes",
    Enterthedetailsofdifferenceindocumentation:
      "Enter the details of difference in documentation",
    PostDeliveryNotification: "Post Delivery Notification(PDN)",
    PDNcanbeuploadedonlyaftersubmittingtheaboveinformation:
      "PDN can be uploaded only after submitting the above information",
    ViewDetails: "View Details",
    AdvanceDeliveryNotification: "Advance Delivery Notification",
    Shipments: "Shipments",
    NoNotes: "No Notes.",
    NoPDNsubmitted: "No PDN submitted",
    OnlyPDFIsSupported: "Only PDF is supported",
    AdvancedDeliveryDocumentIsRequired:
      "Advanced delivery document is required",
    PreDeliveryDocumentIsRequired: "Pre delivery document is required",
    ShippingDocumentIsRequired: "Shipping document is required",
    Upload: "Upload",
    ToSubmitTheShipmentReceived:
      "To submit the shipment received, please upload the PDN (Post Delivery Notification)",
    SaveChanges: "Save as Draft",
    SaveTheShipment: "save the shipment",
    SubmitTheShipment: "submit the shipment",
    IsEverythingInPlaceToConfirmTheShipment:
      "Is everything in place to confirm the shipment?",
    WeaponType: "Weapon Type",
    WeaponCalibre: "Weapon Calibre",
    Quantity: "Quantity",
    AmmunitionType: "Ammunition Type",
    AmmunitionCalibre: "Ammunition Calibre",
    UNNoteNumber: "UN Note Number",
    ShipmentReceivedOn: "Shipment",
    ImportShipments: "Import Shipments",
  },
  Transfers: {
    Transfers: "Transfers",
    SendTransfers: "Send Transfers",
    ReceiveTransfers: "Receive Transfers",
    InitiateTransfer: "Initiate Transfer",
    TransferID: "Transfer ID",
    SourceFacility: "Source Facility",
    DestinationFacility: "Destination Facility",
    WeaponCount: "Weapon Count",
    AmmunitionCount: "Ammunition Count",
    SentDate: "Sent Date",
    ReceivedDate: "Received Date",
    Notes: "Notes",
    Status: "Status",
    SelectedWeaponsforTransfer: "Selected Weapons for Transfer",
    SelectedAmmunitionforTransfer: "Selected Ammunition for Transfer",
    of: "of",
    ReadytoplaceTransferProceedtoReview:
      "Ready to place Transfer? Proceed to Review",
    ReviewTransfer: "Review Transfer",
    DetailscannotbeeditedonFinishTransfer:
      "Details cannot be edited on Finish Transfer.",
    AddNotes: "Add Notes",
    Officerincharge: "Officer in charge",
    FinishTransfer: "Finish Transfer",
    SaveasDraft: "Save as Draft",
    Areyousureyouwanttofinishsendingthetransfer:
      "Are you sure you want to finish sending the transfer?",
    Areyousureyouwanttosavethetransfer:
      "Are you sure you want to save the transfer?",
    Confirmation: "Confirmation",
    Remove: "Remove",
    Add: "Add",
    NotReceived: "Not Received",
    Received: "Received",
    NoWeaponsavailableunderthisfacility:
      "No Weapons available under this facility.",
    TransferDetails: "Transfer Details",
    SecurityForce: "Security Force",
    SourceRegion: "Source Region",
    DestinationRegion: "Destination Region",
    DateofTransfer: "Date of Transfer",
    WeaponsQuantity: "Weapons Quantity",
    AmmunitionsQuantity: "Ammunition Quantity",
    StoredWeapons: "Stored Weapons",
    IssuedWeapons: "Issued Weapons",
    MarkedtoDestroyWeapons: "Marked to Destroy Weapons",
    StoredAmmunition: "Stored Ammunition",
    IssuedAmmunition: "Issued Ammunition",
    MarkedtodestroyAmmunition: "Marked to destroy Ammunition",
    SelectedWeapons: "Selected Weapons",
    SelectedAmmunition: "Selected Ammunition",
    FinishReceivingTransfer: "Finish Receiving Transfer",
    ConfirmPartialReceipt: "Confirm Partial Receipt",
    CancelTransfer: "Cancel Transfer",
    Areyousureyouwanttocancelthetransfer:
      "Are you sure you want to cancel the transfer?",
    DetailsfortransfercannotbechangedonceyouconfirmPartialTransferFinishTransfer:
      "Details for transfer cannot be changed once you confirm Partial Transfer / Finish Transfer",
    TransferCancelled: "Transfer Cancelled",
    Upload: "Upload",
    AuthorizationRequest: "Authorization Request",
    ReferenceNumber: "Reference Number",
    TransferCancelledSuccessfully: "Transfer cancelled successfully!",
    TransferPartiallyReceivedSuccessfully:
      "Transfer partially received successfully!",
    DownloadAcknowledgementReceipt: "Download Acknowledgement Receipt",
    TransferDownloadVerifyMsg:
      "Transfer completely received successfully. Do you want to download acknowledgement receipt?",
  },
  Admin: {
    Users: "Users",
    User: "User",
    Name: "Name",
    Rank: "Rank",
    IDNumber: "ID Number",
    Email: "Email",
    SecurityForce: "Security Force",
    SectorDivision: "Sector/Division",
    Unit: "Unit",
    Status: "Status",
    Role: "Role",
    LastLogin: "Last Login",
    Action: "Action",
    ForcesIDNumber: "Forces ID Number",
    FirstName: "First Name",
    LastName: "Last Name",
    UnitDetails: "Unit Details",
    Division: "Division",
    Level: "Level",
    ChoosePermissionLevel: "Choose Permission Level",
    AllPermissionsGranted: "All Permissions Granted",
    Personnel: "Personnel",
    Roles: "Roles",
    Facility: "Facility",
    Weapons: "Weapons",
    Ammunition: "Ammunition",
    Import: "Import",
    SpotCheck: "Spot Check",
    Transfer: "Transfer",
    Settings: "Settings",
    Report: "Report",
    EditPersonalDetails: "Edit Personal Details",
    EditUser: "Edit User",
    PersonalDetails: "Personal Details",
    Upload: "Upload",
    Delete: "Delete",
    ForceLogout: "Force Logout",
    Image: "Image",
  },
  Reports: {
    Reports: "Reports",
    Categories: "Categories",
    Weapons: "WEAPONS",
    Ammunition: "AMMUNITION",
    Imports: "IMPORTS",
    SpotChecks: "SPOT CHECKS",
    WeaponsS: "Weapons",
    AmmunitionS: "Ammunition",
    ImportsS: "Imports",
    SpotChecksS: "Spot Checks",
    SecurityForce: "Security Force",
    SectorDivision: "Sector/Division",
    Unit: "Unit",
    ExportingCountry: "Exporting Country",
    ConductedBy: "Conducted By",
    SelectYearMonth: "Select Year/Month",
    SelectYear: "Select Year",
    WeaponsVerifiedByImportReport: " Weapons Verified By Import Report",
    WeaponQty: "Weapon Qty",
    WeaponSpotcheck: "Weapon Spotcheck %",
    AmmunitionQty: " Ammunition Qty",
    AmmunitionSpotcheck: "Ammunition Spotcheck %",
    BreakdownOfImportsByCountryAndTypeQuantity:
      "Breakdown of Imports by Country and Type/Quantity",
    PercentageSpotcheckByImport: "Percentage of Spotcheck by Import",
    MachineGuns: "Machine Guns",
    Mortars: "Mortars",
    RocketArtillery: "Rocket Artillery",
    RecoillessWeapons: " Recoilless Weapons",
    CanonAndArtillery: "  Canon and Artillery",
    Shotguns: "Shotguns",
    OtherPistolCalibreWeapons: "Other Pistol Calibre Weapons",
    GrenadeLaunchers: "Grenade Launchers",
    GuidedLightWeapons: "Guided Light Weapons",
    Handguns: "Handguns",
    RocketLaunchers: "Rocket Launchers",
    RiflesAndCarbines: "Rifles and Carbines",
    NotQA: "Not QA",
    LargeCalibreAmmunition: "Large Calibre Ammunition (> 57 mm)",
    MediumCalibreAmmunition: "Medium Calibre Ammunition (20 to 57 mm)",
    SmallCalibreAmmunition: "Small Calibre Ammunition (< 20 mm)",
    Fuzes: "Fuzes",
    Submunitions: "Submunitions",
    Rockets: "Rockets",
    Mines: "Mines",
    GuidedMunitions: "Guided Munitions",
    Grenades: "Grenades",
    AircraftBombs: "Aircraft Bombs",
    MonthlySpotCheck: "Monthly Spot Checks",
    Month: "Month",
    Total: "Total",
    Region: "Region",
    District: "District",
    Facility: "Facility",
    SpotChecksByImportReport: "Spot checks by Import Report",
    WeaponHoldingsByUnit: "Holdings by Unit Report",
    UnitWeaponDetailReport: "Unit Weapon Detail Report",
    UnitWeaponReport: "Unit Weapon Report",
    CapturedWeaponsReport: "Captured Weapons Report",
    LostWeaponsReport: "Lost Weapons Report",
    MaintenanceReport: "Weapon Maintenance Report",
    UnitWeaponHoldingsOverTime: "Weapon Holdings Over Time Report",
    SmallArmsAmmunitionByUnit: "Small Arms Ammunition by Unit Report",
    UnitAmmunitionHoldings: "Unit Ammunition Holding  Report",
    SectorDivisionHoldingsOverTime:
      "Sector / Division Holdings Over Time Report",
    AmmunitionShelfLifeReport: "Ammunition Shelf Life Report",
    LostAmmunitionReport: "Lost Ammunition Report",
    CapturedAmmunitionReport: "Captured Ammunition Report",
    FacilityId: "Facility Id",
    FacilityName: "Facility Name",
    QtyAmmunitionMonthsToExpiry: "	Qty Ammunition < 6 months to Expiry	",
    TotalNEQMonthsToExpiry: "Total NEQ < 6 months to Expiry",
    QtyAmmunitionExpired: "Qty Ammunition Expired",
    TotalNEQAmmunitionExpired: " Total NEQ Ammunition Expired",
    OICName: "OIC Name",
    OICSecurityForce: "OIC Security Force",
    OICTelephone: "OIC Telephone",
    Category: "Category",
    BatchLotNumber: "Batch/Lot Number",
    Calibre: "Calibre",
    CountryOfManufacture: "Country of Manufacture",
    Manufacturer: "Manufacturer",
    GovernmentMark: "Government Mark",
    OtherUniqueIdentifyingMark: "Other Unique Identifying Mark",
    DateCaptured: "Date Captured",
    CapturedFrom: "Captured From",
    Town: "Town",
    Description: "Description",
    QANote: "QA Note",
    Other: "Other",
    Bistoolad: "Bistoolad",
    SerialNumber: "Serial Number",
    WeaponTypes: "Weapon Type",
    Issued: "Issued",
    Stored: "Stored",
    MarkedToDestroy: "Marked to Destroy",
    Destroyed: "Destroyed",
    Lost: "Lost",
    Sold: "Sold",
    ReclaimedByClan: "Reclaimed by Clan",
    Draft: "Draft",
    InTransit: "In Transit",
    Unknown: "Unknown",
    WeaponStatus: "Weapon Status",
    WeaponCondition: "Weapon Condition",
    Name: "Name",
    Rank: "Rank",
    IDNumber: "ID Number",
    CrateNumber: "Crate Number",
    PalletSerialNo: "Pallet Serial No",
    Headstamp: "Headstamp",
    Model: "Model",
    Verification: "VERIFICATION",
    VerificationReport: "Verification Report",
    MonthlySpotchecks: "Monthly Spotchecks",
    ImportId: "Import Id",
    CountryOfExport: "Country of Export",
    TotalWeaponsImported: "Total Weapons Imported",
    PercentageWeaponsVerified: "Weapons Verified %",
    TotalAmmunitionImported: "Total Ammunition Imported",
    PercentageAmmunitionVerified: "Ammunition Verified %",
    ExportingState: "Exporting Country",
    PercentageWeaponsVerifiedByImport: "Percentage Spotcheck by Import",
    DateImported: "Date Imported",
    NumberOfWeaponsVerified: "Number of Weapons Verified",
    NumberOfAmmunitionsVerified: "Number of Ammunition Verified",
    ModelSystemDesignation: "Model System Designation",
    NumberWeaponsSpotchecked: "Number of Weapons Spotchecked",
    NumberAmmunitionsSpotchecked: "Number of Ammunitions Spotchecked",
    ImportByCountry: "Import By Country",
  },
  WeaponStatus: {
    Destroyed: "Destroyed",
    Draft: "Draft",
    "In Transit": "In Transit",
    Issued: "Issued",
    Lost: "Lost",
    "Marked to destroy": "Marked to destroy",
    "Reclaimed by Clan": "Reclaimed by Clan",
    Sold: "Sold",
    Stored: "Stored",
    Unknown: "Unknown",
    Blank: "Blank",
  },
  AmmoStatus: {
    Destroyed: "Destroyed",
    Draft: "Draft",
    "In Transit": "In Transit",
    Issued: "Issued",
    Lost: "Lost",
    "Marked to destroy": "Marked to destroy",
    "Reclaimed by Clan": "Reclaimed by Clan",
    Sold: "Sold",
    Stored: "Stored",
    Unknown: "Unknown",
    Expended: "Expended",
    Blank: "Blank",
  },
  NoSerialReasons: {
    Obliterated: "Obliterated",
    Other: "Other",
    "Worn away over time": "Worn away over time",
    Blank: "Blank",
  },
  WeaponCondition: {
    Serviceable: "Serviceable",
    Unserviceable: "Unserviceable",
    Blank: "Blank",
  },
  WeaponOwnership: {
    Government: "Government",
    Clan: "Clan",
    Private: "Private",
    Other: "Other",
    Blank: "Blank",
  },
  CapturedFrom: {
    "Al Shabaab": "Al Shabaab",
    Criminal: "Criminal",
    "Other non-state group": "Other non-state group",
    Unknown: "Unknown",
    Other: "Other",
    Blank: "Blank",
  },
  PersonnelStatus: {
    Active: "Active",
    Inactive: "Inactive",
    Deceased: "Deceased",
    Defected: "Defected",
    Blank: "Blank",
  },
  FacilityType: {
    Armoury: "Armoury",
    "Explosive Storehouse": "Explosive Storehouse",
    Blank: "Blank",
  },
  ConstructionType: {
    Concrete: "Concrete",
    Containerised: "Containerised",
    "Store room": "Store room",
    Unknown: "Unknown",
    Others: "Other",
    Blank: "Blank",
  },
  OwnershipCompound: {
    Government: "Government",
    Clan: "Clan",
    Private: "Private",
    Other: "Other",
    Blank: "Blank",
  },
  FacilityStatus: {
    Operational: "Operational",
    "Non-Operational": "Non-Operational",
    Other: "Other",
    "Outside of government territory": "Outside of government territory",
    Unknown: "Unknown",
    Blank: "Blank",
  },
  AmmoStockpile: {
    Operational: "Operational",
    "War Reserve": "War Reserve",
    Training: "Training",
    Disposal: "Disposal",
    Production: "Production",
    Experimental: "Experimental",
    Blank: "Blank",
  },
  AmmoContainer: {
    Pallet: "Pallet",
    Crate: "Crate",
    Blank: "Blank",
  },
  ActivityAction: {
    create: "Create",
    update: "Update",
  },
  Blank: "Blank",
  Other: "Other",
};
