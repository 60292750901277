import {
  GET_MAP_DETAILS,
  RESET_MAP_DETAILS,
  GET_MAP_COUNTS,
  RESET_MAP_COUNTS,
} from "../ActionTypes/home";
import { REHYDRATE } from "redux-persist";

interface InitialState {
  mapDetails: any;
  isMapLoading: boolean;
  mapCount: number;
  mapWeaponCount: number;
  mapAmmoCount: number;
  mapFacilityCount: number;
}

const initialState: InitialState = {
  mapDetails: [],
  isMapLoading: true,
  mapCount: 0,
  mapWeaponCount: 0,
  mapAmmoCount: 0,
  mapFacilityCount: 0,
};

const HomeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_MAP_DETAILS:
      return {
        ...state,
        mapDetails: action.payload.homepage_data,
        isMapLoading: false,
      };
    case RESET_MAP_DETAILS:
      return {
        ...state,
        mapDetails: [],
        isMapLoading: true,
      };
    case GET_MAP_COUNTS:
      return {
        ...state,
        isMapLoading: false,
        mapCount: action.payload.data_count ?? 0,
        mapWeaponCount: action.payload.weapon_count ?? 0,
        mapAmmoCount: action.payload.ammunition_count ?? 0,
        mapFacilityCount: action.payload.facility_count ?? 0,
      };
    case RESET_MAP_COUNTS:
      return {
        ...state,
        mapCount: 0,
        mapWeaponCount: 0,
        mapAmmoCount: 0,
        mapFacilityCount: 0,
        isMapLoading: true,
      };
    case REHYDRATE:
    default:
      return state;
  }
};

export default HomeReducer;
