export const GET_SEND_TRANSFER_PENDING = "GET_SEND_TRANSFER_PENDING";
export const GET_MORE_SEND_TRANSFER_PENDING = "GET_MORE_SEND_TRANSFER_PENDING";
export const GET_SEND_TRANSFER_SUCCESS = "GET_SEND_TRANSFER_SUCCESS";
export const GET_MORE_SEND_TRANSFER_SUCCESS = "GET_MORE_SEND_TRANSFER_SUCCESS";
export const RESET_SEND_TRANSFER = "RESET_SEND_TRANSFER";
export const GET_RECEIVE_TRANSFER_PENDING = "GET_RECEIVE_TRANSFER_PENDING";
export const GET_MORE_RECEIVE_TRANSFER_PENDING =
  "GET_MORE_RECEIVE_TRANSFER_PENDING";
export const GET_RECEIVE_TRANSFER_SUCCESS = "GET_RECEIVE_TRANSFER_SUCCESS";
export const GET_MORE_RECEIVE_TRANSFER_SUCCESS =
  "GET_MORE_RECEIVE_TRANSFER_SUCCESS";
export const RESET_RECEIVE_TRANSFER = "RESET_RECEIVE_TRANSFER";
export const GET_TRANSFER_DETAILS = "GET_TRANSFER_DETAILS";
export const RESET_TRANSFER_DETAILS = "RESET_TRANSFER_DETAILS";
