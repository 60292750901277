import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import AppReducers from "./reducers";

const persistConfig = {
  key: "root",
  whitelist: ["auth"],
  storage,
};
const persistedReducer = persistReducer(persistConfig, AppReducers);

const AppStore: any = () => {
  let store: any;
  const isBrowser = typeof window !== "undefined";

  if (isBrowser) {
    const persistConfig = {
      key: "root",
      whitelist: ["auth", "settings"],
      storage,
    };
    const persistedReducer = persistReducer(persistConfig, AppReducers);

    store = configureStore({
      reducer: persistedReducer,
      middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          thunk: {
            extraArgument: null,
          },
          serializableCheck: false,
        }),
    });
    store.__persistor = persistStore(store);
  } else {
    store = configureStore({
      reducer: persistedReducer,
      middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          thunk: {
            extraArgument: null,
          },
          serializableCheck: false,
        }),
    });
  }

  return store;
};

export default AppStore();
