import {
  GET_USER_PENDING,
  GET_MORE_USER_PENDING,
  GET_USER_SUCCESS,
  GET_MORE_USER_SUCCESS,
  RESET_USER,
} from "../ActionTypes/user";
import { REHYDRATE } from "redux-persist";

interface InitialState {
  userLoading: boolean;
  moreUserLoading: boolean;
  userList: any;
  userListCount: number;
  userPage: number;
  userExportQuery: any;
}

const initialState: InitialState = {
  userLoading: false,
  moreUserLoading: false,
  userList: [],
  userListCount: 0,
  userPage: 1,
  userExportQuery: [],
};

const UserReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_USER_PENDING:
      return {
        ...state,
        userLoading: true,
      };
    case GET_MORE_USER_PENDING:
      return {
        ...state,
        moreUserLoading: true,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        userLoading: false,
        userList: action.payload.data,
        userListCount: action.payload.data_count,
        userExportQuery: action.payload.dynamic_filter_query,
        userPage: 2,
      };
    case GET_MORE_USER_SUCCESS:
      const newUserList = action.payload.data;
      const { userList } = state;
      return {
        ...state,
        moreUserLoading: false,
        userList: [...userList, ...newUserList],
        userListCount: action.payload.data_count,
        userExportQuery: action.payload.dynamic_filter_query,
        userPage: state.userPage + 1,
      };
    case RESET_USER:
      return {
        ...state,
        userLoading: false,
        moreUserLoading: false,
        userList: [],
        userListCount: 0,
        userExportQuery: [],
        userPage: 1,
      };
    case REHYDRATE:
    default:
      return state;
  }
};

export default UserReducer;
