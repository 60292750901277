import { REHYDRATE } from "redux-persist";
import { GET_DYNAMIC_USER_LIST, RESET_DYNAMIC_USER_LIST } from "../ActionTypes";

interface intialState {
  DynamicFilterRanks: any;
  DynamicFilterSecurityForces: any;
  DynamicFilterDivisions: any;
  DynamicFilterUnits: any;
  DynamicFilterRoles: any;
  DynamicFilterStatus: any;
  DynamicUnitListLoading: boolean;
}
const intialState = {
  DynamicFilterRanks: [],
  DynamicFilterSecurityForces: [],
  DynamicFilterDivisions: [],
  DynamicFilterUnits: [],
  DynamicFilterRoles: [],
  DynamicFilterStatus: [],
  DynamicUnitListLoading: true,
};

const DynamicFilterUserListReducer = (state = intialState, action: any) => {
  switch (action.type) {
    case GET_DYNAMIC_USER_LIST:
      return {
        ...state,
        DynamicFilterRanks: action.payload.rank,
        DynamicFilterSecurityForces: action.payload.security_forces,
        DynamicFilterDivisions: action.payload.divisions,
        DynamicFilterUnits: action.payload.units,
        DynamicFilterRoles: action.payload.role,
        DynamicFilterStatus: action.payload.status,
        DynamicUnitListLoading: false,
      };
    case RESET_DYNAMIC_USER_LIST:
      return {
        ...state,
        DynamicFilterRanks: [],
        DynamicFilterSecurityForces: [],
        DynamicFilterDivisions: [],
        DynamicFilterUnits: [],
        DynamicFilterRoles: [],
        DynamicFilterStatus: [],
        DynamicUnitListLoading: true,
      };

    case REHYDRATE:
    default:
      return state;
  }
};

export default DynamicFilterUserListReducer;
