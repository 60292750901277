import { REHYDRATE } from "redux-persist";
import { GET_DYNAMIC_UNIT_LIST, RESET_DYNAMIC_UNIT_LIST } from "../ActionTypes";

interface intialState {
  DynamicFilterSecurityForces: any;
  DynamicFilterDivisions: any;
  DynamicFilterRegions: any;
  DynamicFilterDistricts: any;
  DynamicFilterStatus: any;
  DynamicUnitListLoading: boolean;
}
const intialState = {
  DynamicFilterSecurityForces: [],
  DynamicFilterDivisions: [],
  DynamicFilterDistricts: [],
  DynamicFilterStatus: [],
  DynamicFilterRegions: [],
  DynamicUnitListLoading: true,
};

const DynamicFilterUnitListReducer = (state = intialState, action: any) => {
  switch (action.type) {
    case GET_DYNAMIC_UNIT_LIST:
      return {
        ...state,
        DynamicFilterStatus: action.payload.unit_status,
        DynamicFilterRegions: action.payload.regions,
        DynamicFilterSecurityForces: action.payload.security_forces,
        DynamicFilterDivisions: action.payload.divisions,
        DynamicFilterDistricts: action.payload.districts,
        DynamicUnitListLoading: false,
      };
    case RESET_DYNAMIC_UNIT_LIST:
      return {
        ...state,
        DynamicFilterStatus: [],
        DynamicFilterRegions: [],
        DynamicFilterSecurityForces: [],
        DynamicFilterDivisions: [],
        DynamicFilterDistricts: [],
        DynamicUnitListLoading: true,
      };

    case REHYDRATE:
    default:
      return state;
  }
};

export default DynamicFilterUnitListReducer;
