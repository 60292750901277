import {
  GET_TRANSFER_WEAPON_PENDING,
  GET_MORE_TRANSFER_WEAPON_PENDING,
  GET_TRANSFER_WEAPON_SUCCESS,
  GET_MORE_TRANSFER_WEAPON_SUCCESS,
  RESET_TRANSFER_WEAPON,
  GET_MORE_TRANSIT_WEAPON_PENDING,
  GET_MORE_TRANSIT_WEAPON_SUCCESS,
  GET_TRANSIT_WEAPON_PENDING,
  GET_TRANSIT_WEAPON_SUCCESS,
  RESET_TRANSIT_WEAPON,
} from "../ActionTypes/simpleTransferWeapon";
import { REHYDRATE } from "redux-persist";

interface InitialState {
  simpleTransferWeaponLoading: boolean;
  moreSimpleTransferWeaponLoading: boolean;
  simpleTransferWeaponList: any;
  simpleTransferWeaponListCount: number;
  simpleTransferWeaponPage: number;
  simpleTransferWeaponExportQuery: any;

  transitWeaponLoading: boolean;
  moreTransitWeponLoading: boolean;
  transitWeaponList: any;
  transitWeaponListCount: number;
  transitWeaponPage: number;
  transitWeaponExportQuery: any;
}

const initialState: InitialState = {
  simpleTransferWeaponLoading: false,
  moreSimpleTransferWeaponLoading: false,
  simpleTransferWeaponList: [],
  simpleTransferWeaponListCount: 0,
  simpleTransferWeaponPage: 1,
  simpleTransferWeaponExportQuery: [],

  transitWeaponLoading: false,
  moreTransitWeponLoading: false,
  transitWeaponList: [],
  transitWeaponListCount: 0,
  transitWeaponPage: 1,
  transitWeaponExportQuery: [],
};

const SimpleTransferWeaponReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_TRANSFER_WEAPON_PENDING:
      return {
        ...state,
        simpleTransferWeaponLoading: true,
      };
    case GET_MORE_TRANSFER_WEAPON_PENDING:
      return {
        ...state,
        moreSimpleTransferWeaponLoading: true,
      };
    case GET_TRANSFER_WEAPON_SUCCESS:
      return {
        ...state,
        simpleTransferWeaponLoading: false,
        simpleTransferWeaponList: action.payload.weapons_details,
        simpleTransferWeaponListCount: action.payload.data_count,
        simpleTransferWeaponExportQuery: action.payload.dynamic_filter_query,
        simpleTransferWeaponPage: 2,
      };
    case GET_MORE_TRANSFER_WEAPON_SUCCESS:
      const newSimpleTransferWeaponList = action.payload.weapons_details;
      const { simpleTransferWeaponList } = state;
      return {
        ...state,
        moreSimpleTransferWeaponLoading: false,
        simpleTransferWeaponList: [
          ...simpleTransferWeaponList,
          ...newSimpleTransferWeaponList,
        ],
        simpleTransferWeaponListCount: action.payload.data_count,
        simpleTransferWeaponExportQuery: action.payload.dynamic_filter_query,
        simpleTransferWeaponPage: state.simpleTransferWeaponPage + 1,
      };
    case RESET_TRANSFER_WEAPON:
      return {
        ...state,
        simpleTransferWeaponLoading: false,
        moreSimpleTransferWeaponLoading: false,
        simpleTransferWeaponList: [],
        simpleTransferWeaponListCount: 0,
        simpleTransferWeaponExportQuery: [],
        simpleTransferWeaponPage: 1,
      };
    case GET_TRANSIT_WEAPON_PENDING:
      return {
        ...state,
        transitWeaponLoading: true,
      };
    case GET_MORE_TRANSIT_WEAPON_PENDING:
      return {
        ...state,
        moreTransitWeponLoading: true,
      };
    case GET_TRANSIT_WEAPON_SUCCESS:
      return {
        ...state,
        transitWeaponLoading: false,
        transitWeaponList: action.payload.weapons_details,
        transitWeaponListCount: action.payload.data_count,
        transitWeaponExportQuery: action.payload.export_query,
        transitWeaponPage: 2,
      };
    case GET_MORE_TRANSIT_WEAPON_SUCCESS:
      const newTransitWeaponList = action.payload.weapons_details;
      const { transitWeaponList } = state;
      return {
        ...state,
        moreTransitWeponLoading: false,
        transitWeaponList: [...transitWeaponList, ...newTransitWeaponList],
        transitWeaponListCount: action.payload.data_count,
        transitWeaponExportQuery: action.payload.export_query,
        transitWeaponPage: state.transitWeaponPage + 1,
      };
    case RESET_TRANSIT_WEAPON:
      return {
        ...state,
        transitWeaponLoading: false,
        moreTransitWeponLoading: false,
        transitWeaponList: [],
        transitWeaponListCount: 0,
        transitWeaponExportQuery: [],
        transitWeaponPage: 1,
      };
    case REHYDRATE:
    default:
      return state;
  }
};

export default SimpleTransferWeaponReducer;
