import React, { memo } from "react";
import { ToastContainer } from "react-toastify";
import { SWRConfig } from "swr";
import { BrowserRouter } from "react-router-dom";
import MainRouter from "./router";
import Icons from "./components/icons";
import request from "./services/HttpMethods";
import "react-toastify/dist/ReactToastify.css";

const swrConfig = {
  fetcher: (url: string) => request.get(url).then((res) => res.data),
  revalidateOnFocus: false,
  errorRetryCount: 1,
};

const App = () => (
  <React.Fragment>
    <ToastContainer theme="colored" />
    <SWRConfig value={swrConfig}>
      <BrowserRouter>
        <MainRouter />
      </BrowserRouter>
    </SWRConfig>
    <Icons />
  </React.Fragment>
);

export default memo(App);
