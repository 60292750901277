import { REHYDRATE } from "redux-persist";
import {
  GET_USERS_LIST,
  GET_ROLES_LIST,
  GET_UNITS_LIST,
  GET_PERSONNELS_LIST,
  RESET_USERS_LIST,
  RESET_ROLES_LIST,
  RESET_UNITS_LIST,
  RESET_PERSONNELS_LIST,
  GET_FACILITY_LIST,
  RESET_FACILITY_LIST,
  GET_WEAPONS_LIST,
  RESET_WEAPONS_LIST,
  GET_AMMUNITIONS_LIST,
  RESET_AMMUNITIONS_LIST,
  GET_SIMPLE_WEAPONS_LIST,
  RESET_SIMPLE_WEAPONS_LIST,
  GET_SIMPLE_AMMUNITIONS_LIST,
  RESET_SIMPLE_AMMUNITIONS_LIST,
  GET_IMPORTS_LIST,
  RESET_IMPORTS_LIST,
  GET_TRANSFERS_LIST,
  RESET_TRANSFERS_LIST,
  GET_SIMPLE_TRANSFER_WEAPONS_LIST,
  GET_SIMPLE_TRANSFER_AMMUNITIONS_LIST,
  RESET_SIMPLE_TRANSFER_AMMUNITIONS_LIST,
  RESET_SIMPLE_TRANSFER_WEAPONS_LIST,
  RESET_SPOTCHECK_WEAPON_LIST,
  GET_SPOTCHECK_WEAPON_LIST,
  GET_SPOTCHECK_AMMUNITION_LIST,
  RESET_SPOTCHECK_AMMUNITION_LIST,
  GET_SPOTCHECK_FACILITY_LIST,
  RESET_SPOTCHECK_FACILITY_LIST,
  GET_MAP_FACILITY_LIST,
  RESET_MAP_FACILITY_LIST,
  GET_LOCATORS,
  RESET_LOCATORS,
  GET_COUNT,
  GET_IMPORT_AMMUNITION_COUNT,
  GET_IMPORT_WEAPON_COUNT,
} from "../ActionTypes";

interface intialState {
  RolesList: any;
  RolesListCount: any;
  RolesLoading: boolean;
  RolesPage: number;

  UnitsList: any;
  UnitsListCount: any;
  UnitsLoading: boolean;
  UnitsPage: number;

  UsersList: any;
  UsersListCount: any;
  UsersLoading: boolean;
  UsersPage: number;

  PersonnelList: any;
  PersonnelListCount: any;
  PersonnelLoading: boolean;
  PersonnelPage: number;

  FacilitiesList: any;
  FacilitiesListCount: any;
  FacilitiesLoading: boolean;
  FacilitiesPage: number;

  FacilitiesMapList: any;
  FacilitiesMapListCount: any;
  FacilityMarkers: any;

  FacilitiesMapLoading: boolean;
  FacilitiesMapPage: number;

  WeaponsList: any;
  WeaponsListCount: any;
  WeaponsLoading: boolean;
  WeaponsPage: number;
  WeaponExportQuery: any;

  WeaponsSimpleList: any;
  WeaponsSimpleListCount: number;
  WeaponsSimpleLoading: boolean;
  WeaponsSimplePage: number;
  WeaponsSimpleExportQuery: any;

  AmmunitionsList: any;
  AmmunitionsListCount: any;
  AmmunitionsLoading: boolean;
  AmmunitionsPage: number;
  AmmunitionExportQuery: any;

  AmmunitionsSimpleList: any;
  AmmunitionsSimpleListCount: number;
  AmmunitionsSimpleLoading: boolean;
  AmmunitionsSimplePage: number;
  AmmunitionSimpleExportQuery: any;

  transferweaponList: any;
  transferweaponListCount: number;
  transferWeaponPage: number;
  transferweaponListLoading: boolean;

  transferAmmunitionList: any;
  transferAmmunitionListCount: number;
  transferAmmunitionPage: number;
  transferAmmunitionListLoading: boolean;

  spotcheckWeaponList: any;
  spotcheckWeaponListCount: number;
  spotcheckWeaponPage: number;
  spotCheckWeaponListLoading: boolean;

  spotcheckAmmunitionList: any;
  spotcheckAmmunitionListCount: number;
  spotcheckAmmunitionPage: number;
  spotCheckAmmunitionListLoading: boolean;

  spotcheckFacilityList: any;
  spotcheckFacilityListCount: number;
  spotcheckFacilityPage: number;
  spotCheckFaciltyListLoading: boolean;

  locators: any;
  unitCount: any;
  facilityCount: any;
  weaponCount: any;
  transferCount: any;
  spotcheckCount: any;
  ammunitionCount: any;
  personnelCount: any;
  importsCount: any;
  userCount: any;
  roleCount: any;
  importWeaponCount: any;
  importAmmunitionCount: any;
  UnitExportQuery: any;
  UserExportQuery: any;
  RoleExportQuery: any;
  PersonnelExportQuery: any;
  TransferExportQuery: any;
  transferWeaponExportQuery: any;
  ImportExportQuery: any;
  spotcheckWeponExportQuery: any;
  spotcheckAmmuExportQuery: any;
  spotcheckFacilityExportQuery: any;
  FacilitiesMapExportQuery: any;
}
const intialState = {
  RolesList: [],
  RolesListCount: 0,
  RolesLoading: true,
  RolesPage: 1,

  UnitsList: [],
  UnitsListCount: 0,
  UnitsLoading: true,
  UnitsPage: 1,

  UsersList: [],
  UsersListCount: 0,
  UsersLoading: true,
  UsersPage: 1,

  PersonnelList: [],
  PersonnelListCount: 0,
  PersonnelLoading: true,
  PersonnelPage: 1,

  FacilitiesList: [],
  FacilitiesListCount: 0,
  FacilitiesLoading: true,
  FacilitiesPage: 1,
  FacilityExportQuery: [],

  FacilitiesMapLoading: true,
  FacilitiesMapList: [],
  FacilitiesMapListCount: 0,
  FacilitiesMapExportQuery: [],
  FacilityMarkers: [],

  WeaponExportQuery: [],
  WeaponsList: [],
  WeaponsListCount: 0,
  WeaponsPage: 1,
  WeaponsLoading: true,

  WeaponsSimpleList: [],
  WeaponsSimpleListCount: 0,
  WeaponsSimpleLoading: true,
  WeaponsSimplePage: 1,
  WeaponsSimpleExportQuery: [],

  AmmunitionsList: [],
  AmmunitionsListCount: 0,
  AmmunitionsLoading: true,

  ImportsList: [],
  ImportsListCount: 0,
  AmmunitionsPage: 1,

  FacilitiesMapPage: 1,

  ImportsPage: 1,
  ImportsListLoading: true,

  TransfersList: [],
  TransfersListCount: 0,
  TransfersPage: 1,
  TransfersListLoading: true,

  AmmunitionsSimpleList: [],
  AmmunitionsSimpleListCount: 0,
  AmmunitionsSimpleLoading: true,
  AmmunitionsSimplePage: 1,
  AmmunitionsSimpleExportQuery: [],
  AmmunitionExportQuery: [],

  transferweaponList: [],
  transferweaponListCount: 0,
  transferweaponPage: 1,
  transferweaponListLoading: true,

  transferAmmunitionList: [],
  transferAmmunitionListCount: 0,
  transferAmmunitionPage: 1,
  transferAmmunitionListLoading: true,

  spotcheckWeaponList: [],
  spotcheckWeaponListCount: 0,
  spotcheckWeaponPage: 1,
  spotCheckWeaponListLoading: true,

  spotcheckAmmunitionList: [],
  spotcheckAmmunitionListCount: 0,
  spotcheckAmmunitionPage: 1,
  spotCheckAmmunitionListLoading: true,

  spotcheckFacilityList: [],
  spotcheckFacilityListCount: 0,
  spotcheckFacilityPage: 1,
  spotCheckFaciltyListLoading: true,

  locators: [],
  unitCount: 0,
  facilityCount: 0,
  weaponCount: 0,
  ammunitionCount: 0,
  personnelCount: 0,
  importsCount: 0,
  userCount: 0,
  roleCount: 0,
  transferCount: 0,
  spotcheckCount: 0,
  importWeaponCount: 0,
  importAmmunitionCount: 0,
  UnitExportQuery: [],
  UserExportQuery: [],
  RoleExportQuery: [],
  PersonnelExportQuery: [],
  TransferExportQuery: [],
  transferWeaponExportQuery: [],
  ImportExportQuery: [],
  spotcheckWeponExportQuery: [],
  spotcheckAmmuExportQuery: [],
  spotcheckFacilityExportQuery: [],
};

const ListingReducer = (state = intialState, action: any) => {
  switch (action.type) {
    case GET_COUNT.UNITS:
      return {
        ...state,
        unitCount: action.payload.units,
      };
    case GET_COUNT.FACILITY:
      return {
        ...state,
        facilityCount: action.payload.facility,
      };
    case GET_COUNT.WEAPONS:
      return {
        ...state,
        weaponCount: action.payload.weapons,
      };
    case GET_COUNT.AMMUNITION:
      return {
        ...state,
        ammunitionCount: action.payload.ammunition,
      };
    case GET_COUNT.PERSONNEL:
      return {
        ...state,
        personnelCount: action.payload.personnel,
      };
    case GET_COUNT.TRANSFERS:
      return {
        ...state,
        transferCount: action.payload.transfer,
      };
    case GET_COUNT.SPOTCHECKS:
      return {
        ...state,
        spotcheckCount: action.payload.spotcheck,
      };
    case GET_COUNT.IMPORTS:
      return {
        ...state,
        importsCount: action.payload.imports,
      };
    case GET_COUNT.USERS:
      return {
        ...state,
        userCount: action.payload.user,
      };
    case GET_COUNT.ROLES:
      return {
        ...state,
        roleCount: action.payload.role,
      };
    case GET_IMPORT_WEAPON_COUNT:
      return {
        ...state,
        importWeaponCount: action.payload.total_weapons,
      };
    case GET_IMPORT_AMMUNITION_COUNT:
      return {
        ...state,
        importAmmunitionCount: action.payload.total_ammunitions,
      };
    case GET_USERS_LIST:
      return {
        ...state,
        UsersList: action.payload.data,
        UsersListCount: action.payload.data_count,
        UserExportQuery: action.payload.dynamic_filter_query,
        UsersLoading: false,
      };
    case GET_LOCATORS:
      return {
        ...state,
        locators: action.payload.homepage_data,
      };
    case RESET_LOCATORS:
      return {
        ...state,
        locators: [],
      };
    case GET_ROLES_LIST:
      return {
        ...state,
        RolesList: action.payload.roles,
        RolesListCount: action.payload.data_count,
        RoleExportQuery: action.payload.dynamic_filter_query,
        RolesLoading: false,
      };
    case GET_UNITS_LIST:
      return {
        ...state,
        UnitsList: action.payload.units,
        UnitsListCount: action.payload.data_count,

        UnitsLoading: false,
        UnitExportQuery: action.payload.dynamic_filter_query,
      };
    case GET_PERSONNELS_LIST:
      return {
        ...state,
        PersonnelList: [
          ...state.PersonnelList,
          ...action.payload.personnel_details,
        ],
        PersonnelListCount: action.payload.data_count,
        PersonnelExportQuery: action.payload.dynamic_filter_query,

        PersonnelPage: state.PersonnelPage + 1,
        PersonnelLoading: false,
      };
    case GET_FACILITY_LIST:
      return {
        ...state,
        FacilitiesList: action.payload.facility_details,
        FacilitiesListCount: action.payload.data_count,

        FacilitiesLoading: false,
        FacilityExportQuery: action.payload.export_query,
      };
    case GET_MAP_FACILITY_LIST:
      return {
        ...state,
        FacilitiesMapList: action.payload.facility_details,
        FacilitiesMapListCount: action.payload.data_count,
        FacilitiesMapExportQuery: action.payload.export_query,
        FacilitiesMapLoading: false,
        FacilityMarkers: action.payload.facility_ids,
      };
    case GET_WEAPONS_LIST:
      return {
        ...state,
        WeaponsList: action.payload.weapons_details,
        WeaponsListCount: action.payload.data_count,
        WeaponsLoading: false,
        WeaponExportQuery: action.payload.export_query,
      };
    case GET_SIMPLE_WEAPONS_LIST:
      return {
        ...state,
        WeaponsSimpleList: action.payload.weapons_details,
        WeaponsSimpleListCount: action.payload.data_count,
        WeaponsSimpleLoading: false,
        WeaponsSimpleExportQuery: action.payload.export_query,
      };

    case GET_SIMPLE_TRANSFER_WEAPONS_LIST:
      return {
        ...state,
        transferweaponList: action.payload.weapons_details,
        transferweaponListCount: action.payload.data_count,
        transferWeaponExportQuery: action.payload.dynamic_filter_query,

        transferweaponListLoading: false,
      };
    case GET_SIMPLE_TRANSFER_AMMUNITIONS_LIST:
      return {
        ...state,
        transferAmmunitionList: action.payload.ammunition_details,
        transferAmmunitionListCount: action.payload.count,
        transferAmmunitionListLoading: false,
        transferAmmunitionExportQuery: action.payload.dynamic_filter_query,
      };
    case GET_AMMUNITIONS_LIST:
      return {
        ...state,
        AmmunitionsList: action.payload.ammunition_details,
        AmmunitionsListCount: action.payload.count,
        AmmunitionsLoading: false,
        AmmunitionExportQuery: action.payload.export_query,
      };

    case GET_SIMPLE_AMMUNITIONS_LIST:
      return {
        ...state,
        AmmunitionsSimpleList: action.payload.ammunition_details,
        AmmunitionsSimpleListCount: action.payload.count,
        AmmunitionsSimpleLoading: false,
        AmmunitionsSimpleExportQuery: action.payload.export_query,
      };
    case RESET_USERS_LIST:
      return {
        ...state,
        UsersList: [],
        UsersListCount: 0,
        UsersLoading: true,
      };
    case RESET_SIMPLE_TRANSFER_AMMUNITIONS_LIST:
      return {
        ...state,
        transferAmmunitionList: [],
        transferAmmunitionListCount: 0,
        transferAmmunitionListLoading: true,
      };
    case RESET_SIMPLE_TRANSFER_WEAPONS_LIST:
      return {
        ...state,
        transferweaponList: [],
        transferweaponListCount: 0,
        transferweaponListLoading: true,
      };
    case RESET_ROLES_LIST:
      return {
        ...state,
        RolesList: [],
        RolesListCount: 0,
        RolesLoading: true,
      };

    case RESET_UNITS_LIST:
      return {
        ...state,
        UnitsList: [],
        UnitsListCount: 0,
        UnitsLoading: true,
      };
    case RESET_PERSONNELS_LIST:
      return {
        ...state,
        PersonnelList: [],
        PersonnelListCount: 0,
        PersonnelLoading: true,
        PersonnelPage: 1,
      };

    case RESET_FACILITY_LIST:
      return {
        ...state,
        FacilitiesList: [],
        FacilitiesListCount: 0,
        FacilitiesLoading: true,
        FacilityExportQuery: [],
      };
    case RESET_MAP_FACILITY_LIST:
      return {
        ...state,
        FacilitiesMapList: [],
        FacilitiesMapListCount: 0,
        FacilitiesMapExportQuery: [],
        FacilitiesMapLoading: true,
        FacilityMarkers: [],
      };
    case RESET_WEAPONS_LIST:
      return {
        ...state,
        WeaponsList: [],
        WeaponsListCount: 0,
        WeaponsLoading: true,
        WeaponExportQuery: [],
      };

    case RESET_SIMPLE_WEAPONS_LIST:
      return {
        ...state,
        WeaponsSimpleList: [],
        WeaponsSimpleListCount: 0,
        WeaponsSimpleLoading: true,
      };
    case RESET_AMMUNITIONS_LIST:
      return {
        ...state,
        AmmunitionsList: [],
        AmmunitionsListCount: 0,
        AmmunitionsLoading: true,
        AmmunitionExportQuery: [],
      };

    case RESET_SIMPLE_AMMUNITIONS_LIST:
      return {
        ...state,
        AmmunitionsSimpleList: [],
        AmmunitionsSimpleListCount: 0,
        AmmunitionsSimpleLoading: true,
      };
    case GET_IMPORTS_LIST:
      return {
        ...state,
        ImportsList: action.payload.import_details,
        ImportsListCount: action.payload.count,
        ImportExportQuery: action.payload.dynamic_filter_query,

        ImportsListLoading: false,
      };
    case GET_TRANSFERS_LIST:
      return {
        ...state,
        TransfersList: action.payload.transfers_list,
        TransfersListCount: action.payload.data_count,
        TransferExportQuery: action.payload.dynamic_filter_query,

        TransfersListLoading: false,
      };
    case RESET_IMPORTS_LIST:
      return {
        ...state,
        ImportsList: [],
        ImportsListCount: 0,
        ImportsListLoading: true,
      };
    case RESET_TRANSFERS_LIST:
      return {
        ...state,
        TransfersList: [],
        TransfersListCount: 0,
        TransfersListLoading: true,
      };
    case GET_SPOTCHECK_WEAPON_LIST:
      return {
        ...state,
        spotcheckWeaponList: action.payload.spotcheck,
        spotcheckWeaponListCount: action.payload.data_count,
        spotcheckWeponExportQuery: action.payload.dynamic_filter,
        spotCheckWeaponListLoading: false,
      };
    case RESET_SPOTCHECK_WEAPON_LIST:
      return {
        ...state,
        spotcheckWeaponList: [],
        spotcheckWeaponListCount: 0,
        spotCheckWeaponListLoading: true,
      };
    case GET_SPOTCHECK_FACILITY_LIST:
      return {
        ...state,
        spotcheckFacilityList: action.payload.spotcheck,
        spotcheckFacilityListCount: action.payload.data_count,
        spotcheckFacilityExportQuery: action.payload.dynamic_filter,
        spotCheckFaciltyListLoading: false,
      };
    case RESET_SPOTCHECK_FACILITY_LIST:
      return {
        ...state,
        spotcheckFacilityList: [],
        spotcheckFacilityListCount: 0,
        spotCheckFaciltyListLoading: true,
      };
    case GET_SPOTCHECK_AMMUNITION_LIST:
      return {
        ...state,
        spotcheckAmmunitionList: action.payload.spotcheck,
        spotcheckAmmunitionListCount: action.payload.data_count,
        spotcheckAmmuExportQuery: action.payload.dynamic_filter,
        spotCheckAmmunitionListLoading: false,
      };
    case RESET_SPOTCHECK_AMMUNITION_LIST:
      return {
        ...state,
        spotcheckAmmunitionList: [],
        spotcheckAmmunitionListCount: 0,
        spotCheckAmmunitionListLoading: true,
      };
    case REHYDRATE:
    default:
      return state;
  }
};

export default ListingReducer;
