import store from "../../store";
import { LOG_OUT, SET_AUTH, SET_USER } from "../ActionTypes";

const { dispatch }: any = store || {};

const onLogin = (accessToken: any, refreshToken: string) => {
  dispatch({
    type: SET_AUTH,
    accessToken,
    refreshToken,
  });
};
export const setUserCredentials = (user: any) => {
  dispatch({
    type: SET_USER,
    user,
  });
};

const onLogout = () => {
  dispatch({
    type: LOG_OUT,
  });
};

// const toggleLoader = (isloader: boolean) => {
//   dispatch({
//     type: SHOW_HIDE_LOADER,
//     loader: isloader,
//   });
// };

export { onLogin, onLogout };
