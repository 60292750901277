import { REHYDRATE } from "redux-persist";
import { LOG_OUT, SET_AUTH, SET_USER } from "../ActionTypes";

export interface intialState {
  user: any;
  token: string;
  refreshToken: string;
  loader: boolean;
}
const intialState = {
  user: null,
  token: "",
  refreshToken: "",
  loader: false,
};

const AuthReducer = (state = intialState, action: any) => {
  switch (action.type) {
    case SET_AUTH:
      return {
        ...state,
        token: action.accessToken,
        refreshToken: action.refreshToken,
      };
    case SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case LOG_OUT:
      return {
        ...state,
        user: null,
        token: "",
        refreshToken: "",
      };
    case REHYDRATE:

    default:
      return state;
  }
};

export default AuthReducer;
