import {
  GET_SIMPLE_AMMUNITION_PENDING,
  GET_MORE_SIMPLE_AMMUNITION_PENDING,
  GET_SIMPLE_AMMUNITION_SUCCESS,
  GET_MORE_SIMPLE_AMMUNITION_SUCCESS,
  RESET_SIMPLE_AMMUNITION,
  GET_HOME_AMMUNITION,
  RESET_HOME_AMMUNITION,
} from "../ActionTypes/simpleAmmunition";
import { REHYDRATE } from "redux-persist";

interface InitialState {
  simpleAmmunitionLoading: boolean;
  moreSimpleAmmunitionLoading: boolean;
  simpleAmmunitionList: any;
  simpleAmmunitionListCount: number;
  simpleAmmunitionPage: number;
  simpleAmmunitionExportQuery: any;
  simpleAmmunitionMapMarkers: any;
}

const initialState: InitialState = {
  simpleAmmunitionLoading: false,
  moreSimpleAmmunitionLoading: false,
  simpleAmmunitionList: [],
  simpleAmmunitionListCount: 0,
  simpleAmmunitionPage: 1,
  simpleAmmunitionExportQuery: [],
  simpleAmmunitionMapMarkers: [],
};

const AmmunitionReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_HOME_AMMUNITION:
      return {
        ...state,
        simpleAmmunitionMapMarkers: action.payload.homepage_ammunition,
      };
    case GET_SIMPLE_AMMUNITION_PENDING:
      return {
        ...state,
        simpleAmmunitionLoading: true,
      };
    case GET_MORE_SIMPLE_AMMUNITION_PENDING:
      return {
        ...state,
        moreSimpleAmmunitionLoading: true,
      };
    case GET_SIMPLE_AMMUNITION_SUCCESS:
      return {
        ...state,
        simpleAmmunitionLoading: false,
        simpleAmmunitionList: action.payload.ammunition_details,
        simpleAmmunitionListCount: action.payload.count,
        simpleAmmunitionExportQuery: action.payload.export_query,
        // simpleAmmunitionMapMarkers: action.payload.homepage_ammunition,
        simpleAmmunitionPage: 2,
      };
    case GET_MORE_SIMPLE_AMMUNITION_SUCCESS:
      const newAmmunitionList = action.payload.ammunition_details;
      const { simpleAmmunitionList } = state;
      return {
        ...state,
        moreSimpleAmmunitionLoading: false,
        simpleAmmunitionList: [...simpleAmmunitionList, ...newAmmunitionList],
        simpleAmmunitionListCount: action.payload.count,
        simpleAmmunitionExportQuery: action.payload.export_query,
        simpleAmmunitionPage: state.simpleAmmunitionPage + 1,
      };
    case RESET_HOME_AMMUNITION:
      return {
        ...state,
        simpleAmmunitionMapMarkers: [],
      };
    case RESET_SIMPLE_AMMUNITION:
      return {
        ...state,
        simpleAmmunitionLoading: false,
        moreSimpleAmmunitionLoading: false,
        simpleAmmunitionList: [],
        simpleAmmunitionListCount: 0,
        simpleAmmunitionExportQuery: [],
        // simpleAmmunitionMapMarkers: [],
        simpleAmmunitionPage: 1,
      };
    case REHYDRATE:
    default:
      return state;
  }
};

export default AmmunitionReducer;
