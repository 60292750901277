import {
  GET_UNIT_PENDING,
  GET_MORE_UNIT_PENDING,
  GET_UNIT_SUCCESS,
  GET_MORE_UNIT_SUCCESS,
  RESET_UNIT,
} from "../ActionTypes/unit";
import { REHYDRATE } from "redux-persist";

interface InitialState {
  unitLoading: boolean;
  moreUnitLoading: boolean;
  unitList: any;
  unitListCount: number;
  unitPage: number;
  unitExportQuery: any;
}

const initialState: InitialState = {
  unitLoading: false,
  moreUnitLoading: false,
  unitList: [],
  unitListCount: 0,
  unitPage: 1,
  unitExportQuery: [],
};

const UnitReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_UNIT_PENDING:
      return {
        ...state,
        unitLoading: true,
      };
    case GET_MORE_UNIT_PENDING:
      return {
        ...state,
        moreUnitLoading: true,
      };
    case GET_UNIT_SUCCESS:
      return {
        ...state,
        unitLoading: false,
        unitList: action.payload.units,
        unitListCount: action.payload.data_count,
        unitExportQuery: action.payload.dynamic_filter_query,
        unitPage: 2,
      };
    case GET_MORE_UNIT_SUCCESS:
      const newUnitList = action.payload.units;
      const { unitList } = state;
      return {
        ...state,
        moreUnitLoading: false,
        unitList: [...unitList, ...newUnitList],
        unitListCount: action.payload.data_count,
        unitExportQuery: action.payload.dynamic_filter_query,
        unitPage: state.unitPage + 1,
      };
    case RESET_UNIT:
      return {
        ...state,
        unitLoading: false,
        moreUnitLoading: false,
        unitList: [],
        unitListCount: 0,
        unitExportQuery: [],
        unitPage: 1,
      };
    case REHYDRATE:
    default:
      return state;
  }
};

export default UnitReducer;
