import {
  GET_FACILITY_PENDING,
  GET_MORE_FACILITY_PENDING,
  GET_FACILITY_SUCCESS,
  GET_MORE_FACILITY_SUCCESS,
  RESET_FACILITY,
} from "../ActionTypes/facility";
import { REHYDRATE } from "redux-persist";

interface InitialState {
  facilityLoading: boolean;
  moreFacilityLoading: boolean;
  facilityList: any;
  facilityListCount: number;
  facilityPage: number;
  facilityExportQuery: any;
}

const initialState: InitialState = {
  facilityLoading: false,
  moreFacilityLoading: false,
  facilityList: [],
  facilityListCount: 0,
  facilityPage: 1,
  facilityExportQuery: [],
};

const FacilityReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_FACILITY_PENDING:
      return {
        ...state,
        facilityLoading: true,
      };
    case GET_MORE_FACILITY_PENDING:
      return {
        ...state,
        moreFacilityLoading: true,
      };
    case GET_FACILITY_SUCCESS:
      return {
        ...state,
        facilityLoading: false,
        facilityList: action.payload.facility_details,
        facilityListCount: action.payload.data_count,
        facilityExportQuery: action.payload.export_query,
        facilityPage: 2,
      };
    case GET_MORE_FACILITY_SUCCESS:
      const newFacilityList = action.payload.facility_details;
      const { facilityList } = state;
      return {
        ...state,
        moreFacilityLoading: false,
        facilityList: [...facilityList, ...newFacilityList],
        facilityListCount: action.payload.data_count,
        facilityExportQuery: action.payload.export_query,
        facilityPage: state.facilityPage + 1,
      };
    case RESET_FACILITY:
      return {
        ...state,
        facilityLoading: false,
        moreFacilityLoading: false,
        facilityList: [],
        facilityListCount: 0,
        facilityExportQuery: [],
        facilityPage: 1,
      };
    case REHYDRATE:
    default:
      return state;
  }
};

export default FacilityReducer;
