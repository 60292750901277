export const GET_ROLE_LOG = "GET_ROLE_LOG";
export const RESET_ROLE_LOG = "RESET_ROLE_LOG";
export const GET_USER_LOG = "GET_USER_LOG";
export const RESET_USER_LOG = "RESET_USER_LOG";
export const GET_UNIT_LOG = "GET_UNIT_LOG";
export const RESET_UNIT_LOG = "RESET_UNIT_LOG";
export const GET_AMMUNITION_LOG = "GET_AMMUNITION_LOG";
export const RESET_AMMUNITION_LOG = "RESET_AMMUNITION_LOG";
export const GET_FACILITY_LOG = "GET_FACILITY_LOG";
export const RESET_FACILITY_LOG = "RESET_FACILITY_LOG";
export const GET_IMPORT_LOG = "GET_IMPORT_LOG";
export const RESET_IMPORT_LOG = "RESET_IMPORT_LOG";
export const GET_PERSONNEL_LOG = "GET_PERSONNEL_LOG";
export const RESET_PERSONNEL_LOG = "RESET_PERSONNEL_LOG";
export const GET_WEAPONS_LOG = "GET_WEAPONS_LOG";
export const RESET_WEAPONS_LOG = "RESET_WEAPONS_LOG";
export const GET_SIMPLE_WEAPON_LOG = "GET_SIMPLE_WEAPON_LOG";
export const RESET_SIMPLE_WEAPON_LOG = "RESET_SIMPLE_WEAPON_LOG";
export const GET_SIMPLE_AMMO_LOG = "GET_SIMPLE_AMMO_LOG";
export const RESET_SIMPLE_AMMO_LOG = "RESET_SIMPLE_AMMO_LOG";
