import { REHYDRATE } from "redux-persist";
import {
  GET_DYNAMIC_TRANSFER_WEAPONS,
  GET_DYNAMIC_WEAPONS_LIST,
  RESET_DYNAMIC_TRANSFER_WEAPONS,
  RESET_DYNAMIC_WEAPONS_LIST,
} from "../ActionTypes";

interface intialState {
  DynamicFilterSecurityForces: any;
  DynamicFilterDivisions: any;
  DynamicFilterUnits: any;
  DynamicFilterWeaponStatus: any;
  DynamicFilterManufacturingCountry: any;
  DynamicFilterCountriesList: any;
  DynamicFilterManufacturerList: any;
  DynamicFilterJvtWeaponStatus: any;
  DynamicFilterAcceptReject: any;
  DynamicFilterWeaponTypes: any;
  DynamicFilterWeaponCalibre: any;
  DynamicFilterConditionStatus: any;
  DynamicFilterOwnershipStatus: any;
  DynamicFilterCapturedStatus: any;
  DynamicFilterRegion: any;
  DynamicFilterTransWeaponStatus: any;
  DynamicFilterTransWeaponTypes: any;
  DynamicFilterTransWeaponCalibre: any;
  DynamicFilterTransWeaponCondition: any;
  DynamicFilterTransWeaponOwnership: any;
  DynamicFilterTransWeaponRegions: any;
  DynamicFilterTransWeaponSF: any;
  DynamicFilterTransWeaponDivisions: any;
  DynamicFilterTransWeaponUnits: any;
  DynamicFilterTransWeaponCaptured: any;
  DynamicFilterDistrict: any;
  DynamicFilterCapturedFrom: any;
  DynamicFilterCapturedRegion: any;
  DynamicFilterTransWeaponFacility: any;
  DynamicFilterWeaponFacilities: any;
  DynamicFilterRankList: any;
  DynamicWeaponListLoading: boolean;
  DynamicTranWeaponListLoading: boolean;
}
const intialState = {
  DynamicFilterSecurityForces: [],
  DynamicFilterDivisions: [],
  DynamicFilterUnits: [],
  DynamicFilterWeaponStatus: [],
  DynamicFilterManufacturingCountry: [],
  DynamicFilterCountriesList: [],
  DynamicFilterManufacturerList: [],
  DynamicFilterAcceptReject: [],
  DynamicFilterJvtWeaponStatus: [],
  DynamicFilterWeaponTypes: [],
  DynamicFilterWeaponCalibre: [],
  DynamicFilterConditionStatus: [],
  DynamicFilterOwnershipStatus: [],
  DynamicFilterCapturedStatus: [],
  DynamicFilterRegion: [],
  DynamicFilterTransWeaponStatus: [],
  DynamicFilterTransWeaponTypes: [],
  DynamicFilterTransWeaponCalibre: [],
  DynamicFilterTransWeaponCondition: [],
  DynamicFilterTransWeaponOwnership: [],
  DynamicFilterTransWeaponRegions: [],
  DynamicFilterTransWeaponSF: [],
  DynamicFilterTransWeaponDivisions: [],
  DynamicFilterTransWeaponUnits: [],
  DynamicFilterTransWeaponCaptured: [],
  DynamicFilterDistrict: [],
  DynamicFilterCapturedFrom: [],
  DynamicFilterCapturedRegion: [],
  DynamicFilterTransWeaponFacility: [],
  DynamicFilterWeaponFacilities: [],
  DynamicFilterRankList: [],
  DynamicWeaponListLoading: true,
  DynamicTranWeaponListLoading: true,
};

const DynamicFilterWeaponListReducer = (state = intialState, action: any) => {
  switch (action.type) {
    case GET_DYNAMIC_WEAPONS_LIST:
      return {
        ...state,
        DynamicFilterSecurityForces: action.payload?.security_forces,
        DynamicFilterDivisions: action.payload?.divisions,
        DynamicFilterUnits: action.payload?.units,
        DynamicFilterWeaponStatus: action.payload?.weapon_status,
        DynamicFilterManufacturingCountry:
          action.payload?.manufacturing_country,
        DynamicFilterCountriesList: action.payload?.countries_list,
        DynamicFilterManufacturerList: action.payload?.manufacturer_list,
        DynamicFilterAcceptReject: action.payload?.accept_reject,
        DynamicFilterJvtWeaponStatus: action.payload?.jvt_weapon_status,
        DynamicFilterWeaponTypes: action.payload?.weapon_types,
        DynamicFilterWeaponCalibre: action.payload?.weapon_calibre,
        DynamicFilterConditionStatus: action.payload?.condition_status,
        DynamicFilterOwnershipStatus: action.payload?.ownership_status,
        DynamicFilterCapturedStatus: action.payload?.captured_status,
        DynamicFilterRegion: action.payload?.region,
        DynamicFilterDistrict: action.payload?.captured_district,
        DynamicFilterCapturedRegion: action.payload?.captured_region,
        DynamicFilterCapturedFrom: action.payload?.captured_from,
        DynamicFilterWeaponFacilities: action.payload?.facility,
        DynamicFilterRankList: action.payload?.personnel_rank,
        DynamicWeaponListLoading: false,
      };
    case RESET_DYNAMIC_WEAPONS_LIST:
      return {
        ...state,
        DynamicFilterSecurityForces: [],
        DynamicFilterDivisions: [],
        DynamicFilterUnits: [],
        DynamicFilterWeaponStatus: [],
        DynamicFilterManufacturingCountry: [],
        DynamicFilterCountriesList: [],
        DynamicFilterManufacturerList: [],
        DynamicFilterAcceptReject: [],
        DynamicFilterJvtWeaponStatus: [],
        DynamicFilterWeaponTypes: [],
        DynamicFilterWeaponCalibre: [],
        DynamicFilterConditionStatus: [],
        DynamicFilterOwnershipStatus: [],
        DynamicFilterCapturedStatus: [],
        DynamicFilterRegion: [],
        DynamicFilterDistrict: [],
        DynamicFilterCapturedRegion: [],
        DynamicFilterCapturedFrom: [],
        DynamicFilterWeaponFacilities: [],
        DynamicFilterRankList: [],
        DynamicWeaponListLoading: true,
      };
    case GET_DYNAMIC_TRANSFER_WEAPONS:
      return {
        ...state,
        DynamicFilterTransWeaponStatus: action.payload.weapon_status,
        DynamicFilterTransWeaponTypes: action.payload.weapon_types,
        DynamicFilterTransWeaponCalibre: action.payload.weapon_calibre,
        DynamicFilterTransWeaponCondition: action.payload.condition_status,
        DynamicFilterTransWeaponOwnership: action.payload.ownership_status,
        DynamicFilterTransWeaponRegions: action.payload.region,
        DynamicFilterTransWeaponSF: action.payload.security_forces,
        DynamicFilterTransWeaponDivisions: action.payload.divisions,
        DynamicFilterTransWeaponUnits: action.payload.units,
        DynamicFilterTransWeaponCaptured: action.payload.captured_status,
        DynamicFilterTransWeaponFacility: action.payload.facility,
        DynamicTranWeaponListLoading: false,
      };
    case RESET_DYNAMIC_TRANSFER_WEAPONS:
      return {
        ...state,
        DynamicFilterTransWeaponStatus: [],
        DynamicFilterTransWeaponTypes: [],
        DynamicFilterTransWeaponCalibre: [],
        DynamicFilterTransWeaponCondition: [],
        DynamicFilterTransWeaponOwnership: [],
        DynamicFilterTransWeaponRegions: [],
        DynamicFilterTransWeaponSF: [],
        DynamicFilterTransWeaponDivisions: [],
        DynamicFilterTransWeaponUnits: [],
        DynamicFilterTransWeaponCaptured: [],
        DynamicFilterTransWeaponFacility: [],
        DynamicTranWeaponListLoading: true,
      };

    case REHYDRATE:
    default:
      return state;
  }
};

export default DynamicFilterWeaponListReducer;
