import {
  GET_ROLE_PENDING,
  GET_MORE_ROLE_PENDING,
  GET_ROLE_SUCCESS,
  GET_MORE_ROLE_SUCCESS,
  RESET_ROLE,
} from "../ActionTypes/role";
import { REHYDRATE } from "redux-persist";

interface InitialState {
  roleLoading: boolean;
  moreRoleLoading: boolean;
  roleList: any;
  roleListCount: number;
  rolePage: number;
  roleExportQuery: any;
}

const initialState: InitialState = {
  roleLoading: false,
  moreRoleLoading: false,
  roleList: [],
  roleListCount: 0,
  rolePage: 1,
  roleExportQuery: [],
};

const RoleReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_ROLE_PENDING:
      return {
        ...state,
        roleLoading: true,
      };
    case GET_MORE_ROLE_PENDING:
      return {
        ...state,
        moreRoleLoading: true,
      };
    case GET_ROLE_SUCCESS:
      return {
        ...state,
        roleLoading: false,
        roleList: action.payload.roles,
        roleListCount: action.payload.data_count,
        roleExportQuery: action.payload.dynamic_filter_query,
        rolePage: 2,
      };
    case GET_MORE_ROLE_SUCCESS:
      const newRoleList = action.payload.roles;
      const { roleList } = state;
      return {
        ...state,
        moreRoleLoading: false,
        roleList: [...roleList, ...newRoleList],
        roleListCount: action.payload.data_count,
        roleExportQuery: action.payload.dynamic_filter_query,
        rolePage: state.rolePage + 1,
      };
    case RESET_ROLE:
      return {
        ...state,
        roleLoading: false,
        moreRoleLoading: false,
        roleList: [],
        roleListCount: 0,
        roleExportQuery: [],
        rolePage: 1,
      };
    case REHYDRATE:
    default:
      return state;
  }
};

export default RoleReducer;
