import {
  GET_PERSONNEL_PENDING,
  GET_MORE_PERSONNEL_PENDING,
  GET_PERSONNEL_SUCCESS,
  GET_MORE_PERSONNEL_SUCCESS,
  RESET_PERSONNEL,
  GET_PERSONNEL_DETAILS,
  RESET_PERSONNEL_DETAILS,
} from "../ActionTypes/personnel";
import { REHYDRATE } from "redux-persist";

interface InitialState {
  personnelLoading: boolean;
  morePersonnelLoading: boolean;
  personnelList: any;
  personnelListCount: number;
  personnelPage: number;
  personnelExportQuery: any;
  personnelDetails: any;
}

const initialState: InitialState = {
  personnelLoading: false,
  morePersonnelLoading: false,
  personnelList: [],
  personnelListCount: 0,
  personnelPage: 1,
  personnelExportQuery: [],
  personnelDetails: [],
};

const PersonnelReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_PERSONNEL_PENDING:
      return {
        ...state,
        personnelLoading: true,
      };
    case GET_MORE_PERSONNEL_PENDING:
      return {
        ...state,
        morePersonnelLoading: true,
      };
    case GET_PERSONNEL_SUCCESS:
      return {
        ...state,
        personnelLoading: false,
        personnelList: action.payload.personnel_details,
        personnelListCount: action.payload.data_count,
        personnelExportQuery: action.payload.dynamic_filter_query,
        personnelPage: 2,
      };
    case GET_MORE_PERSONNEL_SUCCESS:
      const newPersonnelList = action.payload.personnel_details;
      const { personnelList } = state;
      return {
        ...state,
        morePersonnelLoading: false,
        personnelList: [...personnelList, ...newPersonnelList],
        personnelListCount: action.payload.data_count,
        personnelExportQuery: action.payload.dynamic_filter_query,
        personnelPage: state.personnelPage + 1,
      };
    case GET_PERSONNEL_DETAILS:
      return {
        ...state,
        personnelDetails: action.payload.data,
      };
    case RESET_PERSONNEL_DETAILS:
      return {
        ...state,
        personnelDetails: [],
      };
    case RESET_PERSONNEL:
      return {
        ...state,
        personnelLoading: false,
        morePersonnelLoading: false,
        personnelList: [],
        personnelListCount: 0,
        personnelExportQuery: [],
        personnelPage: 1,
      };
    case REHYDRATE:
    default:
      return state;
  }
};

export default PersonnelReducer;
