import { REHYDRATE } from "redux-persist";
import {
  GET_AMMUNITION_LOG,
  GET_FACILITY_LOG,
  GET_IMPORT_LOG,
  GET_PERSONNEL_LOG,
  GET_ROLE_LOG,
  GET_UNIT_LOG,
  GET_USER_LOG,
  GET_WEAPONS_LOG,
  RESET_AMMUNITION_LOG,
  RESET_FACILITY_LOG,
  RESET_IMPORT_LOG,
  RESET_PERSONNEL_LOG,
  RESET_ROLE_LOG,
  RESET_UNIT_LOG,
  RESET_USER_LOG,
  RESET_WEAPONS_LOG,
  GET_SIMPLE_AMMO_LOG,
  GET_SIMPLE_WEAPON_LOG,
  RESET_SIMPLE_AMMO_LOG,
  RESET_SIMPLE_WEAPON_LOG,
} from "../ActionTypes/log";

interface intialState {
  roleLog: [];
  userLog: [];
  ammunitionLog: [];
  facilityLog: [];
  importLog: [];
  personnelLog: [];
  weaponLog: [];
  unitLog: [];
  simpleWeaponLog: [];
  simpleAmmoLog: [];
}
const intialState = {
  roleLog: [],
  userLog: [],
  ammunitionLog: [],
  facilityLog: [],
  importLog: [],
  personnelLog: [],
  weaponLog: [],
  unitLog: [],
  simpleWeaponLog: [],
  simpleAmmoLog: [],
};

const LogReducer = (state = intialState, action: any) => {
  switch (action.type) {
    case GET_SIMPLE_WEAPON_LOG:
      return {
        ...state,
        simpleWeaponLog: action.payload.history_logs,
      };

    case RESET_SIMPLE_WEAPON_LOG:
      return {
        simpleWeaponLog: [],
      };
    case GET_SIMPLE_AMMO_LOG:
      return {
        ...state,
        simpleAmmoLog: action.payload.history_logs,
      };

    case RESET_SIMPLE_AMMO_LOG:
      return {
        simpleAmmoLog: [],
      };
    case GET_ROLE_LOG:
      return {
        ...state,
        roleLog: action.payload,
      };

    case RESET_ROLE_LOG:
      return {
        roleLog: [],
      };
    case GET_USER_LOG:
      return {
        ...state,
        userLog: action.payload,
      };

    case RESET_USER_LOG:
      return {
        userLog: [],
      };
    case GET_UNIT_LOG:
      return {
        ...state,
        unitLog: action.payload,
      };

    case RESET_UNIT_LOG:
      return {
        unitLog: [],
      };
    case GET_AMMUNITION_LOG:
      return {
        ...state,
        ammunitionLog: action.payload,
      };

    case RESET_AMMUNITION_LOG:
      return {
        ammunitionLog: [],
      };
    case GET_FACILITY_LOG:
      return {
        ...state,
        facilityLog: action.payload,
      };

    case RESET_FACILITY_LOG:
      return {
        facilityLog: [],
      };
    case GET_IMPORT_LOG:
      return {
        ...state,
        importLog: action.payload,
      };

    case RESET_IMPORT_LOG:
      return {
        importLog: [],
      };
    case GET_PERSONNEL_LOG:
      return {
        ...state,
        personnelLog: action.payload,
      };

    case RESET_PERSONNEL_LOG:
      return {
        personnelLog: [],
      };
    case GET_WEAPONS_LOG:
      return {
        ...state,
        weaponLog: action.payload,
      };

    case RESET_WEAPONS_LOG:
      return {
        weaponLog: [],
      };
    case REHYDRATE:

    default:
      return state;
  }
};

export default LogReducer;
