import {
  GET_WEAPON_PENDING,
  GET_MORE_WEAPON_PENDING,
  GET_WEAPON_SUCCESS,
  GET_MORE_WEAPON_SUCCESS,
  RESET_WEAPON,
  GET_WEAPON_DETAILS,
  RESET_WEAPON_DETAILS,
} from "../ActionTypes/weapon";
import { REHYDRATE } from "redux-persist";

interface InitialState {
  weaponLoading: boolean;
  moreWeaponLoading: boolean;
  weaponList: any;
  weaponListCount: number;
  weaponPage: number;
  weaponExportQuery: any;
  weaponDetails: any;
}

const initialState: InitialState = {
  weaponLoading: false,
  moreWeaponLoading: false,
  weaponList: [],
  weaponListCount: 0,
  weaponPage: 1,
  weaponExportQuery: [],
  weaponDetails: {},
};

const WeaponReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_WEAPON_PENDING:
      return {
        ...state,
        weaponLoading: true,
      };
    case GET_MORE_WEAPON_PENDING:
      return {
        ...state,
        moreWeaponLoading: true,
      };
    case GET_WEAPON_SUCCESS:
      return {
        ...state,
        weaponLoading: false,
        weaponList: action.payload.weapons_details,
        weaponListCount: action.payload.data_count,
        weaponExportQuery: action.payload.export_query,
        weaponPage: 2,
      };
    case GET_MORE_WEAPON_SUCCESS:
      const newWeaponList = action.payload.weapons_details;
      const { weaponList } = state;
      return {
        ...state,
        moreWeaponLoading: false,
        weaponList: [...weaponList, ...newWeaponList],
        weaponListCount: action.payload.data_count,
        weaponExportQuery: action.payload.export_query,
        weaponPage: state.weaponPage + 1,
      };
    case RESET_WEAPON:
      return {
        ...state,
        weaponLoading: false,
        moreWeaponLoading: false,
        weaponList: [],
        weaponListCount: 0,
        weaponExportQuery: [],
        weaponPage: 1,
      };
    case GET_WEAPON_DETAILS:
      return {
        ...state,
        weaponDetails: action.payload,
      };
    case RESET_WEAPON_DETAILS:
      return {
        ...state,
        weaponDetails: {},
      };
    case REHYDRATE:
    default:
      return state;
  }
};

export default WeaponReducer;
