import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import En from "../constants/languages/en";
import So from "../constants/languages/so";

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translations: En,
    },
    so: {
      translations: So,
    },
  },

  fallbackLng: "en",
  debug: false,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  // we use content as keys
  keySeparator: ".",
});

export default i18n;
