export const GET_TRANSFER_AMMUNITION_PENDING =
  "GET_TRANSFER_AMMUNITION_PENDING";
export const GET_MORE_TRANSFER_AMMUNITION_PENDING =
  "GET_MORE_TRANSFER_AMMUNITION_PENDING";
export const GET_TRANSFER_AMMUNITION_SUCCESS =
  "GET_TRANSFER_AMMUNITION_SUCCESS";
export const GET_MORE_TRANSFER_AMMUNITION_SUCCESS =
  "GET_MORE_TRANSFER_AMMUNITION_SUCCESS";
export const RESET_TRANSFER_AMMUNITION = "RESET_TRANSFER_AMMUNITION";
export const GET_TRANSIT_TRANSFER_AMMUNITION_PENDING =
  "GET_TRANSIT_TRANSFER_AMMUNITION_PENDING";
export const GET_MORE__TRANSIT_TRANSFER_AMMUNITION_PENDING =
  "GET_MORE__TRANSIT_TRANSFER_AMMUNITION_PENDING";
export const GET__TRANSIT_TRANSFER_AMMUNITION_SUCCESS =
  "GET__TRANSIT_TRANSFER_AMMUNITION_SUCCESS";
export const GET_MORE__TRANSIT_TRANSFER_AMMUNITION_SUCCESS =
  "GET_MORE__TRANSIT_TRANSFER_AMMUNITION_SUCCESS";
export const RESET__TRANSIT_TRANSFER_AMMUNITION =
  "RESET__TRANSIT_TRANSFER_AMMUNITION";
