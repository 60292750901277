import { lazy, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Sidebar from "../components/sidebar";
import { useSelector } from "react-redux";
import AuthRoutes from "./authRoutes";

const routes = [
  {
    path: "/",
    Component: lazy(() => import("../pages/home")),
    exact: true,
  },

  {
    path: "/home",
    Component: lazy(() => import("../pages/home")),
    exact: true,
  },
  {
    path: "/user",
    Component: lazy(() => import("../pages/userManagement/listUser")),
    exact: true,
  },
  {
    path: "/user/:id",
    Component: lazy(() => import("../pages/userManagement/viewUser")),
    exact: true,
  },
  {
    path: "/user/add/:id",
    Component: lazy(() => import("../pages/userManagement/editUser")),
    exact: true,
  },
  {
    path: "/personalDetails",
    Component: lazy(() => import("../pages/userManagement/viewUser")),
    exact: true,
  },
  {
    path: "/personalDetails/edit",
    Component: lazy(() => import("../pages/userManagement/editUser")),
    exact: true,
  },
  {
    path: "/role",
    Component: lazy(() => import("../pages/roleManagement/listRole")),
    exact: true,
  },
  {
    path: "/role/create",
    Component: lazy(() => import("../pages/roleManagement/createRole")),
    exact: true,
  },
  {
    path: "/role/edit/:id",
    Component: lazy(() => import("../pages/roleManagement/createRole")),
    exact: true,
  },
  {
    path: "/role/:id",
    Component: lazy(() => import("../pages/roleManagement/viewRole")),
    exact: true,
  },
  {
    path: "/reports/imports/byCountryTypeQty",
    Component: lazy(
      () => import("../pages/reports/listReports/imports/byCountryTypeQty")
    ),
    exact: true,
  },
  {
    path: "/reports/imports/verifiedByImport",
    Component: lazy(
      () => import("../pages/reports/listReports/imports/verifiedByImport")
    ),
    exact: true,
  },
  {
    path: "/reports/weapons/holdingsByUnit",
    Component: lazy(
      () => import("../pages/reports/listReports/weapons/holdingsByUnit")
    ),
    exact: true,
  },
  {
    path: "/reports/weapons/unitWeapon",
    Component: lazy(
      () => import("../pages/reports/listReports/weapons/unitWeapon")
    ),
    exact: true,
  },
  {
    path: "/reports/weapons/holdingsOverTime",
    Component: lazy(
      () => import("../pages/reports/listReports/weapons/holdingsOverTime")
    ),
    exact: true,
  },
  {
    path: "/reports/weapons/lostWeapon",
    Component: lazy(
      () => import("../pages/reports/listReports/weapons/lostWeapon")
    ),
    exact: true,
  },
  {
    path: "/reports/weapons/maintenance",
    Component: lazy(
      () => import("../pages/reports/listReports/weapons/maintenance")
    ),
    exact: true,
  },
  {
    path: "/reports/weapons/c",
    Component: lazy(
      () => import("../pages/reports/listReports/weapons/captured")
    ),
    exact: true,
  },
  {
    path: "/reports/ammunition/captured",
    Component: lazy(
      () => import("../pages/reports/listReports/ammunition/captured")
    ),
    exact: true,
  },

  {
    path: "/reports/ammunition/smallArmsByUnit",
    Component: lazy(
      () => import("../pages/reports/listReports/ammunition/smallArmsByUnit")
    ),
    exact: true,
  },
  {
    path: "/reports/ammunition/holdingsOverTime",
    Component: lazy(
      () => import("../pages/reports/listReports/ammunition/holdingsOverTime")
    ),
    exact: true,
  },
  {
    path: "/reports/ammunition/lostAmmunition",
    Component: lazy(
      () => import("../pages/reports/listReports/ammunition/lostAmmunition")
    ),
    exact: true,
  },
  {
    path: "/reports/ammunition/unitAmmunition",
    Component: lazy(
      () => import("../pages/reports/listReports/ammunition/unitAmmunition")
    ),
    exact: true,
  },
  {
    path: "/reports/spotchecks/monthlySpotchecks",
    Component: lazy(
      () => import("../pages/reports/listReports/spotchecks/monthlySpotchecks")
    ),
    exact: true,
  },
  {
    path: "/reports/spotchecks/spotcheckByImport",
    Component: lazy(
      () => import("../pages/reports/listReports/spotchecks/spotcheckByImport")
    ),
    exact: true,
  },
  {
    path: "/reports/verification/verificationReport",
    Component: lazy(
      () =>
        import("../pages/reports/listReports/verification/verificationReport")
    ),
    exact: true,
  },
  {
    path: "/reports/dashboard",
    Component: lazy(() => import("../pages/reports/category")),
    exact: true,
  },
  {
    path: "/transfers",
    Component: lazy(() => import("../pages/transfers/listTransfers")),
    exact: true,
  },
  {
    path: "/transfers/initiate",
    Component: lazy(() => import("../pages/transfers/initiateTransfers")),
    exact: true,
  },
  {
    path: "/transfers/draft/:id",
    Component: lazy(() => import("../pages/transfers/initiateTransfers")),
    exact: true,
  },
  {
    path: "/transfers/edit/:id",
    Component: lazy(() => import("../pages/transfers/viewTransfers")),
    exact: true,
  },
  {
    path: "/transfers/:id",
    Component: lazy(() => import("../pages/transfers/viewTransfers")),
    exact: true,
  },

  {
    path: "/import",
    Component: lazy(() => import("../pages/import/listImport")),
    exact: true,
  },
  {
    path: "/import/create",
    Component: lazy(() => import("../pages/import/initiateImport")),
    exact: true,
  },
  {
    path: "/import/edit/:id",
    Component: lazy(() => import("../pages/import/initiateImport")),
    exact: true,
  },
  {
    path: "/import/:id",
    Component: lazy(() => import("../pages/import/viewImport")),
    exact: true,
  },

  {
    path: "/personnel",
    Component: lazy(() => import("../pages/personnelManagement/listPersonnel")),
    exact: true,
  },
  {
    path: "/personnel/create",
    Component: lazy(() => import("../pages/personnelManagement/addPersonnel")),
    exact: true,
  },

  {
    path: "/personnel/:id",
    Component: lazy(() => import("../pages/personnelManagement/viewPersonnel")),
    exact: true,
  },
  {
    path: "/personnel/edit/:id",
    Component: lazy(() => import("../pages/personnelManagement/editPersonnel")),
    exact: true,
  },
  {
    path: "/spotCheck",
    Component: lazy(() => import("../pages/spotCheck/listSpotcheck")),
    exact: true,
  },
  {
    path: "/spotCheck/weapon/:id",
    Component: lazy(() => import("../pages/spotCheck/viewSpotcheck/weapon")),
    exact: true,
  },
  {
    path: "/spotCheck/ammunition/:id",
    Component: lazy(
      () => import("../pages/spotCheck/viewSpotcheck/ammunition")
    ),
    exact: true,
  },
  {
    path: "/spotCheck/facility/:id",
    Component: lazy(() => import("../pages/spotCheck/viewSpotcheck/facility")),
    exact: true,
  },
  {
    path: "/units",
    Component: lazy(() => import("../pages/units/listUnit")),
    exact: true,
  },
  {
    path: "/units/create",
    Component: lazy(() => import("../pages/units/createUnit")),
    exact: true,
  },
  {
    path: "/units/:id",
    Component: lazy(() => import("../pages/units/viewUnit")),
    exact: true,
  },
  {
    path: "/units/edit/:id",
    Component: lazy(() => import("../pages/units/createUnit")),
    exact: true,
  },
  {
    path: "/facility",
    Component: lazy(() => import("../pages/facility/listFacility")),
    exact: true,
  },
  {
    path: "/facility/create",
    Component: lazy(() => import("../pages/facility/editFacility")),
    exact: true,
  },
  {
    path: "/facility/:id",
    Component: lazy(() => import("../pages/facility/viewFacility")),
    exact: true,
  },
  {
    path: "/facility/edit/:id",
    Component: lazy(() => import("../pages/facility/editFacility")),
    exact: true,
  },
  {
    path: "/weapons",
    Component: lazy(() => import("../pages/weapons/listWeapon")),
    exact: true,
  },
  {
    path: "/weapons/create",
    Component: lazy(() => import("../pages/weapons/createWeapon")),
    exact: true,
  },
  {
    path: "/weapons/:id",
    Component: lazy(() => import("../pages/weapons/viewWeapon")),
    exact: true,
  },

  {
    path: "/weapons/edit/:id",
    Component: lazy(() => import("../pages/weapons/createWeapon")),
    exact: true,
  },
  {
    path: "/ammunition",
    Component: lazy(() => import("../pages/ammunitions/listAmmunition")),
    exact: true,
  },
  {
    path: "/ammunition/create",
    Component: lazy(() => import("../pages/ammunitions/createAmmunition")),
    exact: true,
  },
  {
    path: "/ammunition/:id",
    Component: lazy(() => import("../pages/ammunitions/viewAmmunition")),
    exact: true,
  },
  {
    path: "/ammunition/edit/:id",
    Component: lazy(() => import("../pages/ammunitions/createAmmunition")),
    exact: true,
  },
  {
    path: "/assign/:id",
    Component: lazy(() => import("../pages/assign-weapon-ammo/listWeaponAmmo")),
    exact: true,
  },
  {
    path: "/reports/weapons/captured",
    Component: lazy(
      () => import("../pages/reports/listReports/weapons/captured")
    ),
    exact: true,
  },
  {
    path: "/reports/weapons/unitWeaponDetail",
    Component: lazy(
      () => import("../pages/reports/listReports/weapons/unitWeaponDetail")
    ),
    exact: true,
  },
  {
    path: "/reports/ammunition/ammunitionShelfLife",
    Component: lazy(
      () => import("../pages/reports/listReports/ammunition/shelfLife")
    ),
    exact: true,
  },
];

const MainRouter = () => {
  const { token } = useSelector((state: any) => state.auth);

  if (!token) {
    return <AuthRoutes />;
  }

  return (
    <div className="app-l-page-wrap">
      <Sidebar />
      <Switch>
        {routes.map((route) => {
          const { Component } = route;
          return (
            <Route {...route} key={route.path}>
              <Suspense
                fallback={
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <CircularProgress sx={{ color: "#DEDBD0" }} />
                  </Box>
                }
              >
                <Component />
              </Suspense>
            </Route>
          );
        })}
        ;
        <Route path="/">
          <Redirect to="/home" />
        </Route>
      </Switch>
    </div>
  );
};
export default MainRouter;
