import { REHYDRATE } from "redux-persist";
import {
  GET_DYNAMIC_IMPORT_LIST,
  RESET_DYNAMIC_IMPORT_LIST,
} from "../ActionTypes";

interface intialState {
  DynamicFilterExportingCountries: any;
  DynamicFilterDeliveryLocation: any;
  DynamicFilterPdnStatus: any;
  DynamicFilterImportListLoading: boolean;
}
const intialState = {
  DynamicFilterExportingCountries: [],
  DynamicFilterDeliveryLocation: [],
  DynamicFilterPdnStatus: [],
  DynamicFilterImportListLoading: true,
};

const DynamicFilterImportListReducer = (state = intialState, action: any) => {
  switch (action.type) {
    case GET_DYNAMIC_IMPORT_LIST:
      return {
        ...state,
        DynamicFilterExportingCountries:
          action.payload.exporting_countries_list,
        DynamicFilterDeliveryLocation: action.payload.delivery_location_list,
        DynamicFilterPdnStatus: action.payload.pdn_submitted_status,
        DynamicFilterImportListLoading: false,
      };
    case RESET_DYNAMIC_IMPORT_LIST:
      return {
        ...state,
        DynamicFilterExportingCountries: [],
        DynamicFilterDeliveryLocation: [],
        DynamicFilterPdnStatus: [],
        DynamicFilterImportListLoading: true,
      };

    case REHYDRATE:
    default:
      return state;
  }
};

export default DynamicFilterImportListReducer;
