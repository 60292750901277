import {
  GET_RECEIVE_TRANSFER_PENDING,
  GET_MORE_RECEIVE_TRANSFER_PENDING,
  GET_RECEIVE_TRANSFER_SUCCESS,
  GET_MORE_RECEIVE_TRANSFER_SUCCESS,
  RESET_RECEIVE_TRANSFER,
  GET_SEND_TRANSFER_PENDING,
  GET_MORE_SEND_TRANSFER_PENDING,
  GET_SEND_TRANSFER_SUCCESS,
  GET_MORE_SEND_TRANSFER_SUCCESS,
  RESET_SEND_TRANSFER,
  GET_TRANSFER_DETAILS,
  RESET_TRANSFER_DETAILS,
} from "../ActionTypes/transfer";
import { REHYDRATE } from "redux-persist";

interface InitialState {
  receiveTransferLoading: boolean;
  moreReceiveTransferLoading: boolean;
  receiveTransferList: any;
  receiveTransferListCount: number;
  receiveTransferPage: number;
  receiveTransferExportQuery: any;
  sendTransferLoading: boolean;
  moreSendTransferLoading: boolean;
  sendTransferList: any;
  sendTransferListCount: number;
  sendTransferPage: number;
  sendTransferExportQuery: any;
  transferInfo: any;
}

const initialState: InitialState = {
  receiveTransferLoading: false,
  moreReceiveTransferLoading: false,
  receiveTransferList: [],
  receiveTransferListCount: 0,
  receiveTransferPage: 1,
  receiveTransferExportQuery: [],
  sendTransferLoading: false,
  moreSendTransferLoading: false,
  sendTransferList: [],
  sendTransferListCount: 0,
  sendTransferPage: 1,
  sendTransferExportQuery: [],
  transferInfo: {},
};

const TransferReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_TRANSFER_DETAILS:
      return {
        ...state,
        transferInfo: action.payload.data,
      };
    case GET_RECEIVE_TRANSFER_PENDING:
      return {
        ...state,
        receiveTransferLoading: true,
      };
    case GET_MORE_RECEIVE_TRANSFER_PENDING:
      return {
        ...state,
        moreReceiveTransferLoading: true,
      };
    case GET_RECEIVE_TRANSFER_SUCCESS:
      return {
        ...state,
        receiveTransferLoading: false,
        receiveTransferList: action.payload.transfers_list,
        receiveTransferListCount: action.payload.data_count,
        receiveTransferExportQuery: action.payload.dynamic_filter_query,
        receiveTransferPage: 2,
      };
    case GET_MORE_RECEIVE_TRANSFER_SUCCESS:
      const newReceiveTransferList = action.payload.transfers_list;
      const { receiveTransferList } = state;
      return {
        ...state,
        moreReceiveTransferLoading: false,
        receiveTransferList: [
          ...receiveTransferList,
          ...newReceiveTransferList,
        ],
        receiveTransferListCount: action.payload.data_count,
        receiveTransferExportQuery: action.payload.dynamic_filter_query,
        receiveTransferPage: state.receiveTransferPage + 1,
      };
    case RESET_RECEIVE_TRANSFER:
      return {
        ...state,
        receiveTransferLoading: false,
        moreReceiveTransferLoading: false,
        receiveTransferList: [],
        receiveTransferListCount: 0,
        receiveTransferPage: 1,
        receiveTransferExportQuery: [],
      };

    case GET_SEND_TRANSFER_PENDING:
      return {
        ...state,
        sendTransferLoading: true,
      };
    case GET_MORE_SEND_TRANSFER_PENDING:
      return {
        ...state,
        moreSendTransferLoading: true,
      };
    case GET_SEND_TRANSFER_SUCCESS:
      return {
        ...state,
        sendTransferLoading: false,
        sendTransferList: action.payload.transfers_list,
        sendTransferListCount: action.payload.data_count,
        sendTransferExportQuery: action.payload.dynamic_filter_query,
        sendTransferPage: 2,
      };
    case GET_MORE_SEND_TRANSFER_SUCCESS:
      const newSendTransferList = action.payload.transfers_list;
      const { sendTransferList } = state;
      return {
        ...state,
        moreSendTransferLoading: false,
        sendTransferList: [...sendTransferList, ...newSendTransferList],
        sendTransferListCount: action.payload.data_count,
        sendTransferExportQuery: action.payload.dynamic_filter_query,
        sendTransferPage: state.sendTransferPage + 1,
      };
    case RESET_SEND_TRANSFER:
      return {
        ...state,
        sendTransferLoading: false,
        moreSendTransferLoading: false,
        sendTransferList: [],
        sendTransferListCount: 0,
        sendTransferPage: 1,
        sendTransferExportQuery: [],
      };
    case RESET_TRANSFER_DETAILS:
      return {
        ...state,
        transferInfo: {},
      };

    case REHYDRATE:
    default:
      return state;
  }
};

export default TransferReducer;
