import {
  GET_AMMUNITION_PENDING,
  GET_MORE_AMMUNITION_PENDING,
  GET_AMMUNITION_SUCCESS,
  GET_MORE_AMMUNITION_SUCCESS,
  RESET_AMMUNITION,
  GET_AMMUNITION_DETAILS,
  RESET_AMMUNITION_DETAILS,
} from "../ActionTypes/ammunition";
import { REHYDRATE } from "redux-persist";

interface InitialState {
  ammunitionLoading: boolean;
  moreAmmunitionLoading: boolean;
  ammunitionList: any;
  ammunitionListCount: number;
  ammunitionPage: number;
  ammunitionExportQuery: any;
  ammunitionDetails: any;
}

const initialState: InitialState = {
  ammunitionLoading: false,
  moreAmmunitionLoading: false,
  ammunitionList: [],
  ammunitionListCount: 0,
  ammunitionPage: 1,
  ammunitionExportQuery: [],
  ammunitionDetails: {},
};

const AmmunitionReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_AMMUNITION_PENDING:
      return {
        ...state,
        ammunitionLoading: true,
      };
    case GET_MORE_AMMUNITION_PENDING:
      return {
        ...state,
        moreAmmunitionLoading: true,
      };
    case GET_AMMUNITION_SUCCESS:
      return {
        ...state,
        ammunitionLoading: false,
        ammunitionList: action.payload.ammunition_details,
        ammunitionListCount: action.payload.count,
        ammunitionExportQuery: action.payload.export_query,
        ammunitionPage: 2,
      };
    case GET_MORE_AMMUNITION_SUCCESS:
      const newAmmunitionList = action.payload.ammunition_details;
      const { ammunitionList } = state;
      return {
        ...state,
        moreAmmunitionLoading: false,
        ammunitionList: [...ammunitionList, ...newAmmunitionList],
        ammunitionListCount: action.payload.count,
        ammunitionExportQuery: action.payload.export_query,
        ammunitionPage: state.ammunitionPage + 1,
      };
    case GET_AMMUNITION_DETAILS:
      return {
        ...state,
        ammunitionDetails: action.payload,
      };
    case RESET_AMMUNITION_DETAILS:
      return {
        ...state,
        ammunitionDetails: {},
      };
    case RESET_AMMUNITION:
      return {
        ...state,
        ammunitionLoading: false,
        moreAmmunitionLoading: false,
        ammunitionList: [],
        ammunitionListCount: 0,
        ammunitionExportQuery: [],
        ammunitionPage: 1,
      };
    case REHYDRATE:
    default:
      return state;
  }
};

export default AmmunitionReducer;
