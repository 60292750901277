import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import React, { Fragment, ReactNode, useCallback } from "react";

/* This is a React functional component called `ConfirmationDialog` that takes in several props defined
by the `ConfirmationDialogProps` interface. The component renders a Material-UI `Dialog` component
with a title, message, and buttons defined by the `dialogBtns` prop. The `handleClose` function is
called when the dialog is closed, and it sets the `open` state to false and performs additional
actions if the `formik` or `importShipment` props are provided. The component is exported as the
default export of the module. */
interface ConfirmationDialogProps {
  message?: any;
  dialogBtns: {
    btnTitle: string;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
  }[];
  title?: string;
  open: boolean;
  subRenderComponent?: ReactNode;
  setOpen?: any;
  formik?: any;
  importShipment?: any;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  message,
  open,
  title,
  dialogBtns,
  subRenderComponent = null,
  setOpen,
  formik = "",
  importShipment = "",
}) => {
  const handleClose = useCallback(() => {
    setOpen(false);
    if (formik) {
      formik.setSubmitting(false);
    }
    if (importShipment) {
      importShipment.current = false;
    }
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle sx={{ fontSize: 20 }}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontSize: 18 }}>{message}</DialogContentText>{" "}
        {subRenderComponent}
      </DialogContent>
      <DialogActions>
        {dialogBtns?.length
          ? dialogBtns.map((btn: any, index: number) => (
              <Fragment key={btn.btnTitle}>
                <Button
                  disabled={index === 1 && btn.disabled}
                  variant="contained"
                  className={`app-c-btn ${
                    dialogBtns?.length === 1
                      ? "app-c-btn--primary"
                      : index === 0
                      ? "app-c-btn--secondary"
                      : "app-c-btn--primary"
                  }`}
                  onClick={btn.onClick}
                >
                  {btn.btnTitle}
                </Button>
              </Fragment>
            ))
          : null}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
