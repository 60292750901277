import { InputAdornment } from "@mui/material";
import { toast } from "react-toastify";
import i18next from "i18next";

export const errIcon = {
  endAdornment: (
    <InputAdornment position="end">
      <svg className="svg-icon icon-info error-icon">
        <use xlinkHref="#iconInfo">
          <title>Info</title>
        </use>
      </svg>
    </InputAdornment>
  ),
};

export const status = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

export const levels = [
  { label: "Level 1", value: 1 },
  { label: "Level 2", value: 2 },
  { label: "Level 3", value: 3 },
  { label: "Level 4", value: 4 },
];

export const datepickerErrIcon = (
  <svg
    className="svg-icon icon-info error-icon"
    style={{ position: "absolute", left: 9, top: 20 }}
  >
    <use xlinkHref="#iconInfo">
      <title>Info</title>
    </use>
  </svg>
);

export const errClass = "left-icon has-error";

export const errorNotify = () =>
  toast.error(
    i18next.language === "en" ? "Something went wrong!.." : "Waxbaa qaldamay!.."
  );

export const loginErrorNotify = () =>
  toast.error(
    i18next.language === "en"
      ? "Please try to login again!.."
      : "Fadlan isku day inaad markale gasho!.."
  );

export const pdfFileErrorNotify = () =>
  toast.error(
    i18next.language === "en"
      ? "Only pdf files are accepted"
      : "PDF Feelalka kaliya ayuu ogolaanayaa"
  );

export const borderStyle = "1px solid #DEDBD0";

export const imgToolBarConfig: any = {
  zoomIn: true,
  zoomOut: true,
  prev: true,
  oneToOne: false,
  reset: false,
  play: {
    show: true,
    size: "large",
  },
  next: true,
  rotateLeft: true,
  rotateRight: true,
  flipHorizontal: false,
  flipVertical: false,
};
