export const GET_WEAPON_TYPES = "GET_WEAPON_TYPES";
export const GET_WEAPON_CALIBRE = "GET_WEAPON_CALIBRE";
export const GET_WEAPON_STATUS = "GET_WEAPON_STATUS";
export const GET_WEAPON_CONDITION = "GET_WEAPON_CONDITION";
export const GET_WEAPON_OWNERSHIP = "GET_WEAPON_OWNERSHIP";
export const GET_WEAPON_NOSERIAL_REASON = "GET_WEAPON_NOSERIAL_REASON";
export const GET_WEAPON_JVT_LIST = "GET_WEAPON_JVT_LIST";
export const GET_MANUFACTURER_LIST = "GET_MANUFACTURER_LIST";
export const GET_MANUFACTURING_COUNTRY_LIST = "GET_MANUFACTURING_COUNTRY_LIST";
export const GET_WEAPON_MSD = "GET_WEAPON_MSD";
export const RESET_WEAPON_MSD = "RESET_WEAPON_MSD";
export const GET_STOCKPILE = "GET_STOCKPILE";
export const GET_TECHNICAL_STATUS = "GET_TECHNICAL_STATUS";
export const GET_HAZARD_DIVISION = "GET_HAZARD_DIVISION";
export const COMPATIBILITY_GROUP = "COMPATIBILITY_GROUP";
export const AMMUNITION_ROLES = "AMMUNITION_ROLES";
export const AMMUNITION_CALIBRE = "AMMUNITION_CALIBRE";
export const AMMUNITION_TYPES = "AMMUNITION_TYPES";
export const AMMUNITION_STATUS = "AMMUNITION_STATUS";
export const CATEGORY_LIST = "CATEGORY_LIST";
export const GET_MODEL_LIST = "GET_MODEL_LIST";
export const RESET_MODEL_LIST = "RESET_MODEL_LIST";
export const GET_CAT1_LIST = "GET_CAT1_LIST";
export const RESET_CAT1_LIST = "RESET_CAT1_LIST";
export const GET_CAT2_LIST = "GET_CAT2_LIST";
export const RESET_CAT2_LIST = "RESET_CAT2_LIST";
export const GET_CAT3_LIST = "GET_CAT3_LIST";
export const RESET_CAT3_LIST = "RESET_CAT3_LIST";
