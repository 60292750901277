import {
  GET_SPOTCHECK_AMMUNITION_PENDING,
  GET_MORE_SPOTCHECK_AMMUNITION_PENDING,
  GET_SPOTCHECK_AMMUNITION_SUCCESS,
  GET_MORE_SPOTCHECK_AMMUNITION_SUCCESS,
  RESET_SPOTCHECK_AMMUNITION,
  GET_SPOTCHECK_WEAPON_PENDING,
  GET_MORE_SPOTCHECK_WEAPON_PENDING,
  GET_SPOTCHECK_WEAPON_SUCCESS,
  GET_MORE_SPOTCHECK_WEAPON_SUCCESS,
  RESET_SPOTCHECK_WEAPON,
  GET_SPOTCHECK_FACILITY_PENDING,
  GET_MORE_SPOTCHECK_FACILITY_PENDING,
  GET_SPOTCHECK_FACILITY_SUCCESS,
  GET_MORE_SPOTCHECK_FACILITY_SUCCESS,
  RESET_SPOTCHECK_FACILITY,
} from "../ActionTypes/spotCheck";
import { REHYDRATE } from "redux-persist";

interface InitialState {
  spotCheckWeaponLoading: boolean;
  moreSpotCheckWeaponLoading: boolean;
  spotCheckWeaponList: any;
  spotCheckWeaponListCount: number;
  spotCheckWeaponPage: number;
  spotCheckWeaponExportQuery: any;
  spotCheckFacilityLoading: boolean;
  moreSpotCheckFacilityLoading: boolean;
  spotCheckFacilityList: any;
  spotCheckFacilityListCount: number;
  spotCheckFacilityPage: number;
  spotCheckFacilityExportQuery: any;
  spotCheckAmmoLoading: boolean;
  moreSpotCheckAmmoLoading: boolean;
  spotCheckAmmoList: any;
  spotCheckAmmoListCount: number;
  spotCheckAmmoPage: number;
  spotCheckAmmoExportQuery: any;
}

const initialState: InitialState = {
  spotCheckWeaponLoading: false,
  moreSpotCheckWeaponLoading: false,
  spotCheckWeaponList: [],
  spotCheckWeaponListCount: 0,
  spotCheckWeaponPage: 1,
  spotCheckWeaponExportQuery: [],
  spotCheckFacilityLoading: false,
  moreSpotCheckFacilityLoading: false,
  spotCheckFacilityList: [],
  spotCheckFacilityListCount: 0,
  spotCheckFacilityPage: 1,
  spotCheckFacilityExportQuery: [],
  spotCheckAmmoLoading: false,
  moreSpotCheckAmmoLoading: false,
  spotCheckAmmoList: [],
  spotCheckAmmoListCount: 0,
  spotCheckAmmoPage: 1,
  spotCheckAmmoExportQuery: [],
};

const SpotCheckReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_SPOTCHECK_AMMUNITION_PENDING:
      return {
        ...state,
        spotCheckAmmoLoading: true,
      };
    case GET_MORE_SPOTCHECK_AMMUNITION_PENDING:
      return {
        ...state,
        moreSpotCheckAmmoLoading: true,
      };
    case GET_SPOTCHECK_AMMUNITION_SUCCESS:
      return {
        ...state,
        spotCheckAmmoLoading: false,
        spotCheckAmmoList: action.payload.spotcheck,
        spotCheckAmmoListCount: action.payload.data_count,
        spotCheckAmmoExportQuery: action.payload.dynamic_filter,
        spotCheckAmmoPage: 2,
      };
    case GET_MORE_SPOTCHECK_AMMUNITION_SUCCESS:
      const newSpotCheckAmmoList = action.payload.spotcheck;
      const { spotCheckAmmoList } = state;
      return {
        ...state,
        moreSpotCheckAmmoLoading: false,
        spotCheckAmmoList: [...spotCheckAmmoList, ...newSpotCheckAmmoList],
        spotCheckAmmoListCount: action.payload.data_count,
        spotCheckAmmoExportQuery: action.payload.dynamic_filter,
        spotCheckAmmoPage: state.spotCheckWeaponPage + 1,
      };
    case RESET_SPOTCHECK_AMMUNITION:
      return {
        ...state,
        spotCheckAmmoLoading: false,
        moreSpotCheckAmmoLoading: false,
        spotCheckAmmoList: [],
        spotCheckAmmoListCount: 0,
        spotCheckAmmoPage: 1,
        spotCheckAmmoExportQuery: [],
      };

    case GET_SPOTCHECK_WEAPON_PENDING:
      return {
        ...state,
        spotCheckWeaponLoading: true,
      };
    case GET_MORE_SPOTCHECK_WEAPON_PENDING:
      return {
        ...state,
        moreSpotCheckWeaponLoading: true,
      };
    case GET_SPOTCHECK_WEAPON_SUCCESS:
      return {
        ...state,
        spotCheckWeaponLoading: false,
        spotCheckWeaponList: action.payload.spotcheck,
        spotCheckWeaponListCount: action.payload.data_count,
        spotCheckWeaponExportQuery: action.payload.dynamic_filter,
        spotCheckWeaponPage: 2,
      };
    case GET_MORE_SPOTCHECK_WEAPON_SUCCESS:
      const newSpotCheckWeaponList = action.payload.spotcheck;
      const { spotCheckWeaponList } = state;
      return {
        ...state,
        moreSpotCheckWeaponLoading: false,
        spotCheckWeaponList: [
          ...spotCheckWeaponList,
          ...newSpotCheckWeaponList,
        ],
        spotCheckWeaponListCount: action.payload.data_count,
        spotCheckWeaponExportQuery: action.payload.dynamic_filter,
        spotCheckWeaponPage: state.spotCheckWeaponPage + 1,
      };
    case RESET_SPOTCHECK_WEAPON:
      return {
        ...state,
        spotCheckWeaponLoading: false,
        moreSpotCheckWeaponLoading: false,
        spotCheckWeaponList: [],
        spotCheckWeaponListCount: 0,
        spotCheckWeaponPage: 1,
        spotCheckWeaponExportQuery: [],
      };

    case GET_SPOTCHECK_FACILITY_PENDING:
      return {
        ...state,
        spotCheckFacilityLoading: true,
      };
    case GET_MORE_SPOTCHECK_FACILITY_PENDING:
      return {
        ...state,
        moreSpotCheckFacilityLoading: true,
      };
    case GET_SPOTCHECK_FACILITY_SUCCESS:
      return {
        ...state,
        spotCheckFacilityLoading: false,
        spotCheckFacilityList: action.payload.spotcheck,
        spotCheckFacilityListCount: action.payload.data_count,
        spotCheckFacilityExportQuery: action.payload.dynamic_filter,
        spotCheckFacilityPage: 2,
      };
    case GET_MORE_SPOTCHECK_FACILITY_SUCCESS:
      const newSpotCheckFacilityList = action.payload.spotcheck;
      const { spotCheckFacilityList } = state;
      return {
        ...state,
        moreSpotCheckFacilityLoading: false,
        spotCheckFacilityList: [
          ...spotCheckFacilityList,
          ...newSpotCheckFacilityList,
        ],
        spotCheckFacilityListCount: action.payload.data_count,
        spotCheckFacilityExportQuery: action.payload.dynamic_filter,
        spotCheckFacilityPage: state.spotCheckFacilityPage + 1,
      };
    case RESET_SPOTCHECK_FACILITY:
      return {
        ...state,
        spotCheckFacilityLoading: false,
        moreSpotCheckFacilityLoading: false,
        spotCheckFacilityList: [],
        spotCheckFacilityListCount: 0,
        spotCheckFacilityPage: 1,
        spotCheckFacilityExportQuery: [],
      };

    case REHYDRATE:
    default:
      return state;
  }
};

export default SpotCheckReducer;
