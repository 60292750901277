import { REHYDRATE } from "redux-persist";
import { GET_DYNAMIC_SPOTCHECK, RESET_DYNAMIC_SPOTCHECK } from "../ActionTypes";

interface InitialState {
  DynamicFilterSptFacility: any;
  DynamicFilterSptForce: any;
  DynamicFilterSptDivisions: any;
  DynamicFilterSptUnits: any;
  DynamicFilterSptWeaponTypes: any;
  DynamicFilterSptAmmunitionCalibre: any;
  DynamicFilterSptOutcome: any;
  DynamicFilterSptSF: any;
  DynamicSptListLoading: boolean;
}
const initialState: InitialState = {
  DynamicFilterSptFacility: [],
  DynamicFilterSptForce: [],
  DynamicFilterSptDivisions: [],
  DynamicFilterSptUnits: [],
  DynamicFilterSptWeaponTypes: [],
  DynamicFilterSptAmmunitionCalibre: [],
  DynamicFilterSptOutcome: [],
  DynamicFilterSptSF: [],
  DynamicSptListLoading: true,
};

const DynamicFilterSpotCheckReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_DYNAMIC_SPOTCHECK:
      return {
        ...state,
        DynamicFilterSptFacility: action.payload?.facility,
        DynamicFilterSptForce: action.payload?.force,
        DynamicFilterSptDivisions: action.payload?.divisions,
        DynamicFilterSptUnits: action.payload?.units,
        DynamicFilterSptWeaponTypes: action.payload?.weapon_types,
        DynamicFilterSptAmmunitionCalibre: action.payload?.ammunition_calibre,
        DynamicFilterSptOutcome: action.payload?.outcome,
        DynamicFilterSptSF: action.payload?.security_forces,
        DynamicSptListLoading: false,
      };
    case RESET_DYNAMIC_SPOTCHECK:
      return {
        ...state,
        DynamicFilterSptFacility: [],
        DynamicFilterSptForce: [],
        DynamicFilterSptDivisions: [],
        DynamicFilterSptUnits: [],
        DynamicFilterSptWeaponTypes: [],
        DynamicFilterSptAmmunitionCalibre: [],
        DynamicFilterSptOutcome: [],
        DynamicFilterSptSF: [],
        DynamicSptListLoading: true,
      };

    case REHYDRATE:
    default:
      return state;
  }
};

export default DynamicFilterSpotCheckReducer;
