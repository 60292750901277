export const GET_REPORTS_IMPORT_COUNTRY_WEAPON =
  "GET_REPORTS_IMPORT_COUNTRY_WEAPON";

export const RESET_REPORTS_IMPORT_COUNTRY_WEAPON =
  "RESET_REPORTS_IMPORT_COUNTRY_WEAPON";

export const GET_REPORTS_IMPORT_COUNTRY_AMMO =
  "GET_REPORTS_IMPORT_COUNTRY_AMMO";

export const RESET_REPORTS_IMPORT_COUNTRY_AMMO =
  "RESET_REPORTS_IMPORT_COUNTRY_AMMO";

export const GET_REPORTS_UNIT_WEAPON = "GET_REPORTS_UNIT_WEAPON";

export const RESET_REPORTS_UNIT_WEAPON = "RESET_REPORTS_UNIT_WEAPON";

export const GET_REPORTS_UNIT_WEAPON_HOLDINGS =
  "GET_REPORTS_UNIT_WEAPON_HOLDINGS";

export const RESET_REPORTS_UNIT_WEAPON_HOLDINGS =
  "RESET_REPORTS_UNIT_WEAPON_HOLDINGS";

export const GET_REPORTS_WEAPON_HOLDINGS_OVER_TIME =
  "GET_REPORTS_WEAPON_HOLDINGS_OVER_TIME";

export const RESET_REPORTS_WEAPON_HOLDINGS_OVER_TIME =
  "RESET_REPORTS_WEAPON_HOLDINGS_OVER_TIME";

export const GET_REPORTS_AMMUNITION_HOLDINGS_OVER_TIME =
  "GET_REPORTS_AMMUNITION_HOLDINGS_OVER_TIME";

export const RESET_REPORTS_AMMUNITION_HOLDINGS_OVER_TIME =
  "RESET_REPORTS_AMMUNITION_HOLDINGS_OVER_TIME";

export const GET_REPORTS_SMALL_ARMS_BY_UNIT = "GET_REPORTS_SMALL_ARMS_BY_UNIT";

export const RESET_REPORTS_SMALL_ARMS_BY_UNIT =
  "RESET_REPORTS_SMALL_ARMS_BY_UNIT";

export const GET_REPORTS_UNIT_AMMUNITION = "GET_REPORTS_UNIT_AMMUNITION";

export const RESET_REPORTS_UNIT_AMMUNITION = "RESET_REPORTS_UNIT_AMMUNITION";

export const GET_REPORTS_LOST_AMMUNITION = "GET_REPORTS_LOST_AMMUNITION";

export const RESET_REPORTS_LOST_AMMUNITION = "RESET_REPORTS_LOST_AMMUNITION";

export const GET_REPORTS_LOST_WEAPON = "GET_REPORTS_LOST_WEAPON";

export const RESET_REPORTS_LOST_WEAPON = "RESET_REPORTS_LOST_WEAPON";

export const GET_REPORTS_MAINTENANCE_WEAPON = "GET_REPORTS_MAINTENANCE_WEAPON";

export const RESET_REPORTS_MAINTENANCE_WEAPON =
  "RESET_REPORTS_MAINTENANCE_WEAPON";

export const GET_REPORTS_AMMUNITION_SHELF_LIFE =
  "GET_REPORTS_AMMUNITION_SHELF_LIFE";

export const RESET_REPORTS_AMMUNITION_SHELF_LIFE =
  "RESET_REPORTS_AMMUNITION_SHELF_LIFE";

export const GET_REPORTS_CAPTURED_AMMUNITION_PENDING =
  "GET_REPORTS_CAPTURED_AMMUNITION_PENDING";
export const GET_MORE_REPORTS_CAPTURED_AMMUNITION_PENDING =
  "GET_MORE_REPORTS_CAPTURED_AMMUNITION_PENDING";
export const GET_REPORTS_CAPTURED_AMMUNITION_SUCCESS =
  "GET_REPORTS_CAPTURED_AMMUNITION_SUCCESS";
export const GET_MORE_REPORTS_CAPTURED_AMMUNITION_SUCCESS =
  "GET_MORE_REPORTS_CAPTURED_AMMUNITION_SUCCESS";
export const RESET_REPORTS_CAPTURED_AMMUNITION =
  "RESET_REPORTS_CAPTURED_AMMUNITION";

export const GET_REPORTS_CAPTURED_WEAPON_PENDING =
  "GET_REPORTS_CAPTURED_WEAPON_PENDING";
export const GET_MORE_REPORTS_CAPTURED_WEAPON_PENDING =
  "GET_MORE_REPORTS_CAPTURED_WEAPON_PENDING";
export const GET_REPORTS_CAPTURED_WEAPON_SUCCESS =
  "GET_REPORTS_CAPTURED_WEAPON_SUCCESS";
export const GET_MORE_REPORTS_CAPTURED_WEAPON_SUCCESS =
  "GET_MORE_REPORTS_CAPTURED_WEAPON_SUCCESS";
export const RESET_REPORTS_CAPTURED_WEAPON = "RESET_REPORTS_CAPTURED_WEAPON";

export const GET_REPORTS_UNIT_DETAIL_WEAPON_PENDING =
  "GET_REPORTS_UNIT_DETAIL_WEAPON_PENDING";
export const GET_MORE_REPORTS_UNIT_DETAIL_WEAPON_PENDING =
  "GET_MORE_REPORTS_UNIT_DETAIL_WEAPON_PENDING";
export const GET_REPORTS_UNIT_DETAIL_WEAPON_SUCCESS =
  "GET_REPORTS_UNIT_DETAIL_WEAPON_SUCCESS";
export const GET_MORE_REPORTS_UNIT_DETAIL_WEAPON_SUCCESS =
  "GET_MORE_REPORTS_UNIT_DETAIL_WEAPON_SUCCESS";
export const RESET_REPORTS_UNIT_DETAIL_WEAPON =
  "RESET_REPORTS_UNIT_DETAIL_WEAPON";

export const GET_REPORTS_MONTHLY_SPOTCHECK = "GET_REPORTS_MONTHLY_SPOTCHECK";

export const RESET_REPORTS_MONTHLY_SPOTCHECK =
  "RESET_REPORTS_MONTHLY_SPOTCHECK";

export const GET_REPORTS_SPOTCHECK_IMPORT = "GET_REPORTS_SPOTCHECK_IMPORT";

export const RESET_REPORTS_SPOTCHECK_IMPORT = "RESET_REPORTS_SPOTCHECK_IMPORT";

export const GET_REPORTS_VERIFIED_IMPORT = "GET_REPORTS_VERIFIED_IMPORT";

export const RESET_REPORTS_VERIFIED_IMPORT = "RESET_REPORTS_VERIFIED_IMPORT";

export const GET_REPORT_IMPORT_VERIFICATION = "GET_REPORT_IMPORT_VERIFICATION";

export const RESET_REPORT_IMPORT_VERIFICATION =
  "RESET_REPORTS_SPOTCHECK_IMPORT";

export const GET_IMPORT_PERCENTAGE_REPORT = "GET_IMPORT_PERCENTAGE_REPORT";

export const RESET_IMPORT_PERCENTAGE_REPORT = "RESET_IMPORT_PERCENTAGE_REPORT";
