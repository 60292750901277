import store from "../../store";
import {
  SET_APP_LANGUAGE,
  GET_USER_STATUS,
  RESET_USER_STATUS,
} from "../ActionTypes";
import request from "../../services/HttpMethods";
import { get } from "lodash";

const { dispatch }: any = store || {};

export const setAppLanguge = (language: string) => {
  dispatch({
    type: SET_APP_LANGUAGE,
    language,
  });
};

export const getUserStatus = async (id: any) => {
  try {
    const response = await request.get(`/api/user_status_init?user_id=${id}`);
    if (response.status === 200) {
      const status = get(response, "data.status");
      dispatch({
        type: GET_USER_STATUS,
        payload: status,
      });
    }
  } catch (error: any) {
    // console.log(error) --error handled elsewhere
  }
};

export const resetUserStatus = async () => {
  dispatch({
    type: RESET_USER_STATUS,
  });
};
