import { REHYDRATE } from "redux-persist";
import {
  GET_REPORTS_IMPORT_COUNTRY_WEAPON,
  RESET_REPORTS_IMPORT_COUNTRY_WEAPON,
  GET_REPORTS_IMPORT_COUNTRY_AMMO,
  RESET_REPORTS_IMPORT_COUNTRY_AMMO,
  GET_REPORTS_UNIT_WEAPON,
  RESET_REPORTS_UNIT_WEAPON,
  GET_REPORTS_UNIT_WEAPON_HOLDINGS,
  RESET_REPORTS_UNIT_WEAPON_HOLDINGS,
  GET_REPORTS_SMALL_ARMS_BY_UNIT,
  RESET_REPORTS_SMALL_ARMS_BY_UNIT,
  GET_REPORTS_UNIT_AMMUNITION,
  RESET_REPORTS_UNIT_AMMUNITION,
  GET_REPORTS_LOST_AMMUNITION,
  RESET_REPORTS_LOST_AMMUNITION,
  GET_REPORTS_LOST_WEAPON,
  RESET_REPORTS_LOST_WEAPON,
  GET_REPORTS_MAINTENANCE_WEAPON,
  RESET_REPORTS_MAINTENANCE_WEAPON,
  GET_REPORTS_SPOTCHECK_IMPORT,
  RESET_REPORTS_SPOTCHECK_IMPORT,
  GET_REPORTS_VERIFIED_IMPORT,
  RESET_REPORTS_VERIFIED_IMPORT,
  GET_REPORTS_AMMUNITION_SHELF_LIFE,
  RESET_REPORTS_AMMUNITION_SHELF_LIFE,
  GET_REPORTS_WEAPON_HOLDINGS_OVER_TIME,
  GET_REPORTS_AMMUNITION_HOLDINGS_OVER_TIME,
  RESET_REPORTS_WEAPON_HOLDINGS_OVER_TIME,
  RESET_REPORTS_AMMUNITION_HOLDINGS_OVER_TIME,
  GET_REPORTS_MONTHLY_SPOTCHECK,
  RESET_REPORTS_MONTHLY_SPOTCHECK,
  GET_REPORTS_CAPTURED_AMMUNITION_PENDING,
  GET_MORE_REPORTS_CAPTURED_AMMUNITION_PENDING,
  GET_REPORTS_CAPTURED_AMMUNITION_SUCCESS,
  GET_MORE_REPORTS_CAPTURED_AMMUNITION_SUCCESS,
  RESET_REPORTS_CAPTURED_AMMUNITION,
  GET_REPORTS_UNIT_DETAIL_WEAPON_PENDING,
  GET_MORE_REPORTS_UNIT_DETAIL_WEAPON_PENDING,
  GET_REPORTS_UNIT_DETAIL_WEAPON_SUCCESS,
  GET_MORE_REPORTS_UNIT_DETAIL_WEAPON_SUCCESS,
  RESET_REPORTS_UNIT_DETAIL_WEAPON,
  GET_REPORTS_CAPTURED_WEAPON_PENDING,
  GET_MORE_REPORTS_CAPTURED_WEAPON_PENDING,
  GET_REPORTS_CAPTURED_WEAPON_SUCCESS,
  GET_MORE_REPORTS_CAPTURED_WEAPON_SUCCESS,
  RESET_REPORTS_CAPTURED_WEAPON,
  GET_REPORT_IMPORT_VERIFICATION,
  RESET_REPORT_IMPORT_VERIFICATION,
  GET_IMPORT_PERCENTAGE_REPORT,
  RESET_IMPORT_PERCENTAGE_REPORT,
} from "../ActionTypes/reports";

interface InitialState {
  importByCountryWeaponLoading: boolean;
  importByCountryAmmoLoading: boolean;
  importByCountryWeapon: any;
  importByCountryAmmo: any;
  unitWeaponReport: any;
  unitWeaponReportLoading: boolean;
  unitWeaponHoldings: any;
  unitWeaponHoldingsLoading: boolean;
  smallArmsByUnit: any;
  smallArmsByUnitLoading: boolean;
  unitAmmunition: any;
  unitAmmunitionLoading: boolean;
  lostAmmunition: any;
  lostAmmunitionLoading: boolean;
  lostWeapon: any;
  lostWeaponLoading: boolean;
  maintenanceWeapon: any;
  maintenanceWeaponLoading: boolean;
  spotcheckByImport: any;
  spotcheckByImportLoading: boolean;
  verifiedImport: any;
  verifiedImportLoading: boolean;
  ammunitionShelfLife: any;
  ammunitionShelfLifeLoading: boolean;
  weaponHoldingsOverTime: any;
  weaponHoldingsOverTimeLoading: boolean;
  ammunitionHoldingsOverTime: any;
  ammunitionHoldingsOverTimeLoading: boolean;
  capturedWeaponLoading: boolean;
  moreCapturedWeaponLoading: boolean;
  capturedWeaponList: any;
  capturedWeaponListCount: number;
  capturedWeaponPage: number;
  capturedWeaponExportQuery: any;
  capturedAmmunitionLoading: boolean;
  moreCapturedAmmunitionLoading: boolean;
  capturedAmmunitionList: any;
  capturedAmmunitionListCount: number;
  capturedAmmunitionPage: number;
  capturedAmmunitionExportQuery: any;
  unitWeaponDetailLoading: boolean;
  moreUnitWeaponDetailLoading: boolean;
  unitWeaponDetailList: any;
  unitWeaponDetailListCount: number;
  unitWeaponDetailPage: number;
  unitWeaponDetailExportQuery: any;
  monthlySpotchecks: any;
  monthlYSpotchecksLoading: boolean;
  verificationReport: any;
  verificationReportLoading: boolean;
  percentageReport: any;
  percentageReportLoading: boolean;
}
const initialState: InitialState = {
  importByCountryWeaponLoading: true,
  importByCountryAmmoLoading: true,
  importByCountryWeapon: [],
  importByCountryAmmo: [],
  unitWeaponReport: [],
  unitWeaponReportLoading: true,
  unitWeaponHoldings: [],
  unitWeaponHoldingsLoading: true,
  smallArmsByUnit: [],
  smallArmsByUnitLoading: true,
  unitAmmunition: [],
  unitAmmunitionLoading: true,
  lostAmmunition: [],
  lostAmmunitionLoading: true,
  lostWeapon: [],
  lostWeaponLoading: true,
  maintenanceWeapon: [],
  maintenanceWeaponLoading: true,
  spotcheckByImport: [],
  spotcheckByImportLoading: true,
  verifiedImport: [],
  verifiedImportLoading: true,
  ammunitionShelfLife: [],
  ammunitionShelfLifeLoading: true,
  weaponHoldingsOverTime: [],
  weaponHoldingsOverTimeLoading: true,
  ammunitionHoldingsOverTime: [],
  ammunitionHoldingsOverTimeLoading: true,
  capturedWeaponLoading: false,
  moreCapturedWeaponLoading: false,
  capturedWeaponList: [],
  capturedWeaponListCount: 0,
  capturedWeaponPage: 1,
  capturedWeaponExportQuery: [],
  capturedAmmunitionLoading: false,
  moreCapturedAmmunitionLoading: false,
  capturedAmmunitionList: [],
  capturedAmmunitionListCount: 0,
  capturedAmmunitionPage: 1,
  capturedAmmunitionExportQuery: [],
  unitWeaponDetailLoading: false,
  moreUnitWeaponDetailLoading: false,
  unitWeaponDetailList: [],
  unitWeaponDetailListCount: 0,
  unitWeaponDetailPage: 1,
  unitWeaponDetailExportQuery: [],
  monthlySpotchecks: [],
  monthlYSpotchecksLoading: true,
  verificationReport: [],
  verificationReportLoading: true,
  percentageReport: [],
  percentageReportLoading: true,
};

const ReportsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_REPORTS_IMPORT_COUNTRY_WEAPON:
      return {
        ...state,
        importByCountryWeapon: action.payload?.filter(
          (item: any) => Object.keys(item?.ammunition).length === 0
        ),
        importByCountryWeaponLoading: false,
      };

    case RESET_REPORTS_IMPORT_COUNTRY_WEAPON:
      return {
        ...state,
        importByCountryWeapon: [],
        importByCountryWeaponLoading: true,
      };

    case GET_REPORTS_UNIT_DETAIL_WEAPON_PENDING:
      return {
        ...state,
        unitWeaponDetailLoading: true,
      };
    case GET_MORE_REPORTS_UNIT_DETAIL_WEAPON_PENDING:
      return {
        ...state,
        moreUnitWeaponDetailLoading: true,
      };
    case GET_REPORTS_UNIT_DETAIL_WEAPON_SUCCESS:
      return {
        ...state,
        unitWeaponDetailLoading: false,
        unitWeaponDetailList: action.payload.weapons_details,
        unitWeaponDetailListCount: action.payload.data_count,
        unitWeaponDetailExportQuery: action.payload.export_query,
        unitWeaponDetailPage: 2,
      };
    case GET_MORE_REPORTS_UNIT_DETAIL_WEAPON_SUCCESS:
      const newUnitWeaponDetailList = action.payload.weapons_details;
      const { unitWeaponDetailList } = state;
      return {
        ...state,
        moreUnitWeaponDetailLoading: false,
        unitWeaponDetailList: [
          ...unitWeaponDetailList,
          ...newUnitWeaponDetailList,
        ],
        unitWeaponDetailListCount: action.payload.data_count,
        unitWeaponDetailExportQuery: action.payload.dynamic_filter_query,
        unitWeaponDetailPage: state.unitWeaponDetailPage + 1,
      };
    case RESET_REPORTS_UNIT_DETAIL_WEAPON:
      return {
        ...state,
        unitWeaponDetailLoading: false,
        moreUnitWeaponDetailLoading: false,
        unitWeaponDetailList: [],
        unitWeaponDetailListCount: 0,
        unitWeaponDetailExportQuery: [],
        unitWeaponDetailPage: 1,
      };

    case GET_REPORTS_CAPTURED_WEAPON_PENDING:
      return {
        ...state,
        capturedWeaponLoading: true,
      };
    case GET_MORE_REPORTS_CAPTURED_WEAPON_PENDING:
      return {
        ...state,
        moreCapturedWeaponLoading: true,
      };
    case GET_REPORTS_CAPTURED_WEAPON_SUCCESS:
      return {
        ...state,
        capturedWeaponLoading: false,
        capturedWeaponList: action.payload.weapons_details,
        capturedWeaponListCount: action.payload.total_count,
        capturedWeaponExportQuery: action.payload.export_query,
        capturedWeaponPage: 2,
      };
    case GET_MORE_REPORTS_CAPTURED_WEAPON_SUCCESS:
      const newCapturedWeaponList = action.payload.weapons_details;
      const { capturedWeaponList } = state;
      return {
        ...state,
        moreCapturedWeaponLoading: false,
        capturedWeaponList: [...capturedWeaponList, ...newCapturedWeaponList],
        capturedWeaponListCount: action.payload.total_count,
        capturedWeaponExportQuery: action.payload.dynamic_filter_query,
        capturedWeaponPage: state.capturedWeaponPage + 1,
      };
    case RESET_REPORTS_CAPTURED_WEAPON:
      return {
        ...state,
        capturedWeaponLoading: false,
        moreCapturedWeaponLoading: false,
        capturedWeaponList: [],
        capturedWeaponListCount: 0,
        capturedWeaponExportQuery: [],
        capturedWeaponPage: 1,
      };

    case GET_REPORTS_CAPTURED_AMMUNITION_PENDING:
      return {
        ...state,
        capturedAmmunitionLoading: true,
      };
    case GET_MORE_REPORTS_CAPTURED_AMMUNITION_PENDING:
      return {
        ...state,
        moreCapturedAmmunitionLoading: true,
      };
    case GET_REPORTS_CAPTURED_AMMUNITION_SUCCESS:
      return {
        ...state,
        capturedAmmunitionLoading: false,
        capturedAmmunitionList: action.payload.captured_ammunition_details,
        capturedAmmunitionListCount: action.payload.total_count,
        capturedAmmunitionExportQuery: action.payload.export_query,
        capturedAmmunitionPage: 2,
      };
    case GET_MORE_REPORTS_CAPTURED_AMMUNITION_SUCCESS:
      const newCapturedAmmunitionList =
        action.payload.captured_ammunition_details;
      const { capturedAmmunitionList } = state;
      return {
        ...state,
        moreCapturedAmmunitionLoading: false,
        capturedAmmunitionList: [
          ...capturedAmmunitionList,
          ...newCapturedAmmunitionList,
        ],
        capturedAmmunitionListCount: action.payload.total_count,
        capturedAmmunitionExportQuery: action.payload.dynamic_filter_query,
        capturedAmmunitionPage: state.capturedAmmunitionPage + 1,
      };
    case RESET_REPORTS_CAPTURED_AMMUNITION:
      return {
        ...state,
        capturedAmmunitionLoading: false,
        morePersonnelLoading: false,
        capturedAmmunitionList: [],
        capturedAmmunitionListCount: 0,
        capturedAmmunitionExportQuery: [],
        capturedAmmunitionPage: 1,
      };

    case GET_REPORTS_WEAPON_HOLDINGS_OVER_TIME:
      return {
        ...state,
        weaponHoldingsOverTime: action.payload,
        weaponHoldingsOverTimeLoading: false,
      };

    case RESET_REPORTS_WEAPON_HOLDINGS_OVER_TIME:
      return {
        ...state,
        weaponHoldingsOverTime: [],
        weaponHoldingsOverTimeLoading: true,
      };

    case GET_REPORTS_AMMUNITION_HOLDINGS_OVER_TIME:
      return {
        ...state,
        ammunitionHoldingsOverTime: action.payload,
        ammunitionHoldingsOverTimeLoading: false,
      };

    case RESET_REPORTS_AMMUNITION_HOLDINGS_OVER_TIME:
      return {
        ...state,
        ammunitionHoldingsOverTime: [],
        ammunitionHoldingsOverTimeLoading: true,
      };

    case GET_REPORTS_IMPORT_COUNTRY_AMMO:
      return {
        ...state,
        importByCountryAmmo: action.payload?.filter(
          (item: any) => Object.keys(item?.weapon).length === 0
        ),
        importByCountryAmmoLoading: false,
      };

    case RESET_REPORTS_IMPORT_COUNTRY_AMMO:
      return {
        ...state,
        importByCountryAmmo: [],
        importByCountryAmmoLoading: true,
      };

    case RESET_REPORTS_UNIT_WEAPON:
      return {
        ...state,
        unitWeaponReport: [],
        unitWeaponReportLoading: true,
      };

    case GET_REPORTS_UNIT_WEAPON:
      return {
        ...state,
        unitWeaponReport: action.payload,
        unitWeaponReportLoading: false,
      };

    case RESET_REPORTS_MONTHLY_SPOTCHECK:
      return {
        ...state,
        monthlySpotchecks: [],
        monthlySpotchecksLoading: true,
      };

    case GET_REPORTS_MONTHLY_SPOTCHECK:
      return {
        ...state,
        monthlySpotchecks: action.payload,
        monthlySpotchecksLoading: false,
      };

    case RESET_REPORTS_UNIT_WEAPON_HOLDINGS:
      return {
        ...state,
        unitWeaponHoldings: [],
        unitWeaponHoldingsLoading: true,
      };

    case GET_REPORTS_UNIT_WEAPON_HOLDINGS:
      return {
        ...state,
        unitWeaponHoldings: action.payload,
        unitWeaponHoldingsLoading: false,
      };

    case RESET_REPORTS_SMALL_ARMS_BY_UNIT:
      return {
        ...state,
        smallArmsByUnit: [],
        smallArmsByUnitLoading: true,
      };

    case GET_REPORTS_SMALL_ARMS_BY_UNIT:
      return {
        ...state,
        smallArmsByUnit: action.payload,
        smallArmsByUnitLoading: false,
      };

    case RESET_REPORTS_UNIT_AMMUNITION:
      return {
        ...state,
        unitAmmunition: [],
        unitAmmunitionLoading: true,
      };

    case GET_REPORTS_UNIT_AMMUNITION:
      return {
        ...state,
        unitAmmunition: action.payload,
        unitAmmunitionLoading: false,
      };

    case RESET_REPORTS_AMMUNITION_SHELF_LIFE:
      return {
        ...state,
        ammunitionShelfLife: [],
        ammunitionShelfLifeLoading: true,
      };

    case GET_REPORTS_AMMUNITION_SHELF_LIFE:
      return {
        ...state,
        ammunitionShelfLife: action.payload,
        ammunitionShelfLifeLoading: false,
      };

    case RESET_REPORTS_LOST_AMMUNITION:
      return {
        ...state,
        lostAmmunition: [],
        lostAmmunitionLoading: true,
      };

    case GET_REPORTS_LOST_AMMUNITION:
      return {
        ...state,
        lostAmmunition: action.payload,
        lostAmmunitionLoading: false,
      };

    case RESET_REPORTS_LOST_WEAPON:
      return {
        ...state,
        lostWeapon: [],
        lostWeaponLoading: true,
      };

    case GET_REPORTS_LOST_WEAPON:
      return {
        ...state,
        lostWeapon: action.payload,
        lostWeaponLoading: false,
      };

    case RESET_REPORTS_MAINTENANCE_WEAPON:
      return {
        ...state,
        maintenanceWeapon: [],
        maintenanceWeaponLoading: true,
      };

    case GET_REPORTS_MAINTENANCE_WEAPON:
      return {
        ...state,
        maintenanceWeapon: action.payload,
        maintenanceWeaponLoading: false,
      };

    case RESET_REPORTS_SPOTCHECK_IMPORT:
      return {
        ...state,
        spotcheckByImport: [],
        spotcheckByImportLoading: true,
      };

    case GET_REPORTS_SPOTCHECK_IMPORT:
      return {
        ...state,
        spotcheckByImport: action.payload,
        spotcheckByImportLoading: false,
      };

    case RESET_REPORTS_VERIFIED_IMPORT:
      return {
        ...state,
        verifiedImport: [],
        verifiedImportLoading: true,
      };

    case GET_REPORTS_VERIFIED_IMPORT:
      return {
        ...state,
        verifiedImport: action.payload,
        verifiedImportLoading: false,
      };

    case RESET_REPORT_IMPORT_VERIFICATION:
      return {
        ...state,
        verificationReport: [],
        verificationReportLoading: true,
      };

    case GET_REPORT_IMPORT_VERIFICATION:
      return {
        ...state,
        verificationReport: action.payload,
        verificationReportLoading: false,
      };

    case RESET_IMPORT_PERCENTAGE_REPORT:
      return {
        ...state,
        percentageReport: [],
        percentageReportLoading: true,
      };

    case GET_IMPORT_PERCENTAGE_REPORT:
      return {
        ...state,
        percentageReport: action.payload,
        percentageReportLoading: false,
      };

    case REHYDRATE:

    default:
      return state;
  }
};

export default ReportsReducer;
