interface ApiEndpoint {
  [key: string]: string;
}
interface NodeType {
  [key: string]: ApiEndpoint;
}

const currentEnv: string = "uat";

const envConfig: NodeType = {
  development: {
    API_URL: "https://api.dev.ims.experionglobal.dev",
  },
  qa: {
    API_URL: "https://api.qa.ims.experionglobal.dev",
  },
  uat: {
    API_URL: "https://api.ims-uat.wamdb.com",
  },
};

export default envConfig[currentEnv];
