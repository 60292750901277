import { REHYDRATE } from "redux-persist";
import {
  GET_DIVISIONS,
  RESET_DIVISIONS,
  GET_RANKS,
  RESET_RANKS,
  GET_ROLES,
  RESET_ROLES,
  GET_SECURITY_FORCES,
  RESET_SECURITY_FORCES,
  GET_UNITS,
  RESET_DIVISION_UNITS_RANK,
  RESET_DIVISION_UNITS,
  RESET_UNITS,
  GET_IMPORTID_LIST,
  GET_TRANSFER_STATUS,
  GET_SHIPMENTID_LIST,
  GET_JVT_SHIPMENTID_LIST,
  RESET_JVT_SHIPMENT_LIST,
  RESET_SHIPMENT_LIST,
  GET_SF_DIV_UNIT_MAPPER,
  RESET_SF_DIV_UNIT_MAPPER,
  GET_CAPTURED_FROM_LIST,
  RESET_CAPTURED_FROM_LIST,
  GET_DISTRICT_DROPDOWN,
  RESET_DISTRICT_DROPDOWN,
  GET_USER_DROPDOWN,
  RESET_USER_DROPDOWN,
  GET_FACILITY_TYPE,
  GET_FACILITY_STATUS,
  GET_CONSTRUCTION_TYPE,
  GET_FACILITY_DROPDOWN,
  RESET_FACILITY_DROPDOWN,
  GET_OFFICER_IN_CHARGE,
  RESET_OFFICER_IN_CHARGE,
  GET_OWNERSHIP_COMPOUND,
} from "../ActionTypes/dropDown";

interface InitialState {
  securityForces: any;
  securityForcesLoading: boolean;
  Roles: any;
  rolesLoading: boolean;
  Ranks: any;
  ranksLoading: boolean;
  Divisions: any;
  divisionsLoading: boolean;
  UnitsLoading: boolean;
  Units: any;
  transferStatus: any;
  importIdList: any;
  importIdCount: number;
  shipmentList: any;
  jvtShipmentList: any;
  totalImportCount: number;
  totalShipmentCount: number;
  totalJvtShipmentCount: number;
  DivMapper: any;
  capturedFrom: any;
  districtList: any;
  userList: any;
  facilityTypes: any;
  facilityStatus: any;
  facilityConstructionType: any;
  facilityDropdown: any;
  facilityDropdownLoading: boolean;
  officerInCharge: any;
  officerInChargeLoading: boolean;
  ownershipCompound: any;
}
const initialState: InitialState = {
  securityForces: [],
  securityForcesLoading: true,
  Roles: [],
  rolesLoading: true,
  Ranks: [],
  ranksLoading: true,
  Divisions: [],
  divisionsLoading: true,
  UnitsLoading: true,
  Units: [],
  transferStatus: [],
  importIdList: [],
  importIdCount: 0,
  shipmentList: [],
  jvtShipmentList: [],
  totalImportCount: 0,
  totalShipmentCount: 0,
  totalJvtShipmentCount: 0,
  DivMapper: [],
  capturedFrom: [],
  districtList: [],
  userList: [],
  facilityTypes: [],
  facilityStatus: [],
  facilityConstructionType: [],
  facilityDropdown: [],
  facilityDropdownLoading: true,
  officerInCharge: [],
  officerInChargeLoading: true,
  ownershipCompound: [],
};

const DropDownReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_SECURITY_FORCES:
      return {
        ...state,
        securityForces: action.payload,
        securityForcesLoading: false,
      };
    case RESET_SECURITY_FORCES:
      return {
        ...state,
        securityForces: [],
        securityForcesLoading: true,
      };
    case GET_ROLES:
      return {
        ...state,
        Roles: action.payload.roles,
        rolesLoading: false,
      };
    case RESET_ROLES:
      return {
        ...state,
        Roles: [],
        rolesLoading: true,
      };
    case GET_RANKS:
      return {
        ...state,
        Ranks: action.payload,
        ranksLoading: false,
      };
    case RESET_RANKS:
      return {
        ...state,
        Ranks: [],
        ranksLoading: true,
      };
    case GET_DIVISIONS:
      return {
        ...state,
        Divisions: action.payload,
        divisionsLoading: false,
      };
    case RESET_DIVISIONS:
      return {
        ...state,
        Divisions: [],
        divisionsLoading: true,
      };
    case GET_UNITS:
      return {
        ...state,
        UnitsLoading: false,
        Units: action.payload,
      };
    case GET_TRANSFER_STATUS:
      return {
        ...state,
        transferStatus: action.payload,
      };
    case GET_CAPTURED_FROM_LIST:
      return {
        ...state,
        capturedFrom: action.payload.weapon_captured,
      };
    case GET_IMPORTID_LIST:
      return {
        ...state,
        importIdList: action.payload.import_id_list,
        totalImportCount: action.payload.data_count,
      };

    case GET_SHIPMENTID_LIST:
      return {
        ...state,
        shipmentList: action.payload.imports_shipment_list,
        totalShipmentCount: action.payload.data_count,
      };
    case GET_JVT_SHIPMENTID_LIST:
      return {
        ...state,
        jvtShipmentList: action.payload.imports_shipment_list,
        totalJvtShipmentCount: action.payload.data_count,
      };
    case GET_SF_DIV_UNIT_MAPPER:
      return {
        ...state,
        DivMapper: action.payload,
      };
    case GET_USER_DROPDOWN:
      return {
        ...state,
        userList: action.payload,
      };
    case GET_DISTRICT_DROPDOWN:
      return {
        ...state,
        districtList: action.payload,
      };
    case GET_FACILITY_TYPE:
      return {
        ...state,
        facilityTypes: action.payload.data,
      };
    case GET_FACILITY_STATUS:
      return {
        ...state,
        facilityStatus: action.payload.data,
      };
    case GET_OWNERSHIP_COMPOUND:
      return {
        ...state,
        ownershipCompound: action.payload.data,
      };
    case GET_CONSTRUCTION_TYPE:
      return {
        ...state,
        facilityConstructionType: action.payload.data,
      };
    case GET_FACILITY_DROPDOWN:
      return {
        ...state,
        facilityDropdown: action.payload.facility_list,
        facilityDropdownLoading: false,
      };
    case RESET_FACILITY_DROPDOWN:
      return {
        ...state,
        facilityDropdown: [],
        facilityDropdownLoading: true,
      };
    case GET_OFFICER_IN_CHARGE:
      return {
        ...state,
        officerInCharge: action.payload.data.data,
        officerInChargeLoading: false,
      };
    case RESET_OFFICER_IN_CHARGE:
      return {
        ...state,
        officerInCharge: [],
        officerInChargeLoading: true,
      };
    case RESET_DISTRICT_DROPDOWN:
      return {
        ...state,
        districtList: [],
      };
    case RESET_USER_DROPDOWN:
      return {
        ...state,
        userList: [],
      };
    case RESET_SF_DIV_UNIT_MAPPER:
      return {
        ...state,
        DivMapper: [],
      };
    case RESET_CAPTURED_FROM_LIST:
      return {
        ...state,
        capturedFrom: [],
      };
    case RESET_SHIPMENT_LIST:
      return {
        ...state,
        shipmentList: [],
        totalShipmentCount: 0,
      };
    case RESET_JVT_SHIPMENT_LIST:
      return {
        ...state,
        jvtShipmentList: [],
        totalJvtShipmentCount: 0,
      };
    case RESET_DIVISION_UNITS_RANK:
      return {
        ...state,
        Divisions: [],
        Units: [],
        Ranks: [],
      };
    case RESET_DIVISION_UNITS:
      return {
        ...state,
        Divisions: [],
        Units: [],
      };
    case RESET_UNITS:
      return {
        ...state,
        UnitsLoading: true,
        Units: [],
      };

    case REHYDRATE:

    default:
      return state;
  }
};

export default DropDownReducer;
