export const GET_TRANSFER_WEAPON_PENDING = "GET_TRANSFER_WEAPON_PENDING";
export const GET_MORE_TRANSFER_WEAPON_PENDING =
  "GET_MORE_TRANSFER_WEAPON_PENDING";
export const GET_TRANSFER_WEAPON_SUCCESS = "GET_TRANSFER_WEAPON_SUCCESS";
export const GET_MORE_TRANSFER_WEAPON_SUCCESS =
  "GET_MORE_TRANSFER_WEAPON_SUCCESS";
export const RESET_TRANSFER_WEAPON = "RESET_TRANSFER_WEAPON";
export const GET_TRANSIT_WEAPON_PENDING = "GET_TRANSIT_WEAPON_PENDING";
export const GET_MORE_TRANSIT_WEAPON_PENDING =
  "GET_MORE_TRANSIT_WEAPON_PENDING";
export const GET_TRANSIT_WEAPON_SUCCESS = "GET_TRANSIT_WEAPON_SUCCESS";
export const GET_MORE_TRANSIT_WEAPON_SUCCESS =
  "GET_MORE_TRANSIT_WEAPON_SUCCESS";
export const RESET_TRANSIT_WEAPON = "RESET_TRANSIT_WEAPON";
