import axios from "axios";
import get from "lodash/get";
import configs from "../config";
import { onLogin, onLogout } from "../store/actions/auth";
import store from "../store";
import { toast } from "react-toastify";
import { loginErrorNotify } from "../constants/common";

const refrehApiUrl = `${configs.API_URL}/api/user/refresh?app_type=web`;

// const getNewToken = async () => {
//   try {
//     const { refreshToken } = store.getState().auth || "";
//     const response: any = await axios.get(refrehApiUrl, {
//       headers: { Authorization: `Bearer ${refreshToken}` },
//     });
//     if (response.data) {
//       const { user } = response.data || {};
//       onLogin(user.access_token, user.refresh_token);
//       return user.access_token;
//     }
//   } catch (error) {
//     store.dispatch(onLogout());
//   }
// };

const request = axios.create({
  baseURL: configs.API_URL,
});

request.interceptors.request.use(
  (conf: any) => {
    const { token } = store.getState().auth || "";
    if (token) {
      conf.headers.Authorization = `Bearer ${token}`;
    }

    return conf;
  },
  (error) => Promise.reject(error)
);

request.interceptors.response.use(
  (response) => response,
  async (error) => {
    const message = get(error, "response.data.detail") || "";
    const status = get(error, "response.status");

    const originalRequest = error.config;

    // if (status === 422 && message === "Signature has expired") {
    //   originalRequest._retry = true;
    //   const token = await getNewToken();
    //   axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    //   return request(originalRequest);
    // }

    if (message === "Signature has expired" && status === 422) {
      // toast.error(message);
      store.dispatch(onLogout());
    } else if (message === "Please login again !" && status === 401) {
      loginErrorNotify();
      store.dispatch(onLogout());
    } else if (
      message === "Authorized user could not be found" &&
      status === 404
    ) {
      toast.error(message);
      store.dispatch(onLogout());
    } else if (status === 500) {
      toast.error("Internal Server Error. Something went wrong!");
    }

    return Promise.reject(error);
  }
);

export default request;
