import { REHYDRATE } from "redux-persist";
import {
  GET_DYNAMIC_FACILITIES_LIST,
  RESET_DYNAMIC_FACILITIES_LIST,
} from "../ActionTypes";

interface intialState {
  DynamicFilterSecurityForces: any;
  DynamicFilterDivisions: any;
  DynamicFilterRegions: any;
  DynamicFilterDistricts: any;
  DynamicFilterStatus: any;
  DynamicFilterUnits: any;
  DynamicFilterFacilityType: any;
  DynamicFilterFacilityConstructionType: any;
  DynamicFilterNEQRate: any;
  DynamicFilterSalwCapacity: any;
  DynamicCompoundOwnership: any;
  DynamicFacilityListLoading: boolean;
}
const intialState = {
  DynamicFilterSecurityForces: [],
  DynamicFilterDivisions: [],
  DynamicFilterRegions: [],
  DynamicFilterDistricts: [],
  DynamicFilterStatus: [],
  DynamicFilterUnits: [],
  DynamicFilterFacilityType: [],
  DynamicFilterFacilityConstructionType: [],
  DynamicFilterNEQRate: [],
  DynamicFilterSalwCapacity: [],
  DynamicCompoundOwnership: [],
  DynamicFacilityListLoading: true,
};

const DynamicFilterFacilitiesListReducer = (
  state = intialState,
  action: any
) => {
  switch (action.type) {
    case GET_DYNAMIC_FACILITIES_LIST:
      return {
        ...state,
        DynamicFilterSecurityForces: action.payload.security_forces,
        DynamicFilterDivisions: action.payload.divisions,
        DynamicFilterRegions: action.payload.regions,
        DynamicFilterDistricts: action.payload.districts,
        DynamicFilterStatus: action.payload.facility_status,
        DynamicFilterUnits: action.payload.units,
        DynamicFilterFacilityType: action.payload.facility_type,
        DynamicFilterFacilityConstructionType:
          action.payload.facility_construction_type,
        DynamicFilterNEQRate: action.payload.facility_neq_rating,
        DynamicFilterSalwCapacity: action.payload.facility_salw_capacity,
        DynamicCompoundOwnership: action.payload.facility_compound_ownership,
        DynamicFacilityListLoading: false,
      };
    case RESET_DYNAMIC_FACILITIES_LIST:
      return {
        ...state,
        DynamicFilterSecurityForces: [],
        DynamicFilterDivisions: [],
        DynamicFilterRegions: [],
        DynamicFilterDistricts: [],
        DynamicFilterStatus: [],
        DynamicFilterUnits: [],
        DynamicFilterFacilityType: [],
        DynamicFilterFacilityConstructionType: [],
        DynamicFilterNEQRate: [],
        DynamicFilterSalwCapacity: [],
        DynamicCompoundOwnership: [],
        DynamicFacilityListLoading: true,
      };

    case REHYDRATE:
    default:
      return state;
  }
};

export default DynamicFilterFacilitiesListReducer;
