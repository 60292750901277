import {
  GET_SIMPLE_WEAPON_PENDING,
  GET_MORE_SIMPLE_WEAPON_PENDING,
  GET_SIMPLE_WEAPON_SUCCESS,
  GET_MORE_SIMPLE_WEAPON_SUCCESS,
  RESET_SIMPLE_WEAPON,
  GET_HOME_WEAPON,
  RESET_HOME_WEAPON,
} from "../ActionTypes/simpleWeapon";
import { REHYDRATE } from "redux-persist";

interface InitialState {
  simpleWeaponLoading: boolean;
  moreSimpleWeaponLoading: boolean;
  simpleWeaponList: any;
  simpleWeaponListCount: number;
  simpleWeaponPage: number;
  simpleWeaponExportQuery: any;
  simpleWeaponMapMarkers: any;
}

const initialState: InitialState = {
  simpleWeaponLoading: false,
  moreSimpleWeaponLoading: false,
  simpleWeaponList: [],
  simpleWeaponListCount: 0,
  simpleWeaponPage: 1,
  simpleWeaponExportQuery: [],
  simpleWeaponMapMarkers: [],
};

const SimpleWeaponReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_HOME_WEAPON:
      return {
        ...state,
        simpleWeaponMapMarkers: action.payload.homepage_weapon,
      };
    case GET_SIMPLE_WEAPON_PENDING:
      return {
        ...state,
        simpleWeaponLoading: true,
      };
    case GET_MORE_SIMPLE_WEAPON_PENDING:
      return {
        ...state,
        moreSimpleWeaponLoading: true,
      };
    case GET_SIMPLE_WEAPON_SUCCESS:
      return {
        ...state,
        simpleWeaponLoading: false,
        simpleWeaponList: action.payload.weapons_details,
        simpleWeaponListCount: action.payload.data_count,
        simpleWeaponExportQuery: action.payload.export_query,
        // simpleWeaponMapMarkers: action.payload.homepage_weapon,
        simpleWeaponPage: 2,
      };
    case GET_MORE_SIMPLE_WEAPON_SUCCESS:
      const newSimpleWeaponList = action.payload.weapons_details;
      const { simpleWeaponList } = state;
      return {
        ...state,
        moreSimpleWeaponLoading: false,
        simpleWeaponList: [...simpleWeaponList, ...newSimpleWeaponList],
        simpleWeaponListCount: action.payload.data_count,
        simpleWeaponExportQuery: action.payload.export_query,
        simpleWeaponPage: state.simpleWeaponPage + 1,
      };
    case RESET_HOME_WEAPON:
      return {
        ...state,
        simpleWeaponMapMarkers: [],
      };
    case RESET_SIMPLE_WEAPON:
      return {
        ...state,
        simpleWeaponLoading: false,
        moreSimpleWeaponLoading: false,
        simpleWeaponList: [],
        simpleWeaponListCount: 0,
        simpleWeaponExportQuery: [],
        // simpleWeaponMapMarkers: [],
        simpleWeaponPage: 1,
      };
    case REHYDRATE:
    default:
      return state;
  }
};

export default SimpleWeaponReducer;
