export const GET_SPOTCHECK_WEAPON_PENDING = "GET_SPOTCHECK_WEAPON_PENDING";
export const GET_MORE_SPOTCHECK_WEAPON_PENDING =
  "GET_MORE_SPOTCHECK_WEAPON_PENDING";
export const GET_SPOTCHECK_WEAPON_SUCCESS = "GET_SPOTCHECK_WEAPON_SUCCESS";
export const GET_MORE_SPOTCHECK_WEAPON_SUCCESS =
  "GET_MORE_SPOTCHECK_WEAPON_SUCCESS";
export const RESET_SPOTCHECK_WEAPON = "RESET_SPOTCHECK_WEAPON";
export const GET_SPOTCHECK_AMMUNITION_PENDING =
  "GET_SPOTCHECK_AMMUNITION_PENDING";
export const GET_MORE_SPOTCHECK_AMMUNITION_PENDING =
  "GET_MORE_SPOTCHECK_AMMUNITION_PENDING";
export const GET_SPOTCHECK_AMMUNITION_SUCCESS =
  "GET_SPOTCHECK_AMMUNITION_SUCCESS";
export const GET_MORE_SPOTCHECK_AMMUNITION_SUCCESS =
  "GET_MORE_SPOTCHECK_AMMUNITION_SUCCESS";
export const RESET_SPOTCHECK_AMMUNITION = "RESET_SPOTCHECK_AMMUNITION";
export const GET_SPOTCHECK_FACILITY_PENDING = "GET_SPOTCHECK_FACILITY_PENDING";
export const GET_MORE_SPOTCHECK_FACILITY_PENDING =
  "GET_MORE_SPOTCHECK_FACILITY_PENDING";
export const GET_SPOTCHECK_FACILITY_SUCCESS = "GET_SPOTCHECK_FACILITY_SUCCESS";
export const GET_MORE_SPOTCHECK_FACILITY_SUCCESS =
  "GET_MORE_SPOTCHECK_FACILITY_SUCCESS";
export const RESET_SPOTCHECK_FACILITY = "RESET_SPOTCHECK_FACILITY";
