import { REHYDRATE } from "redux-persist";
import {
  GET_DYNAMIC_TRANSFERS_LIST,
  RESET_DYNAMIC_TRANSFERS_LIST,
} from "../ActionTypes";

interface intialState {
  DynamicFilterSourceFacility: any;
  DynamicFilterDestinationFacility: any;
  DynamicFilterStatus: any;
  DynamicTrasnsferListLoading: boolean;
}
const intialState = {
  DynamicFilterSourceFacility: [],
  DynamicFilterDestinationFacility: [],
  DynamicFilterStatus: [],
  DynamicTrasnsferListLoading: true,
};

const DynamicFilterTrasnsferListReducer = (
  state = intialState,
  action: any
) => {
  switch (action.type) {
    case GET_DYNAMIC_TRANSFERS_LIST:
      return {
        ...state,
        DynamicFilterSourceFacility: action.payload.source_facility,
        DynamicFilterDestinationFacility: action.payload.destination_facility,
        DynamicFilterStatus: action.payload.status_list,
        DynamicTrasnsferListLoading: false,
      };
    case RESET_DYNAMIC_TRANSFERS_LIST:
      return {
        ...state,
        DynamicFilterSourceFacility: [],
        DynamicFilterDestinationFacility: [],
        DynamicFilterStatus: [],
        DynamicTrasnsferListLoading: true,
      };

    case REHYDRATE:
    default:
      return state;
  }
};

export default DynamicFilterTrasnsferListReducer;
