export const SET_AUTH = "SET_AUTH";

export const GET_LOCATORS = "GET_LOCATORS";

export const RESET_LOCATORS = "RESET_LOCATORS";

export const SET_USER = "SET_USER";

export const LOG_OUT = "LOG_OUT";

export const GET_USER_STATUS = "GET_USER_STATUS";

export const RESET_USER_STATUS = "RESET_USER_STATUS";

export const SET_APP_LANGUAGE = "SET_APP_LANGUAGE";

export const GET_SECURITY_FORCES = "GET_SECURITY_FORCES";

export const GET_ROLES = "GET_ROLES";

export const GET_RANKS = "GET_RANKS";

export const GET_DIVISIONS = "GET_DIVISIONS";

export const GET_UNITS = "GET_UNITS";

export const RESET_DIVISION_UNITS_RANK = "RESET_DIVISION_UNITS_RANK";

export const RESET_DIVISION_UNITS = "RESET_DIVISION_UNITS";

export const RESET_UNITS = "RESET_UNITS";

export const GET_USERS_LIST = "GET_USERS_LIST";

export const GET_ROLES_LIST = "GET_ROLES_LIST";

export const GET_PERSONNELS_LIST = "GET_PERSONNELS_LIST";

export const GET_UNITS_LIST = "GET_UNITS_LIST";

export const RESET_USERS_LIST = "RESET_USERS_LIST";

export const RESET_ROLES_LIST = "RESET_ROLES_LIST";

export const RESET_PERSONNELS_LIST = "RESET_PERSONNELS_LIST";

export const RESET_UNITS_LIST = "RESET_UNITS_LIST";

export const GET_REGIONS = "GET_REGIONS";

export const GET_DISTRICTS = "GET_DISTRICTS";

export const GET_STATESLIST = "GET_STATESLIST";

export const SET_REGIONS = "SET_REGIONS";

export const SET_DISTRICTS = "SET_DISTRICTS";

export const CLEAR_DISTRICTS = "CLEAR_DISTRICTS";

export const CLEAR_REGIONS = "CLEAR_REGIONS";

export const GET_FACILITY_LIST = "GET_FACILITY_LIST";

export const RESET_FACILITY_LIST = "RESET_FACILITY_LIST";

export const CLEAR_STATELIST = "CLEAR_STATELIST";

export const GET_WEAPONS_LIST = "GET_WEAPONS_LIST";

export const RESET_WEAPONS_LIST = "RESET_WEAPONS_LIST";

export const GET_AMMUNITIONS_LIST = "GET_AMMUNITIONS_LIST";

export const RESET_AMMUNITIONS_LIST = "RESET_AMMUNITIONS_LIST";

export const GET_SIMPLE_WEAPONS_LIST = "GET_SIMPLE_WEAPONS_LIST";

export const GET_SIMPLE_TRANSFER_WEAPONS_LIST =
  "GET_SIMPLE_TRANSFER_WEAPONS_LIST";

export const RESET_SIMPLE_TRANSFER_WEAPONS_LIST =
  "RESET_SIMPLE_TRANSFER_WEAPONS_LIST";

export const RESET_SIMPLE_WEAPONS_LIST = "RESET_SIMPLE_WEAPONS_LIST";

export const GET_SIMPLE_AMMUNITIONS_LIST = "GET_SIMPLE_AMMUNITIONS_LIST";

export const GET_SIMPLE_TRANSFER_AMMUNITIONS_LIST =
  "GET_SIMPLE_TRANSFER_AMMUNITIONS_LIST";

export const RESET_SIMPLE_TRANSFER_AMMUNITIONS_LIST =
  "RESET_SIMPLE_TRANSFER_AMMUNITIONS_LIST";

export const RESET_SIMPLE_AMMUNITIONS_LIST = "RESET_SIMPLE_AMMUNITIONS_LIST";

export const GET_FACILITY_TYPE = "GET_FACILITY_TYPE";

export const GET_FACILITY_STATUS = "GET_FACILITY_STATUS";

export const GET_CONSTRUCTION_TYPE = "GET_CONSTRUCTION_TYPE";

export const GET_FACILITY_DROPDOWN = "GET_FACILITY_DROPDOWN";

export const GET_IMPORTS_LIST = "GET_IMPORTS_LIST";

export const RESET_IMPORTS_LIST = "RESET_IMPORTS_LIST";

export const GET_TRANSFERS_LIST = "GET_TRANSFERS_LIST";

export const RESET_TRANSFERS_LIST = "RESET_TRANSFERS_LIST";

export const GET_OFFICER_IN_CHARGE = "GET_OFFICER_IN_CHARGE";

export const GET_EXPORT_FACILITY_LIST = "GET_EXPORT_FACILITY_LIST";

export const GET_EXPORT_WEAPONS_LIST = "GET_EXPORT_WEAPONS_LIST";

export const RESET_EXPORT_WEAPONS_LIST = "RESET_EXPORT_WEAPONS_LIST";

export const RESET_EXPORT_FACILITY_LIST = "RESET_EXPORT_FACILITY_LIST";

export const GET_TRANSFER_STATUS = "GET_TRANSFER_STATUS";

export const GET_SPOTCHECK_WEAPON_LIST = "GET_SPOTCHECK_WEAPON_LIST";

export const GET_SPOTCHECK_AMMUNITION_LIST = "GET_SPOTCHECK_AMMUNITION_LIST";

export const RESET_SPOTCHECK_AMMUNITION_LIST =
  "RESET_SPOTCHECK_AMMUNITION_LIST";

export const RESET_SPOTCHECK_WEAPON_LIST = "RESET_SPOTCHECK_WEAPON_LIST";

export const GET_SPOTCHECK_FACILITY_LIST = "GET_SPOTCHECK_FACILITY_LIST";

export const RESET_SPOTCHECK_FACILITY_LIST = "RESET_SPOTCHECK_FACILITY_LIST";

export const GET_IMPORTID_LIST = "GET_IMPORTID_LIST";

export const GET_SHIPMENTID_LIST = "GET_SHIPMENTID_LIST";

export const RESET_SHIPMENT_LIST = "RESET_SHIPMENT_LIST";

export const GET_SF_DIV_UNIT_MAPPER = "GET_SF_DIV_UNIT_MAPPER";

export const RESET_SF_DIV_UNIT_MAPPER = "RESET_SF_DIV_UNIT_MAPPER";

export const GET_MAP_FACILITY_LIST = "GET_MAP_FACILITY_LIST";

export const RESET_MAP_FACILITY_LIST = "RESET_MAP_FACILITY_LIST";

export const GET_JVT_SHIPMENTID_LIST = "GET_JVT_SHIPMENTID_LIST";

export const RESET_JVT_SHIPMENT_LIST = "RESET_JVT_SHIPMENT_LIST";

export const GET_CAPTURED_FROM_LIST = "GET_CAPTURED_FROM_LIST";

export const RESET_CAPTURED_FROM_LIST = "RESET_CAPTURED_FROM_LIST";

export const GET_DISTRICT_DROPDOWN = "GET_DISTRICT_DROPDOWN";

export const RESET_DISTRICT_DROPDOWN = "RESET_DISTRICT_DROPDOWN";

export const GET_USER_DROPDOWN = "GET_USER_DROPDOWN";

export const RESET_USER_DROPDOWN = "RESET_USER_DROPDOWN";

export const GET_COUNT = {
  UNITS: "UNITS",
  FACILITY: "FACILITY",
  WEAPONS: "WEAPONS",
  AMMUNITION: "AMMUNITION",
  PERSONNEL: "AMMPERSONNELUNITION_ROLES",
  IMPORTS: "IMPORTS",
  USERS: "USERS",
  ROLES: "ROLES",
  TRANSFERS: "TRANSFERS",
  SPOTCHECKS: "SPOTCHECKS",
};

export const GET_IMPORT_WEAPON_COUNT = "GET_IMPORT_WEAPON_COUNT";
export const GET_IMPORT_AMMUNITION_COUNT = "GET_IMPORT_AMMUNITION_COUNT";

export const GET_DYNAMIC_AMMUNITIONS_LIST = "GET_DYNAMIC_AMMUNITIONS_LIST";
export const RESET_DYNAMIC_AMMUNITIONS_LIST = "RESET_DYNAMIC_AMMUNITIONS_LIST";

export const GET_DYNAMIC_UNIT_LIST = "GET_DYNAMIC_UNIT_LIST";
export const RESET_DYNAMIC_UNIT_LIST = "RESET_DYNAMIC_UNIT_LIST";

export const GET_DYNAMIC_WEAPONS_LIST = "GET_DYNAMIC_WEAPONS_LIST";
export const RESET_DYNAMIC_WEAPONS_LIST = "RESET_DYNAMIC_WEAPONS_LIST";

export const GET_DYNAMIC_FACILITIES_LIST = "GET_DYNAMIC_FACILITIES_LIST";
export const RESET_DYNAMIC_FACILITIES_LIST = "RESET_DYNAMIC_FACILITIES_LIST";

export const GET_DYNAMIC_PERSONNEL_LIST = "GET_DYNAMIC_PERSONNEL_LIST";
export const RESET_DYNAMIC_PERSONNEL_LIST = "RESET_DYNAMIC_PERSONNEL_LIST";

export const GET_DYNAMIC_IMPORT_LIST = "GET_DYNAMIC_IMPORT_LIST";
export const RESET_DYNAMIC_IMPORT_LIST = "RESET_DYNAMIC_IMPORT_LIST";

export const GET_DYNAMIC_TRANSFERS_LIST = "GET_DYNAMIC_TRANSFERS_LIST";
export const RESET_DYNAMIC_TRANSFERS_LIST = "RESET_DYNAMIC_TRANSFERS_LISTT";

export const GET_DYNAMIC_SPOTCHECK = "GET_DYNAMIC_SPOTCHECK";
export const RESET_DYNAMIC_SPOTCHECK = "RESET_DYNAMIC_SPOTCHECK";

export const GET_DYNAMIC_USER_LIST = "GET_DYNAMIC_USER_LIST";
export const RESET_DYNAMIC_USER_LIST = "RESET_DYNAMIC_USER_LIST";

export const GET_DYNAMIC_ROLE_LIST = "GET_DYNAMIC_ROLE_LIST";
export const RESET_DYNAMIC_ROLE_LIST = "RESET_DYNAMIC_ROLE_LIST";

export const GET_DYNAMIC_UNIT_WEAPON_DETAIL_REPORT =
  "GET_DYNAMIC_UNIT_WEAPON_DETAIL_REPORT";
export const RESET_DYNAMIC_UNIT_WEAPON_DETAIL_REPORT =
  "RESET_DYNAMIC_UNIT_WEAPON_DETAIL_REPORT";

export const GET_DYNAMIC_CAPTURED_WEAPONS_REPORT =
  "GET_DYNAMIC_CAPTURED_WEAPONS_REPORT";
export const RESET_DYNAMIC_CAPTURED_WEAPONS_REPORT =
  "RESET_DYNAMIC_CAPTURED_WEAPONS_REPORT";

export const GET_DYNAMIC_CAPTURED_AMMUNITION_REPORT =
  "GET_DYNAMIC_CAPTURED_AMMUNITION_REPORT";
export const RESET_DYNAMIC_CAPTURED_AMMUNITION_REPORT =
  "RESET_DYNAMIC_CAPTURED_AMMUNITION_REPORT";

export const GET_DYNAMIC_TRANSFER_AMMUNITIONS =
  "GET_DYNAMIC_TRANSFER_AMMUNITIONS";
export const RESET_DYNAMIC_TRANSFER_AMMUNITIONS =
  "RESET_DYNAMIC_TRANSFER_AMMUNITIONS";

export const GET_DYNAMIC_TRANSFER_WEAPONS = "GET_DYNAMIC_TRANSFER_WEAPONS";
export const RESET_DYNAMIC_TRANSFER_WEAPONS = "RESET_DYNAMIC_TRANSFER_WEAPONS";
