export default {
  Home: {
    All: "Dhammaan",
    Facility: "Bakhaarka",
    Weapon: "Hubka",
    Ammunition: "Rasaasta",
  },
  Sidebar: {
    Home: "Bogga Hore",
    Units: "Unuga/Fadhiga",
    Facilities: "Bakhaarka",
    Weapons: "Hubka",
    Ammunitions: "Rasaasta",
    Personnel: "Shaqaale",
    Imports: "Soo dejinta",
    Transfers: "Wareejinta",
    SpotCheck: "Kormeerid",
    Reports: "Warbixinada",
    Admin: "Maamulka",
    Users: "Isticmaalayaasha",
    Roles: "Awoodaha",
    AuditLog: "Diiwaanka Hantidhawrka",
    Dropdown: "Liiska Hoos-u-dhaca Xogta",
    PersonalDetails: "Faahfaahin shaqsiyadeed",
    SignOut: "Ka bax",
    LanguageSelection: "Xulashada Luuqadda",
    LogoutConfirm: "Ma hubtaa inaad doonayso inaad ka baxdo?",
    LanguageChangeConfirm:
      "Ma hubtaa inaad rabto inaad luqadda u beddesho English?",
    Confirmation: "Hubin",
    Yes: "Haa",
    No: "Maya",
  },
  Roles: {
    Name: "Magaca",
    SecurityForce: "Nuuca Ciidanka",
    SectorDivision: "Qeybta",
    Unit: "Unuga/Fadhiga",
    Status: "Xaalada",
    Role: "Awood",
    Level: "Heerka",
    Division: "Qeybta",
    Cancel: "Ka Laabsho",
    Personnel: "Askarta",
    User: "Isticmaale",
    Roles: "Awoodaha",
    Armoury: "Bakhaarka",
    Facility: "Bakhaarka",
    Weapons: "Hubka",
    Ammunition: "Rasaasta",
    Import: "Soo dejinta",
    "Spot Check": "Kormeerid",
    SpotCheck: "kormeer",
    Transfer: "Xawilaad/Daabulid",
    Settings: "goobaha",
    Report: "Warbixin",
    CreatePersonnel: "Abuur Askari cusub",
    UpdatePersonnel: "Cusbooneesii Askari",
    ViewPersonnel: "Fiiri Askari",
    ListPersonnel: "Tix Askarta",
    ListUser: "Liiska Isticmaalayaasha",
    ViewUser: "Isticmaalaha haysta ogolaanshaha aragtida oo kaliya",
    UpdateUser: "Cusbooneysiinta Isticmaalaha",
    ListRoles: "Tix Awoodaha",
    CreateRoles: "Abuur Awoodo",
    CreateRole: "Abuur Awoodo",
    UpdateRoles: "Cusbnooneesii Awoodo",
    UpdateRole: "Cusbnooneesii Awoodo",
    EditRole: "Wax ka badal Awoodo",
    ViewRoles: "Fiiri Awoodo",
    CreateFacility: "Abuur Bakhaar cusub",
    UpdateFacility: "Cusbooneesii Bakhaar",
    ViewFacility: "Fiiri Bakhaar",
    ListFacility: "Tix Bakhaar",
    CreateWeapons: "Abuur hub cusub",
    UpdateWeapons: "Cusbooneesii hub",
    ViewWeapons: "fiiri hub",
    ListWeapons: "Tix hub",
    AssignWeapon: "Sii hub",
    CreateAmmunition: "Samaye Rasaas",
    UpdateAmmunition: "Cusbooneesii rasaas",
    ViewAmmunition: "Fiiri Rasaas",
    ListAmmunition: "Liiska Rasaas",
    ListAmmunitions: "Liiska Rasaas",
    AssignAmmunition: "Rasaas sii",
    CreateUnit: "Abuur Unuga/Fadhiga",
    UpdateUnit: "Cusbooneesii Unuga/Fadhiga",
    ViewUnit: "Fiiri Unug/Fadhiga",
    ListUnit: "Tix Unuga/Fadhiga",
    CreateImport: "Abuur xamuul cusub",
    UpdateImport: "Cusbooneesii xamuul",
    ViewImport: "Fiiri Xamuul",
    ListImports: "Tix",
    CreateSpotCheck: "Abuur kormeer cusub",
    UpdateSpotCheck: "cusbooneysiin Baro Hubin",
    ViewSpotCheck: "Fiiri kormeer",
    ListSpotChecks: "Tix Kormeerada",
    CreateTransfer: "Abuur Xawilaad cusub",
    UpdateTransfer: "Cusbooneesii Xawilaad",
    ViewTransfer: "Fiiri Xawilaad",
    ListTransfer: "Tix Xawilaadaha",
    CreateReport: "Samaye Warbixin",
    UpdateReport: "cusbooneysiin Warbixin",
    ViewReport: "Fiiri Warbixin",
    ListReport: "Tix Warbixinada",
    ViewWeaponReports: "Fiiri warbixinta hubka",
    ExportWeaponReports: "Soo daji warbixinta hubka",
    ViewAmmunitionReports: "Fiiri warbixinta rasaasta",
    ExportAmmunitionReports: "Dhoofinta Wararka Rasaasta",
    ViewImportReports: "Daawo Warbixinada Soo Dejinta",
    ViewSpotCheckReports: "Eeg Warbixinta Hubinta Goobta",
    ExportImportReports: "Dhoofinta Warbixinada Soo Dejinta",
    ExportSpotCheckReports: "Dhoofinta Warbixinta Hubinta Goobta",
    ChoosePermissionLevel: "Dooro ogolaanshaha",
    AvailablePermissionsFor: "Ogolaanshahee hada diyaar ah",
    AllPermissionsGranted: "Dhammaan u fur",
    ThisRole: "Doorkan ?",
    Permissions: "Ogolaanshooyin",
  },
  Personnel: {
    Image: "Sawir",
    Personnel: "Shaqaale",
    Name: "Magaca",
    Rank: "Darajada",
    ForcesIDNumber: "Lambarka Ciidanka",
    securityForce: "Nuuca Ciidanka",
    SectorDivision: "Qeybta",
    Unit: "Unuga/Fadhiga",
    ForcesAPNo: "AP Lambar",
    BiometricRegistrationNo: "Lambarka Diiwangelinta Suulsaarka",
    PhoneNo: "Teleefoon Lambar",
    Status: "Xaalada",
    PersonnelDetails: "Warbixinta Shaqsiga",
    firstName: "Magaca hore",
    middleName: "Magaca Dhexe",
    lastName: "Magaca Dambe",
    telephoneNumber: "Teleefoon Lambar",
    Guarantor: "Xogta Damiinka",
    ResponsiblePersonName: "Magaca Damiinka",
    ResponsiblePersonPhoneNo: "Damiinka Teleefankiisa",
    ModalHeader: "Shakhsi ayaa lagu daray",
    ModalSubHeader: "Ma waxaa rabtaa in hub u xil saarto/ shakhsi kale abuur",
    AssignLater: "Dib uga dhiib",
    AssignWeaponORAmmunition: "U dhiib Hub  Rasaas",
    EditPersonnel: "Tafatirka Shaqaalaha",
    Weapons: "Hubka",
    Ammunitions: "Rasaasta",
    WeaponType: "Nuuca Hubka",
    Calibre: "Kaliibaraha Hubka",
    SerialNo: "Lambarka Hubka",
    Condition: "Xaalad",
    WeaponOwnership: "Lahaanshaha Hubka",
    WeaponStatus: "Xalaada Hubka",
    AssignWeapon: "Sii hub",
    AmmunitionType: "Nuuca Rasaasta",
    BatchORLotNumber: "Lambarka Qafiska",
    AmmunitionStatus: "Xaaladda Rasaasta",
    TechnicalStatus: "Xaaladda Farsamada",
    HazardDivision: "Qeybta Khatarta",
    Notes: "Faahfaahinta",
    AssignAmmunition: "Rasaas sii",
    AssignAmmunitionModalEmptyText: "qofkaan wax rasaas ah kuma qorna",
    AssignWeaponModalEmptyText: "qofkaan wax hub ah kuma qorna",
    ViewAndEdit: "Daawo",
    UnitDetails: "Faahfaahinta Kooxda",
    AreYouSureYouWantToUpdateThisPersonnel:
      "Ma hubtaa inaad doonayso inaad cusboonaysiiso shaqaalahan?",
    PersonnelNotes: "Qoraalka Shaqsiyadeed",
    PersonnelUpdatedSuccessfully: "Sarkaalkaan waa la update gareeyey",
    DoneAssigning: "Waa la fuliyey waajibaadkaan",
  },
  Save: "Xifdin",
  Create: "Abuur",
  Action: "Wax ka badal",
  Cancel: "Ka noqo",
  Edit: "Wax ka badal",
  Update: "Cusbooneysiin",
  CreateS: "Abuur",
  UpdateS: "Cusbooneysiin",
  ActivityLog: "Dhaq-dhaqaaqa",
  Search: "Raadi",
  Enter: "Geli",
  Yes: "Haa",
  No: "Maya",
  Confirmation: "Hubin",
  Export: "Dhoofi",
  ItemsPerPageOf: "Walxaha boggiiba ee",
  Entries: "gelinta",
  Issue: "Arrin",
  To: "ku",
  Areyousureyouwantto: "Ma hubtaa inaad rabto",
  Clear: "Cad",
  Apply: "Codso",
  FillMandatoryFields: "Fadlan buuxi meelaha qasabka ah",
  Submit: "Gudbi",
  DateCreated: "Taariikhda lasameeyay",
  DateModified: "Taariikhda wax laga badalay",
  Version: "Nooca",
  FacilityID: "Lambarka Bakhaarka",
  FacilityName: "Magaca Bakhaarka",
  ForcesIDNumber: "Lambarka Ciidanka",
  Rank: "Darajada",
  Name: "Magaca",
  Notes: "Faahfaahinta",
  Characters: "erayo",
  ViewDetails: "U arag si faahfaahsan",
  Upload: "Ku shubid",
  Type: "Nuuca",
  Value: "Qiimaha",
  AssignWeaponOrAmmunition: "Sii Hub/Rasaas ",
  Assign: "Sii",
  ClickHereForDetailedView: "Halkan guji si aad u hesho aragti faahfaahsan",
  SomethingWentWrong: "Waxbaa qaldamay!",
  ErrorInExporting: "Khaladaadka Dhoofinta!",
  OnlyPdfFilesAccepted: "PDF Feelalka kaliya ayuu ogolaanayaa",
  PleaseTryToLoginAgain: "Fadlan isku day inaad markale gasho!",
  Maximum6Imagesuploaded: "Ugu badnaan lix sawir ayaa la galin karaa",
  Ok: "Hagaag",
  NoData: "Xog la'aan",
  Done: "La sameeyay",
  IsRequired: "Loo baahanyahay",
  AmmunitionAssignedSuccessfully: "	Qoondada Rasaasta ayaa lagu guulaystay!",
  AmmunitionUpdatedSuccessfully:
    "Rasaasta si guul leh ayaa loo cusboonaysiiyay!",
  FacilityUpdated: "Xarunta waxbaa laga bedelay!",
  FacilityCreated: "Xarunta la Diyaariyay!",
  ImportIDGeneratedSuccessfully:
    "Hubinta aqoonsiga soo dejinta waa lagu guuleystay!",
  ShipmentConfirmedSuccessfully: "Hubinta Xamuulka waa lagu guulaystay!",
  TransferSentSuccessfully: "Hubinta Wareejinta la diray!",
  TransferSavedSuccessfully: "Kaydinta Wareejinta !",
  UserUpdatedSuccessfully: "Waa lagu guuleystay !",
  UserLoggedOutSuccessfully: "Waa lagu guuleystay in laga baxo xisaabta!",
  UserDeletedSuccessfully: "Waa lagu guuleystay in xisaabta la tiro!",
  WeaponUpdatedSuccessfully: "Bedelida Hubka waa lagu guuleystay!",
  WeaponAssignedSuccessfully: "Qoondeynta Hubaka waa lagu guuleystay!",
  FieldsUpdatedBy: "Waxaa cusbooneesiiyey",
  FieldName: "Magaca Goobta",
  OldValue: "Qiimo Hore",
  NewValue: "Qiimo Cusub",
  Available: "La heli karo",
  IssuedDate: "Taariikhda la bixiyey",
  ChangedBy: "waxaa wax ka badalay",
  ChangedOn: "waxa kabadalka waa taaganyahay",
  RecoveryTown: "Magaalada laga soo qabtay",
  AddedSuccessfully: "ayaa lagu daray!",
  Unit: {
    Unit: "Unuga/Fadhiga",
    Units: "Unuga/Fadhiga",
    UnitCreate: "Unuga/Fadhiga",
    securityForce: "Nuuca Ciidanka",
    SectorDivision: "Qeybta",
    EnglishTranslation: "Turjumaada ingiriisiga",
    Region: "Gobolka",
    District: "Degmada",
    Town: "Magaalo",
    Status: "Xaalada",
    BASICDETAILS: "Faahfaahinta aasaasiga ah",
    UnitName: "Magaca Unuga",
    State: "Dal",
    LatitudeAndLongitude: "Lool / Dhig",
    SetPositionOnMap: "Goob ka dooro Khariirada",
    EnterLocation: "Gali Goobta ama tirada goobta",
    ConfirmLocation: "Hubi Goobta",
    DateCreated: "Taariikhda lasameeyay",
    DateModified: "Taariikhda wax laga beddelay",
    Version: "Nooca",
    AreyousureyouwantCreateUnit: "Ma hubtaa inaad abuurto unuga ?",
    AreyousureyouwantUpdateUnit: "Ma hubtaa inaad cusbooneysiinto unuga ?",
  },
  facility: {
    facilityImage: "Sawirada Bakhaarka",
    Sketchmapfacility: "Maabka Bakhaarka",
    facility: "Bakhaarka",
    facilityId: "Lambarka Bakhaarka",
    facilityName: "Magaca Bakhaarka",
    facilityType: "Nuuca Bakhaarka",
    SecurityForce: "Nuuca Ciidanka",
    SectorDivision: "Qeybta",
    Unit: "Unuga/Fadhiga",
    ConstructionType: "Nuuca Dhismaha",
    SALWCapacity: "Baaxada",
    NEQRating: "Cabiraka Walxaha Qarxa",
    OwnershipofCompound: "Yaa leh xerada",
    Status: "Xaalada Bakhaarka",
    AssessedUnitsofSpace: "Inta meelood ee Banaan",
    Region: "Gobolka",
    District: "Degmada",
    Town: "Magaalo",
    FacilityIdImplementer: "Lambarka Deeq bixiyaha",
    DateConstructed: "Taariikhda la dhisay",
    ConstructedBy: "Cida Dhistay",
    Donor: "Deeqbixiye",
    UploadImages: "Soo rar sawirada",
    Uploadupto2images: "Soo rar ilaa 2 sawir",
    LicenseNumberName: "Shati Lambar/Magac",
    LicenseDocument: "Dukumentiga shatiga",
    Licensefileisrequired: "Faylka shatiga ayaa loo baahan yahay",
    BASICDETAILS: "Xogta Bakhaarka",
    UnitofSpace: "Cabirka Bakhaarka",
    Length: "Dhererka",
    Width: "Balaca",
    Height: "Jooga",
    LocationDetails: "Faahfaahinta Goobta",
    State: "Dal",
    LatitudeAndLongitude: "Lool / Dhig",
    SetPositionOnMap: "Goob ka dooro Khariirada",
    EnterLocation: "Gali Goobta ama tirada goobta",
    ConfirmLocation: "Hubi Goobta",
    OfficerinCharge: "Dooro Sarkaalka",
    selectOIC: "Dooro Bakhaar hayaha",
    OIC: "Sarkaalka masuulka ka ah",
    OtherDetails: "Faahfaahinta kale",
    UploadedImages: "Sawirada lasoo galiyay",
    Weapons: "Hubka",
    Ammunitions: "Rasaasta",
    ThisFacility: "Xaruntan ?",
    Division: "Qeybta",
    DateCreated: "Taariikhda lasameeyay",
    Action: "Ficilka",
    ExportingState: "Wadanka laga soo dhoofiyay",
    GovernmentUserMark: "Calaamada dowlad la isticmaalo",
    OtherUniqueIdentifyingMark: "Calaamadaha kale ee lagu aqoonsan karo",
    FacilityImageNotAvailable: "Diyaar ma aha Sawirka Xarunta",
    SktechMapFacilityNotAvailable: "Khariirada Xarunta ma aha diyaar",
    Licenseupload: "Soo geli",
    License: "shatiga",
  },
  weapon: {
    OtherDetails: "Faahfaahinta kale",
    Rank: "Darajada",
    UploadedImages: "Sawirada lasoo galiyay",
    Weapon: "Hubka",
    WeaponStatus: "Xalaada Hubka",
    WeaponType: "Nuuca Hubka",
    Calibre: "Kaliibaraha Hubka",
    Serialnumber: "Lambarka Hubka",
    GovernmentUserMark: "Calaamada Dowladda",
    OtherUniqueIdentifyingMark: "Calaamadaha kale ee lagu aqoonsan karo",
    Condition: "Xaalad",
    Ownership: "Lahaanshaha",
    Region: "Gobolka",
    SecurityForce: "Nuuca Ciidanka",
    SectorDivision: "Qeybta",
    Unit: "Unuga/Fadhiga",
    Capturedweapon: "Hub la soo qabtay",
    ModelSystemDesignation: "Moodalka Hubka",
    GenericCategory1: "Nooca Qebta 1",
    GenericCategory2: "Nooca Qebta 2",
    GenericCategory3: "Nooca Qebta 3",
    YearofManufacturing: "Sanadka la sameeyay",
    ManufacturingCountry: "Dalka lagu sameeyay",
    Manufacturer: "Cida samaysay",
    QANote: "Faalada Xog Xaqiijayaha",
    AcceptRejectRecord: "Ogolaansho/Diidmo",
    ReasonforRejection: "Sababta Diidmada",
    QABy: "Waxaa xaqiijiyey",
    JVTVerificationStatus: "Xaalada Hubinta JVT",
    Import: "Soo dejinta",
    YearofImport: "Sanadka la soo dejiyay",
    ExportingState: "Wadanka laga soo dhoofiyay",
    UNNoteNumber: "Lambarka UN-ka",
    BasicDetails: "Faahfaahinta",
    WeaponCalibre: "Kaliibaraha Hubka",
    WeaponCondition: "Xaaladda Hubka",
    Serialnumberavailable: "Hubka Lambar ma leeyahay",
    Enterserialnumber: "Geli lambarka taxanaha",
    GovernmentUserMarkAvailable:
      "Isticmaalaha Calaamda Dawladda oo La Heli karo",
    ReasonfornoSerialNo: "Maxuu Lambar u lahayn",
    GenerateSerialNo: "Dhalinta NambarTaxane ah",
    Description: "Sharaxaada",
    Weaponownership: "Cida leh Hubka",
    WeaponValue: "Qiimaha Hubka($)",
    UploadImages: "Soo rar sawirada",
    Uploadupto6images: "Soo rar ilaa 6 sawir",
    Upload: "Ku shubid",
    UploadText: "Soo gali ",
    OneorMulitpleImages: "hal sawir ama wax kabadan",
    LocationDetails: "Faahfaahinta Goobta",
    facility: "Bakhaarka",
    FacilityName: "Magaca Bakhaarka",
    State: "Dal",
    District: "Degmo",
    Town: "Magaalo",
    Destroyeddetails: "Faahfaahin burburay",
    Datedestroyed: "Taariikhda la Burburiyay",
    Locationdestroyed: "Goobta lagu burburiyay",
    Destroyedby: "waxaa burburiyay",
    AssignCustodian: "Qof u xil saar",
    Custodiandetails: "Xogta Hub Sidaha",
    Custodianname: "Magaca masuulka",
    SearchPersonnel: "Raadi Shaqsi ama askari",
    TelephoneNumber: "Lambarka Taleefonka",
    Lostdetails: "Faahfaahinta luntay",
    LostDate: "Taariikhda uu lumay",
    Descriptionofloss: "Faahfaahinta Hubka Lumay",
    Lostdescription: "Sharaxaad lumida",
    MarkedtoDestroydetails: "Waxaa loo calaamadeeyey burburin faahfaahinta",
    DateofMarking: "Taariikhda Calaamadaynta",
    Methodname: "Habka magaca",
    Reclaimeddate: "Taariikhda dib loo soo celiyay",
    MethodofMarking: "Habka calaamadaynta",
    Notes: "Faahfaahinta",
    Reclaimeddetails: "Faahfaahinta la soo celiyay",
    Date: "Taariikhda",
    SoldDetails: "Faahfaahinta la iibiyay",
    DateSold: "Taariikhda la Iibiyay",
    Unknowndetails: "Faahfaahin aan la garanayn",
    RecordUpdated: "Xog Aruuriyaha",
    UpdatedDate: "Taariikhda la cusbooneesiiyay",
    UpdatedbyRank: "Darajada",
    UpdatedbySecurityForceID: "Lambar Ciidan",
    UpdatedbyName: "Magaca Buuxa",
    Isthisweaponcaptured: "Hubknaan mala soo qabtay?",
    CapturedFrom: "Cida Laga soo qabtay",
    CapturedDate: "Taariikhda la soo qbtay",
    Recoverystate: "Maamul goboleedka laga soo qabtay",
    RecoveryRegion: "Gobolka laga soo qabtay",
    RecoveryDistrict: "Degmada Soo kabashada",
    TownORVillage: "Magaalo/ Tuulo",
    DescriptiononRecovery: "Faahfaahinta sida loo soo qabtay",
    QualityAssurance: "Hubinta tayada",
    QualityAssuranceNote: "Qoraalka Sugnaanta Tayada",
    Verification: "Hubinta",
    Stateofrecovery: "Xaaladda soo kabashada",
    Regionofrecovery: "Gobolka laga soo qaaday",
    Districtofrecovery: "Degmada laga soo qabtay",
    Weapondetails: "Faahfaahinta Hubka",
    Shipment: "Raritaanka",
    ImportID: "Lambarka Xamuulka",
    ShipmentID: "Aqoonsiga shixnada",
    ViewWeapon: "Daawo Hubka",
    WeaponWithSerialNo: "hubka aan taxan lahayn",
    AndGovernmentUserMark: "iyo calaamada isticmaalaha dawladda",
    WeaponCreated: "Hub si guul leh loo sameeyay",
    WeaponCreationConfirmation: "Ma rabtaa in aad ku abuurto hub",
    FacilityId: "Lambarka Bakhaarka",
    Status: "Xaalada",
    Images: "Sawirada",
    LatitudeAndLongitude: "Lool / Dhig",
    ForcesIDNumber: "Lambark Ciidanka",
    AcceptReject: "Ogolaansho/Diidmo",
    DescriptonOfRecovery: "Faahfaahinta sida loo soo helay",
    WeaponNotes: "Faahfaahinta hubka",
    AddAnotherModelSystemDesignation:
      "ku dar nooc kale oo hab laysla qaataay ah",
    WeaponWithSameSpecificationFound: "Hub leh tilmaamo isku mid ah ayaa jira.",
    UnknownDate: "Lama Oga Taariikhda",
    SoldDate: "La gatay Taariikhda",
    SoldNotes: "La gatay Faahfaahinta",
    UnknownNotes: "Lama Oga Faahfaahinta",
    MarkedToDestroyNotes: "Waxaa loo calaamadeeyey burburin Faahfaahinta",
    QACalibre: "Kaliibaraha xaqiijiyaha",
  },
  Ammunition: {
    AmmunitionStatus: "Xaalada rasaasta",
    AmmunitionType: "Nuuca Rasaasta",
    AmmunitionCalibre: "Kaliibaraha Rasaasta",
    AmmunitionRole: "Doorka rasaasta",
    Quantity: "Tirada",
    BatchLotNumber: "Lambarka Qafiska",
    GovernmentUserMark: "Calaamada Dowladda",
    OtherUniqueIdentifyingMark: "Calaamadaha lagu aqoonsan karo",
    Description: "Sharaxaada",
    Region: "Gobolka",
    SecurityForce: "Nuuca Ciidanka",
    SectorDivision: "Qeybta",
    Unit: "Unuga/Fadhiga",
    FacilityName: "Magaca Bakhaarka",
    Stockpile: "Kayd",
    TechnicalStatus: "Xaalada Farsamo",
    HazardDivision: "Nuuca hubka/qalabka",
    CompatibilityGroup: "Guruubka is leh",
    Category1: "Qeybta 1",
    Category2: "Qeybta 2",
    ModelOrHeadstamp: "Nuuca Rasaasta",
    Calibre: "Kaliibaraha",
    NetExplosiveQuantity: "Tirada guud ee walxaha qarxa",
    YearofManufacture: "Sanadka la sameeyay",
    ManufacturingCountry: "Dalka lagu sameeyay",
    Manufacturer: "Cida samaysay",
    QANote: "Faahfaahin",
    AcceptRejectrecord: "Ogolaansh/Diidmo",
    ReasonForRejection: "Sababta Diidmada",
    QABy: "Waxaa xaqiijiyey",
    JVTVerificationStatus: "Xaalada Hubinta JVT",
    Import: "Soo dejinta",
    YearofImport: "Sanadka la soo dejiyay",
    ExportingState: "Wadanka laga soo dhoofiyay",
    UNNoteNumber: "Lambarka UN-ka",
    AmmunitionDetails: "Faahfaahinta Rasaasta",
    QualityAssurance: "Hubinta Tayada",
    ExpendbyDate: "Taariikhda bixinta/isticmaalka",
    ImportID: "Lambarka Xamuulka",
    ShipmentID: "Aqoonsiga shixnada",
    AmmunitionDescription: "Tilmaamaha Rasaasta",
    BasicDetails: "Faahfaahinta",
    LocationDetails: "Faahfaahinta Goobta",
    State: "Dal",
    District: "Degmo",
    TownVillage: "Magaalo/Tuulo",
    DestroyedDetails: "Faah-faahinta Burburtay",
    DateDestroyed: "Taariikhda Burburtay",
    LocationDestroyed: "Goobta La Burburay",
    Destroyedby: "Waxaa dumiyay",
    OtherDetails: "Faahfaahinta kale",
    IsthisAmmunitioncaptured: "Rasaasta la soo qabtay miyaa?",
    CapturedFrom: "Cida laga soo qabtay",
    CapturedDate: "Taariikhda la qabtay",
    RecoveryState: "Maamul goboleedka laga soo qabtay",
    RecoveryRegion: "Gobolka laga soo qabtay",
    RecoveryDistrict: "Degmada Soo kabashada",
    LostDetails: "Faahfaahinta Lumay",
    LostDate: "Taariikhda ay luntay",
    Descriptionofloss: "Faahfaahinta alaabta luntay",
    DateOfMarking: "Taariikhda Calaamadaynta",
    MethodofMarking: "Habka calaamadaynta",
    Notes: "Faahfaahinta",
    AcceptReject: "Ogolaansho/Diidmo",
    QualityAssuranceNote: "Qoraalka Sugnaanta Tayada",
    RecordUpdated: "Diiwaanka waa la cusboonaysiiyay",
    UpdatedDate: "Taariikhda la cusbooneysiiyay",
    UpdatedbyRank: "Darajada",
    UpdatedbySecurityForceID: "Lambar Ciidan",
    UpdatedbyName: "Magaca Buuxa",
    Verification: "Xaqiijinta",
    UpdateAmmunition: "Cusbooneysii Rasaasta",
    CreateAmmunition: "Samee rasaas",
    Areyousureyouwantto: "Ma hubtaa inaad rabto",
    ThisAmmunition: "Rasaastan",
    StateofRecovery: "Xaalada Soo Kabashada",
    RegionofRecovery: "Gobolka Soo kabashada",
    DistrictofRecovery: "Gobol iyo Degmo",
    DescriptiononRecovery: "Faahfaahinta sida loo soo qabtay",
    MethodName: "Habka Magaca",
    AcceptedRejected: "Waa la aqbalay / diiday",
    ViewAmmunition: "Daawo Rasaasta",
    CustodianDetails: "Xogta Hub Sidaha",
    AssignCustodian: "Qof u xil saar",
    SearchPersonnel: "Raadi Shaqsi ama askari",
    TelephoneNumber: "Lambarka Taleefanka",
    Searching: "Raadinta",
    Search: "Raadi",
    ResultsFound: "Natiijooyinka La Helay",
    CustodianName: "Magaca masuulka",
    Rank: "Darajada",
    ViewDetails: "U arag si faahfaahsan",
    UploadImages: "Soo rar sawirada",
    Uploadupto6images: "Soo rar ilaa 6 sawir",
    Upload: "Ku shubid",
    UploadText: "Soo gali ",
    OneorMulitpleImages: "hal sawir ama wax kabadan",
    NoAmmunitionData: "Ma jiraan wax rasaas ah oo laga helayo xaruntan.",
    AmmunitionCreated: "Rasaas ayaa loo sameeyay si guul leh",
    AmmuniCreationConfirmation: "Ma waxaad rabtaa inaad ku abuurto rasaas",
    FacilityId: "Lambarka Bakhaarka",
    StackNumber: "Rastada Bakhaarka",
    AmmunitionNotes: "Qoraallada Rasaasta",
    Container: "Weel",
    BoxOrCaseNumber: "Lambarka Sanduuqa",
    PalletSerialNumber: "Lambarka Xermada",
    AmTagApplied: "Kiimiko Ma leeyahay",
    AmTagCode: "Kiimiko Lambar",
    Role: "Awood",
    Nature: "Dabeecada",
    Facility: "Bakhaarka",
    LsaClassification: "Lsa Classification",
    PalletSerialNo: "Lambarka Xermada",
    CapturedAmmunition: "Rasaasta la soo qabto",
    Model: "Moodelka rasaasta",
    Headstamp: "Calaamadda rasaasta madaxeeda",
    Status: "Xaalada",
    Images: "Sawirada",
    LatitudeAndLongitude: "Lool / Dhig",
    Town: "Magaalo",
    Lostdescription: "Sharaxaad lumida",
    DescriptionofRecovery: "Faahfaahinta sida loo soo qabtay",
    Exportingstate: "Wadanka laga soo dhoofiyay",
    AddModelSystemDesignation: "Ku dar moodelka rasaasta loogu tala galay",
    AddAnotherAmmunitionModel: "ku dar rasaas kale iyo moodelkeeda",
    AddAnotherAmmunitionHeadstamp: "Ku dar rasaas kale iyo Calaamadeeda",
    MarkedtoDestroyDetails: "Waxaa loo calaamadeeyey burburin faahfaahinta",
  },
  spotcheck: {
    spotcheckidfacility: "Lambarka Bakhaarka",
    spotcheckidweapon: "Lambarka Bakhaarka",
    SpotcheckID: "Lambarka Bakhaarka",
    Dateofspotcheck: "Taariikhada kormeerka",
    WeaponType: "Nooca Hubka",
    SerialNumber: "Lambarka Hubka",
    GovernmentUserMark: "Calaamada Dowladda",
    AssosiatedimportId: "Xiriirka uu la leeyahay xamuul lambar",
    SpotchekedBy: "Waxaa kormeeray",
    SecurityForce: "Nuuca Ciidanka",
    Force: "Nooca Ciidanka",
    Outcome: "Natiijada",
    BatchOrlotnumber: "Lambarka Qafiska",
    AmmunitionCalibre: "Kaliibaraha Rasaasta",
    Quantity: "Tirada",
    Description: "faahfaahin",
    facilityId: "Lambarka Bakhaarka",
    SectorDivision: "Qeybta",
    Unit: "Unuga/Fadhiga",
    Quantityofweaponsspotcheked: "Tirada hubka la kormeeray",
    QuantityofAmmunitionspotcheked: "Tirada rasaasta la kormeeray",
    PercentageTotalFacilityWeapons: "% Tirada Bakhaarka hubka",
    PercentageTotalFacilityAmmunition: "% Tirada rasaasta taala bakhaarka",
    SpotChecks: "Kormeerid",
    SpotCheckDetails: "Faahfaahinta kormeerka",
    WeaponStatus: "Xalaada Hubka",
    MatchesFound: "Is lahaansho hub%",
    MisMatcheFound: "Kala duwanaasho hub%",
    SpotCheckDateTime: "Taariikhada iyo Waqtiga kormeerka",
    SpotCheckBy: "Waxaa kormeeray",
    Images: "Sawirada",
    SpotCheckVerificationDetails: "Xaqiijinta kormeerka",
    WeaponImages: "Sawirada Hubka",
    Match: "Islahaansho",
    Stored: "kaydsan",
    WeaponCalibre: "Kaliibaraha Hubka",
    GovernmentUserMarkAvailable:
      "Isticmaalaha Calaamda Dawladda oo La Heli karo",
    OtherUniqueIdentifyingMark: "Calaamado kale oo naadir ah",
    WeaponCondition: "Xaaladda Hubka",
    WeaponOwnership: "Lahaanshaha Hubka",
    Facility: "Bakhaarka",
    Edited: "Waxaa wax ka badalay",
    IssuedTo: "Magaca Askariga",
    AmmunitionType: "Nuuca Rasaasta",
    BatchORLotNumber: "Lambarka Qafiska",
    Status: "Xaalada",
    facilityImage: "Sawirka Bakhaarka",
    MapFacilityImage: "Sawirada Mapka Bakhaarka",
    MapImage: "Sawirka Mapka",
    facilityName: "Magaca Bakhaarka",
    SALWCapacity: "Baxaada",
    OwnershipofCompound: "Yaa leh xerada",
    Latitude: "lool",
    Longitude: "Dhig",
    NEQRating: "Qiimaynta NEQ",
    OfficerinCharge: "Askariga ",
    Stockpile: "Keydka",
    TechnicalStatus: "Xaaladda Farsamada",
    HazardDivision: "Qeybta Khatarta",
    AmmunitionStatus: "Xaalada rasaasta",
    AmmunitionDescription: "Faahfaahin",
    AmmunitionImages: "Sawirada Rasaasta",
    CompatibilityGroup: "Kooxda waafaqsan",
    ExpendByDate: "Taariikhda lagu kordhiyay",
    AmmunitionRole: "Doorka rasaasta",
    MatchesFoundWeapon: "Is lahaansho hub%",
    MisMatcheFoundWeapon: "Kala duwanaasho hub%",
    SpotCheckDateTimeWeapon: "Taariikhada kormeerka iyo waqtiga",
    SpotCheckVerificationDetailsWeapon: "Faahfaahinta xaqiijinta kormeerka",
    MatchFacility: "waa isku mid",
  },
  Import: {
    Imports: "Soo dejinta",
    ImportId: "Lambarka Xamuulka",
    DateCreated: "Taariikhda lasameeyay",
    ExportingState: "Wadanka laga soo dhoofiyay",
    ShipmentID: "Aqoonsiga shixnada",
    DeliveryDate: "Waqtiga la geynayo",
    DeliveryLocation: "Goobta la geynayo",
    EndUser: "Yaa Isticmaalaya",
    TotalWeapons: "Tirada guud ee hubka",
    TotalAmmunition: "Tirada guud ee rasaasta",
    PDNSubmitted: "Warqadda Ogeysiinta Keenista Kadib",
    InitiateImport: "Soo Dejin",
    GenerateImportID: "Samee aqoonsiga soo dejinta",
    ConfirmPDN: "Hubin PDN",
    Step1: "Tallaabada 1",
    ExportingCountry: "Dalka Dhoofinta",
    EstimatedDateofArrival: "Qiyaasta Wakhtiga helida",
    PlannedDeliveryLocation: "Goobta loo qorsheeyay in la geeyo",
    MethodofShipment: "Habka raritaanka marinada hawada",
    IntendedEndUser: "Ku talagalida cida isticmaaleysa",
    DescriptionofItemsImported: "Faahfaahinta alaabta la soo dhoofiyay",
    AssociatedPreDeliveryDocuments: "Waraaqadaha Horudhaca",
    AirwayBillsandBillsofLading: "Rasiita diyaarada xamuulka soo qaaday",
    AdvancedDeliveryDocuments: "Waqadda Ogeysiinta Helida Xamuulka",
    ConfirmShipment: "Xaqiiji raritaanka",
    ConfirmTheShipment: "Xaqiiji shixnadda",
    Step2: "Tallaabada 2",
    PartoftheImporthasarrivedAddremainingitemsviaanothershipment:
      "Qayb ka mid ah soo dejinta ayaa timid? Ku dar walxaha haray shixnadaha kale",
    AddShipment: "Ku dar shixnad",
    ActualDateofArrival: "Taariikhda imaatinka dhabta ah",
    ShippingDocuments: "Dukumentiyada rarida",
    Shipment: "Raritaanka",
    receivedon: "ayaa la helay",
    ViewShipment: "Fiiri shixnada",
    ShippingDocument: "Dukumentiga dhoofinta",
    RegisterConfirmPDN: "Isdiiwaangeli oo Xaqiiji PDN",
    Step3: "Tallaabada 3",
    Weapon: "Hubka",
    Ammunition: "Rasaasta",
    Notes: "Faahfaahinta",
    Enterthedetailsofdifferenceindocumentation:
      "Geli faahfaahinta kala duwanaanshaha dukumeentiyada",
    PostDeliveryNotification: "Ogeysiinta keenista kadib",
    PDNcanbeuploadedonlyaftersubmittingtheaboveinformation:
      "PDN waxaa la soo gelin karaa oo keliya ka dib marka la gudbiyo macluumaadka kor ku xusan",
    ViewDetails: "U arag si faahfaahsan",
    AdvanceDeliveryNotification: "Ogeysiinta Hormarinta Hormarinta",
    Shipments: "Shixnadaha",
    NoNotes: "Wax Xusuusin ah",
    NoPDNsubmitted: "Ma loo baahno in  la ogeysiiyo keenista kadib",
    OnlyPDFIsSupported: "Kaliya PDF ayaa la taageeray",
    AdvancedDeliveryDocumentIsRequired:
      "Dukumeenti gaarsiinta horumarsan ayaa loo baahan yahay",
    PreDeliveryDocumentIsRequired:
      "Dukumeentiga keenista ka hor ayaa loo baahan yahay",
    ShippingDocumentIsRequired: "Dukumeentiga rarida ayaa loo baahan yahay",
    Upload: "Ku shubid",
    ToSubmitTheShipmentReceived:
      "Si aad u soo gudbiso shixnadda la helay, fadlan soo rar PDN (Post Delivery Notification)",
    SaveChanges: "Badbaadada isbedelada",
    SaveTheShipment: "keydso shixnadda",
    SubmitTheShipment: "soo gudbi shixnadda",
    IsEverythingInPlaceToConfirmTheShipment:
      "Wax walba ma yaallaan si loo xaqiijiyo raritaanka?",
    WeaponType: "Nuuca Hubka",
    WeaponCalibre: "Kaliibaraha Hubka",
    Quantity: "Tirada",
    AmmunitionType: "Nuuca Rasaasta",
    AmmunitionCalibre: "Kaliibaraha Rasaasta",
    UNNoteNumber: "Lambarka UN-ka",
    ShipmentReceivedOn: "Shixnadda",
    ImportShipments: "Soo rar shixnado",
  },
  Transfers: {
    Transfers: "Wareejinta",
    SendTransfers: "Soo dir Wareejin",
    ReceiveTransfers: "Hel wareejinta",
    InitiateTransfer: "Bilow wareejinta",
    TransferID: "Lambarka Xamuulka",
    SourceFacility: "Bakhaarka laga raraayo",
    DestinationFacility: "Bakhaarka loo raraayo",
    WeaponCount: "Tirada Hubka",
    AmmunitionCount: "Tirada Rasaasta",
    SentDate: "Taariikhda dirista",
    ReceivedDate: "Taariikhda helida",
    Notes: "Faahfaahinta",
    Status: "Xaalada",
    SelectedWeaponsforTransfer: "Hubka loo xushay wareejinta",
    SelectedAmmunitionforTransfer: "Rasaasta loo xushay wareejinta",
    of: "ee",
    ReadytoplaceTransferProceedtoReview:
      "Diyaar ma u tahay in la bedelo? U gudub dib u eegista",
    ReviewTransfer: "Wareejinta dib u eegista",
    DetailscannotbeeditedonFinishTransfer:
      "Faahfaahinta laguma tafatirin karo Wareejinta Dhammaystirka",
    AddNotes: "Kudar Qoraal",
    Officerincharge: "Askariga",
    FinishTransfer: "Dhameystir wareejinta",
    SaveasDraft: "U keydi si qabyo ah",
    Areyousureyouwanttofinishsendingthetransfer:
      "Ma hubtaa inaad rabto inaad dhammayso diritaanka wareejinta?",
    Areyousureyouwanttosavethetransfer:
      "Ma hubtaa inaad rabto inaad kaydiso wareejinta?",
    Confirmation: "Hubin",
    Add: "Ku dar",
    Remove: "Ka saarid",
    NotReceived: "Lama helin",
    Received: "Waa la helay Xamuulka",
    NoWeaponsavailableunderthisfacility:
      "Ma jiro wax hub ah oo laga heli karo xaruntan hoosteeda.",
    TransferDetails: "faahfaahinta Xamuulka",
    SecurityForce: "Nuuca Ciidanka",
    SourceRegion: "Gobolka laga raraayo",
    DestinationRegion: "Gobolka loo rarayo",
    DateofTransfer: "Taariikhda dirista",
    WeaponsQuantity: "Tirada Hubka",
    AmmunitionsQuantity: "Tirada rasaasta",
    StoredWeapons: "Hubka kaydsan",
    IssuedWeapons: "Hubka la soo saaray",
    MarkedtoDestroyWeapons: "Loo calaamadeeyay in lagu burburiyo Hubka",
    StoredAmmunition: "Rasaasta kaydsan",
    IssuedAmmunition: "Rasaasta la soo saaray",
    MarkedtodestroyAmmunition: "Loo calaamadeeyay in la burburiyo rasaasta",
    SelectedWeapons: "Hubka la doortay",
    SelectedAmmunition: "Rasaas la doortay",
    FinishReceivingTransfer: "Dhamaystir Helitaanka Wareejinta",
    ConfirmPartialReceipt: "Xaqiiji qayb ka mid ah rasiidka",
    CancelTransfer: "Jooji wareejinta",
    Areyousureyouwanttocancelthetransfer:
      "Ma hubtaa inaad doonayso inaad joojiso wareejinta?",
    DetailsfortransfercannotbechangedonceyouconfirmPartialTransferFinishTransfer:
      "Faahfaahinta wareejinta lama beddeli karo marka aad xaqiijiso wareejinta qaybeed / dhammayn wareejinta",
    TransferCancelled: "Wareejinta waa la joojiyay",
    Upload: "Ku shubid",
    AuthorizationRequest: "Codsi ogolaansho",
    ReferenceNumber: "Numbarka tixraaca",
    TransferCancelledSuccessfully: "Wareejintaa waa laga noqday!",
    TransferPartiallyReceivedSuccessfully:
      "Qayb ahaan wareejintaa waa lagu guulaystay!",
    DownloadAcknowledgementReceipt:
      "Soo Deji Rasiitka Helitaanka Hubka & Rasaasta",
    TransferDownloadVerifyMsg:
      "Ma rabtaa inaa soo dajiso rasiitka Helitaanka Hubka ama Rasaasta?",
  },
  Admin: {
    Users: "Isticmaalayaasha",
    User: "Isticmaale",
    Name: "Magaca",
    Rank: "Darajada",
    IDNumber: "Lambar Askari",
    Email: "Email",
    SecurityForce: "Nuuca Ciidanka",
    SectorDivision: "Qeybta",
    Unit: "Unuga/Fadhiga",
    Status: "Xaalada",
    Role: "Awood",
    Level: "Heerka",
    Division: "Qeybta",
    LastLogin: "Marki ugu dambeesay uu soo galo",
    ForcesIDNumber: "Lambar Askari",
    FirstName: "Magaca hore",
    LastName: "Magaca Dambe",
    UnitDetails: "Faahfaahinta Unuga ama Fadhiga",
    ChoosePermissionLevel: "Dooro ogolaanshaha ",
    AllPermissionsGranted: "Dhammaan Ogolaanshaha waa la bixiyay",
    Personnel: "Shaqaale",
    Roles: "Awoodaha",
    Facility: "Bakhaarka",
    Weapons: "Hubka",
    Ammunition: "Rasaasta",
    Import: "Soo dejinta",
    SpotCheck: "Kormeerid",
    Transfer: "Wareejin",
    Report: "Warbixin",
    EditPersonalDetails: "Tafatir Faahfaahinta Shaqsiyeed",
    EditUser: "Wax ka beddel isticmaale",
    PersonalDetails: "Faahfaahin shaqsiyadeed",
    Upload: "Ku shubid",
    Delete: "tirtirto",
    ForceLogout: "xoog ka bixid",
    Image: "Sawir",
  },
  Reports: {
    Reports: "Warbixinada",
    Categories: "Qeybta",
    Weapons: "Hubka",
    Ammunition: "Rasaasta",
    Imports: "Soo dejinta",
    SpotChecks: "Kormeerid",
    WeaponsS: "Hubka",
    AmmunitionS: "Rasaasta",
    ImportsS: "Soo dejinta",
    SpotChecksS: "Kormeerid",
    SecurityForce: "Nuuca Ciidanka",
    SectorDivision: "Qeybta",
    Unit: "Unuga/Fadhiga",
    ExportingCountry: "Wadanka",
    ConductedBy: "Waxaa kormeeray",
    SelectYearMonth: "Dooro Sannad ama Bil",
    SelectYear: "Dooro Sannad",
    WeaponsVerifiedByImportReport:
      "Hubka Lagu Xaqiijiyay Warbixinta Soo Dejinta",
    WeaponQty: "Tirada Hubka",
    WeaponSpotcheck: "Tirada Hubka la kormeeray %",
    AmmunitionQty: "Tirada Rasaasta",
    AmmunitionSpotcheck: "Tirada Rasaasta la kormeeray %",
    BreakdownOfImportsByCountryAndTypeQuantity:
      "U kala bixi wadan ahaan ama Nuuc/Tiro",
    PercentageSpotcheckByImport: "Boqolkiiba meeqo ayaa la kormeeray",
    MachineGuns: "Qoryaha Darandooriga ",
    Mortars: "hoobiyayaal",
    RocketArtillery: "Gantaalo",
    RecoillessWeapons: "Hubka aan soo noqonayn",
    CanonAndArtillery: "Canon iyo Madaafiic",
    Shotguns: "  Qoryaha riddada ah",
    OtherPistolCalibreWeapons: "  Hubka kale ee Bistooladaha",
    GrenadeLaunchers: "  Bam-gacmeedyada",
    GuidedLightWeapons: " Hubka fudud ee la hago",
    Handguns: " Qoryaha gacanta",
    RocketLaunchers: "    Gantaallayaasha",
    RiflesAndCarbines: "Qoryaha iyo Kaarbuunada",
    NotQA: "Lama Xaqiijin",
    LargeCalibreAmmunition: " Rasaasta waaweyn ee Caliber (> 57 mm)",
    MediumCalibreAmmunition: " Rasaasta Caliber Dhexdhexaad (20 ilaa 57 mm)",
    SmallCalibreAmmunition: "Rasaas Caliber-yar (< 20 mm)",
    Fuzes: "Fuzes",
    Submunitions: "Xubnaha",
    Rockets: "Gantaalo",
    Mines: "Miinada",
    GuidedMunitions: "Dagaal la hagayo",
    Grenades: "Bam-gacmeedyo",
    AircraftBombs: "Bambooyinka Diyaaradaha",
    MonthlySpotCheck: "Kormeerid Bileed",
    Month: "Bil",
    Total: "Tirada Guud",
    Region: "Gobolka",
    District: "Degmada",
    Facility: "Bakhaarka",
    SpotChecksByImportReport: "Hubinta barta warbixinta soo dejinta",
    WeaponHoldingsByUnit: "Haysashada Warbixinta Cutubka",
    UnitWeaponDetailReport: "Warbixinta Hubka- Heer Unuga/Fadhiga",
    UnitWeaponReport: "Warbixinta Hubka- Heer Cutub",
    CapturedWeaponsReport: "Warbixinta hubka la soo qabtay",
    LostWeaponsReport: "Warbixinta Hubka Lumay",
    MaintenanceReport: "Warbixinta Dayactirka",
    UnitWeaponHoldingsOverTime: "Faahfaahinta Hubka Fadhiga/Unuga",
    SmallArmsAmmunitionByUnit: "Rasaasta Qoryaha Fudud heer Unug/Fadhi",
    UnitAmmunitionHoldings: "Faahfaahinta Rasaasta Fadhiga/Unuga",
    SectorDivisionHoldingsOverTime: "Faahfaahinta Hubka Qeybta",
    AmmunitionShelfLifeReport: "Warbixinta Rasaasta",
    LostAmmunitionReport: "Warbixinta Rasaasta Luntay",
    CapturedAmmunitionReport: "Warbixinta Hubka La soo qabtay",
    FacilityId: "Lambarka Bakhaarka",
    FacilityName: "Magaca Bakhaarka",
    QtyAmmunitionMonthsToExpiry: "Rasaasta 6 Bil dhicisteeda ka dhimantahay",
    TotalNEQMonthsToExpiry: "Tirada Guud",
    QtyAmmunitionExpired: "Rasaasta Qty waa dhacday",
    TotalNEQAmmunitionExpired: "Isku geynta rasaasta NEQ waa dhacday",
    OICName: "Askariga ka masuulka ah",
    OICSecurityForce: "Taliyaha Ciidanka",
    OICTelephone: "Teleefoonka Askariga",
    Category: "Qeybta",
    BatchLotNumber: "Lambarka Qafiska",
    Calibre: "Kaliibaraha",
    CountryOfManufacture: "Wadanka soo saaray",
    Manufacturer: "Warshadda",
    GovernmentMark: "Calaamada Dowladda",
    OtherUniqueIdentifyingMark: "Calaamado Kale oo naadir ah",
    DateCaptured: "Taariikhda la soo qbtay",
    CapturedFrom: "Cidda laga soo qabsaday",
    Town: "Magaalo/Tuulo",
    Description: "Faahfaahin",
    QANote: "Faalada Xog Xaqiijayaha",
    Other: "Wax kale",
    Bistoolad: "Bistolad",
    SerialNumber: "Lambarka Hubka",
    WeaponTypes: "Nuuca hubka",
    Issued: "La bixiyey",
    Stored: "La keydiyay",
    MarkedToDestroy: "Waxaa loo calaamadeeyey burburin",
    Destroyed: "La bur-buriyay",
    Lost: "Lumay",
    Sold: "La gatay",
    ReclaimedByClan: "Qabiilka soo sheegtay",
    Draft: "Qoraal",
    InTransit: "Wadada ayuu ku sii jiraa",
    Unknown: "Lama oga",
    Name: "Magaca",
    Rank: "Darajada",
    IDNumber: "ID Lambar",
    WeaponStatus: "Xalaada Hubka",
    WeaponCondition: "Xaalada Hubka",
    CrateNumber: "Lambarka Sanduuqa",
    PalletSerialNo: "Lambarka Xermada",
    Headstamp: "Sumada rasaasta",
    Model: "Model",
    Verification: "Xaqiijin",
    VerificationReport: "Warbixinta Xaqiijinta",
    MonthlySpotchecks: "Kormeerid Bileed",
    ImportId: "Lambarka Xamuulka",
    CountryOfExport: "Wadanka",
    TotalWeaponsImported: "Tirada Guud ee Hubka la soo dajiyey ",
    PercentageWeaponsVerified: "Tirada la xaqiijiyey",
    TotalAmmunitionImported: "Tirada Guud ee rasaasta la soo dajiyey",
    PercentageAmmunitionVerified: "Tirada guud la xaqiijiyey",
    ExportingState: "Wadanka laga soo dhoofiyay",
    PercentageWeaponsVerifiedByImport:
      "Boqolkiiba Hubka Lagu Xaqiijiyay Soo Dejinta",
    DateImported: "Taariikhada",
    NumberOfWeaponsVerified: "Tirada hubka la xaqiijiyay",
    NumberOfAmmunitionsVerified: "Tirada tasaasta la xaqiijiyay",
    ModelSystemDesignation: "Modelka Qoriga",
    NumberWeaponsSpotchecked: "Tirada hubka ee baarista deg dega lagu sameeyay",
    NumberAmmunitionsSpotchecked:
      "Tirada rasaasta ee baarista deg dega lagu sameeyay",
    ImportByCountry: "Dalku soo dejiyo",
  },
  WeaponStatus: {
    Destroyed: "La Bur-buriyey",
    Draft: "Qoraal",
    "In Transit": "Safar sii ah",
    Issued: "La Baxshay",
    Lost: "Lumay",
    "Marked to destroy": "Waxaa loo calaamadeeyey burburin",
    "Reclaimed by Clan": "Reclaimed by Clan",
    Sold: "La iibiyey",
    Stored: "La Keydiyey",
    Unknown: "Lama Oga",
    Blank: "Banaan",
  },
  AmmoStatus: {
    Destroyed: "La Bur-buriyey",
    Draft: "Qoraal",
    "In Transit": "Safar sii ah",
    Issued: "La Baxshay",
    Lost: "Lumay",
    "Marked to destroy": "Waxaa loo calaamadeeyey burburin",
    "Reclaimed by Clan": "Reclaimed by Clan",
    Sold: "La iibiyey",
    Stored: "La Keydiyey",
    Unknown: "Lama Oga",
    Expended: "La Isticmaalay",
    Blank: "Banaan",
  },
  NoSerialReasons: {
    Obliterated: "Calaamada waa la tiray",
    Other: "Wax kale",
    "Worn away over time": "Calaamada waa ay tirmatay",
    Blank: "Banaan",
  },
  WeaponCondition: {
    Serviceable: "Wuu shaqeynayaa",
    Unserviceable: "Ma shaqeynayo",
    Blank: "Banaan",
  },
  WeaponOwnership: {
    Government: "Dowladda ayaa leh",
    Clan: "Qabiil ayaa leh",
    Private: "Shaqsi ayaa leh",
    Other: "Wax kale",
    Blank: "Banaan",
  },
  CapturedFrom: {
    "Al Shabaab": "Al Shabaab",
    Criminal: "Dambiile",
    "Other non-state group": "Ururo kale ee hubeysan",
    Unknown: "Lama oga",
    Other: "Wax kale",
    Blank: "Banaan",
  },
  PersonnelStatus: {
    Active: "Shaqeynayo",
    Inactive: "Aan shaqeyneyn",
    Deceased: "Dhintay",
    Defected: "Baxsad",
    Blank: "Banaan",
  },
  FacilityType: {
    Armoury: "Bakhaar",
    "Explosive Storehouse": "Bakhaarka Walxaha Qarxa",
    Blank: "Banaan",
  },
  ConstructionType: {
    Concrete: "Shub",
    Containerised: "Bakhaar Kunteenar ah",
    "Store room": "Qol keyd ah",
    Unknown: "Lama Oga",
    Others: "Wax kale",
    Blank: "Banaan",
  },
  OwnershipCompound: {
    Government: "Dowladda",
    Clan: "Qabiil ayaa leh",
    Private: "Shaqsi ayaa leh",
    Other: "Wax kale",
    Blank: "Banaan",
  },
  FacilityStatus: {
    Operational: "Shaqeynaya",
    "Non-Operational": "Aan shaqeyneyn",
    Other: "Wax kale",
    "Outside of government territory": "Dhulka dowladda ka baxsan",
    Unknown: "Lama Oga",
    Blank: "Banaan",
  },
  AmmoStockpile: {
    Operational: "Shaqeynayo",
    "War Reserve": "Hawlgal / diyaariyey",
    Training: "Tababar",
    Disposal: "Burburin",
    Production: "Soo saaris",
    Experimental: "Tijaabo",
    Blank: "Banaan",
  },
  AmmoContainer: {
    Pallet: "Xermo",
    Crate: "Sanduuq",
    Blank: "Banaan",
  },
  ActivityAction: {
    create: "Abuur",
    update: "Cusbooneysiin",
  },
  Blank: "Banaan",
  Other: "Wax kale",
};
