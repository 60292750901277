export const GET_SECURITY_FORCES = "GET_SECURITY_FORCES";
export const RESET_SECURITY_FORCES = "RESET_SECURITY_FORCES";
export const GET_ROLES = "GET_ROLES";
export const RESET_ROLES = "RESET_ROLES";
export const GET_RANKS = "GET_RANKS";
export const RESET_RANKS = "RESET_RANKS";
export const GET_DIVISIONS = "GET_DIVISIONS";
export const RESET_DIVISIONS = "RESET_DIVISIONS";
export const GET_UNITS = "GET_UNITS";
export const RESET_DIVISION_UNITS_RANK = "RESET_DIVISION_UNITS_RANK";
export const RESET_DIVISION_UNITS = "RESET_DIVISION_UNITS";
export const RESET_UNITS = "RESET_UNITS";
export const GET_IMPORTID_LIST = "GET_IMPORTID_LIST";
export const GET_TRANSFER_STATUS = "GET_TRANSFER_STATUS";
export const GET_SHIPMENTID_LIST = "GET_SHIPMENTID_LIST";
export const GET_JVT_SHIPMENTID_LIST = "GET_JVT_SHIPMENTID_LIST";
export const RESET_JVT_SHIPMENT_LIST = "RESET_JVT_SHIPMENT_LIST";
export const RESET_SHIPMENT_LIST = "RESET_SHIPMENT_LIST";
export const GET_SF_DIV_UNIT_MAPPER = "GET_SF_DIV_UNIT_MAPPER";
export const RESET_SF_DIV_UNIT_MAPPER = "RESET_SF_DIV_UNIT_MAPPER";
export const GET_CAPTURED_FROM_LIST = "GET_CAPTURED_FROM_LIST";
export const RESET_CAPTURED_FROM_LIST = "RESET_CAPTURED_FROM_LIST";
export const GET_DISTRICT_DROPDOWN = "GET_DISTRICT_DROPDOWN";
export const RESET_DISTRICT_DROPDOWN = "RESET_DISTRICT_DROPDOWN";
export const GET_USER_DROPDOWN = "GET_USER_DROPDOWN";
export const RESET_USER_DROPDOWN = "RESET_USER_DROPDOWN";
export const GET_FACILITY_TYPE = "GET_FACILITY_TYPE";
export const GET_FACILITY_STATUS = "GET_FACILITY_STATUS";
export const GET_CONSTRUCTION_TYPE = "GET_CONSTRUCTION_TYPE";
export const GET_FACILITY_DROPDOWN = "GET_FACILITY_DROPDOWN";
export const RESET_FACILITY_DROPDOWN = "RESET_FACILITY_DROPDOWN";
export const GET_OFFICER_IN_CHARGE = "GET_OFFICER_IN_CHARGE";
export const RESET_OFFICER_IN_CHARGE = "RESET_OFFICER_IN_CHARGE";
export const GET_OWNERSHIP_COMPOUND = "GET_OWNERSHIP_COMPOUND";
