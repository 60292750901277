import {
  SET_APP_LANGUAGE,
  GET_USER_STATUS,
  RESET_USER_STATUS,
} from "../ActionTypes";

interface initialState {
  language: string;
  userStatus: any;
}
const initialState = {
  language: "en",
  userStatus: false,
};

export const SettingsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_APP_LANGUAGE:
      return {
        ...state,
        language: action.language,
      };
    case GET_USER_STATUS:
      return {
        ...state,
        userStatus: action.payload,
      };
    case RESET_USER_STATUS:
      return {
        ...state,
        userStatus: false,
      };

    default:
      return state;
  }
};

export default SettingsReducer;
