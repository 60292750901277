import { lazy, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
const Login = lazy(() => import("../pages/login"));
const RedirectWeb = lazy(() => import("../pages/loginRedirection/web"));
const RedirectMobile = lazy(() => import("../pages/loginRedirection/mobile"));
const DeactivatedUser = lazy(() => import("../pages/deactivatedUser"));

const AuthRoutes = () => (
  <Switch>
    <Route exact path="/">
      <Redirect to={"/login"} />
    </Route>
    <Route
      exact
      path={"/login"}
      render={() => (
        <Suspense
          fallback={
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "16px",
              }}
            >
              <CircularProgress sx={{ color: "#DEDBD0" }} />
            </Box>
          }
        >
          <Login />
        </Suspense>
      )}
    />
    <Route
      exact
      path={"/deactivated-user"}
      render={() => (
        <Suspense
          fallback={
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "16px",
              }}
            >
              <CircularProgress sx={{ color: "#DEDBD0" }} />
            </Box>
          }
        >
          <DeactivatedUser />
        </Suspense>
      )}
    />

    <Route
      exact
      path={"/redirect-web"}
      render={() => (
        <Suspense
          fallback={
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "16px",
              }}
            >
              <CircularProgress sx={{ color: "#DEDBD0" }} />
            </Box>
          }
        >
          <RedirectWeb />
        </Suspense>
      )}
    />
    <Route
      exact
      path={"/redirect-mobile"}
      render={() => (
        <Suspense
          fallback={
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "16px",
              }}
            >
              <CircularProgress sx={{ color: "#DEDBD0" }} />
            </Box>
          }
        >
          <RedirectMobile />
        </Suspense>
      )}
    />
    <Redirect to={"/login"} />
  </Switch>
);

export default AuthRoutes;
