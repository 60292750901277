import { REHYDRATE } from "redux-persist";
import {
  GET_DYNAMIC_PERSONNEL_LIST,
  RESET_DYNAMIC_PERSONNEL_LIST,
} from "../ActionTypes";

interface InitialState {
  DynamicFilterSecurityForces: any;
  DynamicFilterDivisions: any;
  DynamicFilterUnits: any;
  DynamicFilterRanks: any;
  DynamicFilterStatus: any;
  DynamicPersonnelListLoading: boolean;
}
const initialState: InitialState = {
  DynamicFilterSecurityForces: [],
  DynamicFilterDivisions: [],
  DynamicFilterUnits: [],
  DynamicFilterRanks: [],
  DynamicFilterStatus: [],
  DynamicPersonnelListLoading: true,
};

const DynamicFilterPersonnelListReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case GET_DYNAMIC_PERSONNEL_LIST:
      return {
        ...state,
        DynamicFilterSecurityForces: action.payload.security_forces,
        DynamicFilterDivisions: action.payload.divisions,
        DynamicFilterUnits: action.payload.units,
        DynamicFilterRanks: action.payload.rank,
        DynamicFilterStatus: action.payload.status_list,
        DynamicPersonnelListLoading: false,
      };
    case RESET_DYNAMIC_PERSONNEL_LIST:
      return {
        ...state,
        DynamicFilterSecurityForces: [],
        DynamicFilterDivisions: [],
        DynamicFilterUnits: [],
        DynamicFilterRanks: [],
        DynamicFilterStatus: [],
        DynamicPersonnelListLoading: true,
      };
    case REHYDRATE:
    default:
      return state;
  }
};

export default DynamicFilterPersonnelListReducer;
