import { combineReducers, Action, ThunkAction } from "@reduxjs/toolkit";
import AuthReducer from "./auth";
import AppStore from "../../store";
import SettingsReducer from "./settings";
import UnitReducer from "./unit";
import ListingReducer from "./listing";
import LocationReducer from "./location";
import WeaponReducer from "./weapon";
import FacilityReducer from "./facility";
import AmmunitionReducer from "./ammunition";
import ReportsReducer from "./reports";
import DynamicAmmunitionReducer from "./ammunitionDynamicFilter";
import DynamicFilterUnitListReducer from "./unitDynamicFilter";
import DynamicFilterUserListReducer from "./userDynamicFilter";
import DynamicFilterPersonnelListReducer from "./personnelDynamicFilter";
import DynamicFilterRoleListReducer from "./roleDynamicFilter";
import DynamicFilterFacilitiesListReducer from "./facilityDynamicFilter";
import DynamicFilterWeaponListReducer from "./weaponDynamicFilter";
import DynamicFilterTransferListReducer from "./transferDynamicFilter";
import DynamicFilterImportListReducer from "./importDynamicFilter";
import DynamicFilterSpotCheckReducer from "./spotCheckDynamicFilter";
import PersonnelReducer from "./personnel";
import DropDownReducer from "./dropDown";
import WeaponAmmoDropDownReducer from "./weaponAmmoDropDown";
import ImportReducer from "./import";
import SpotCheckReducer from "./spotCheck";
import TransferReducer from "./transfer";
import UserReducer from "./user";
import RoleReducer from "./role";
import SimpleWeaponReducer from "./simpleWeapon";
import SimpleAmmunitionReducer from "./simpleAmmunition";
import MapFacilityReducer from "./mapFacility";
import SimpleTransferAmmoReducer from "./simpleTransferAmmo";
import SimpleTransferWeaponReducer from "./simpleTransferWeapon";
import HomeReducer from "./home";
import LogReducer from "./log";

const reducers = {
  auth: AuthReducer,
  settings: SettingsReducer,
  unit: UnitReducer,
  listing: ListingReducer,
  location: LocationReducer,
  weapon: WeaponReducer,
  facility: FacilityReducer,
  ammunition: AmmunitionReducer,
  reports: ReportsReducer,
  dynamicFilterAmmunition: DynamicAmmunitionReducer,
  dynamicFilterUnit: DynamicFilterUnitListReducer,
  dynamicFilterUser: DynamicFilterUserListReducer,
  dynamicFilterRole: DynamicFilterRoleListReducer,
  dynamicFilterPersonnel: DynamicFilterPersonnelListReducer,
  dynamicFilterFacility: DynamicFilterFacilitiesListReducer,
  dynamicFilterWeapon: DynamicFilterWeaponListReducer,
  dynamicFilterTransfer: DynamicFilterTransferListReducer,
  dynamicFilterImport: DynamicFilterImportListReducer,
  dynamicFilterSpotCheck: DynamicFilterSpotCheckReducer,
  personnel: PersonnelReducer,
  dropDown: DropDownReducer,
  weaponAmmoDropDown: WeaponAmmoDropDownReducer,
  import: ImportReducer,
  spotCheck: SpotCheckReducer,
  transfer: TransferReducer,
  user: UserReducer,
  role: RoleReducer,
  simpleWeapon: SimpleWeaponReducer,
  simpleAmmunition: SimpleAmmunitionReducer,
  mapFacility: MapFacilityReducer,
  simpleTransferWeapon: SimpleTransferWeaponReducer,
  simpleTransferAmmo: SimpleTransferAmmoReducer,
  home: HomeReducer,
  logs: LogReducer,
};

const appReducer = combineReducers(reducers);

export type RootState = ReturnType<typeof AppStore.getState>;

export type AppDispatch = typeof AppStore.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default appReducer;
