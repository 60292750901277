import React, { memo, useCallback, useEffect, useState } from "react";
import images from "../../components/images";
import classNames from "classnames";
import IconButton from "@mui/material/IconButton";
import "../sidebar/sidebar.scss";
import { onLogout, setUserCredentials } from "../../store/actions/auth";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import request from "../../services/HttpMethods";
import ConfirmationDialog from "../modal";
import { setAppLanguge } from "../../store/actions/settings";
import useSWR from "swr";
import { get } from "lodash";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "react-click-away-listener";
import {
  getUnitListCount,
  getAmmunitionListCount,
  getWeaponListCount,
  getFacilityListCount,
  getPersonnelListCount,
  getRoleListCount,
  getUserListCount,
  getImportListCount,
  getSpotcheckListCount,
  getTransferListCount,
} from "../../store/actions/listing";
import { Skeleton } from "@mui/material";

const menuAdminUsers = "Admin Users";
const sidebarHome = "Sidebar.Home";
const sidebarUnits = "Sidebar.Units";
const sidebarFacility = "Sidebar.Facilities";
const sidebarAmmo = "Sidebar.Ammunitions";
const sidebarWeapon = "Sidebar.Weapons";
const sidebarPersonnel = "Sidebar.Personnel";
const sidebarImports = "Sidebar.Imports";
const sidebarTransfers = "Sidebar.Transfers";
const sidebarSpotCheck = "Sidebar.SpotCheck";
const sidebarReports = "Sidebar.Reports";
const sidebarAdmin = "Sidebar.Admin";

const Sidebar: React.FC = () => {
  const [collapsed, setCollapsed] = React.useState(true);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [menuVisible, setMenuVisible] = useState<boolean>(false);

  const { language } = useSelector((state: any) => state.settings);
  const { t, i18n } = useTranslation();
  const dispatch: any = useDispatch();
  const [lang, setLang] = useState("en");
  const [langConfirm, setLangConfirm] = useState(false);

  const rootSubmenuKeys: any = [
    "users",
    "transfer",
    "spotCheck",
    "profile",
    "others",
  ];

  const { data } = useSWR(`/api/get_user?logged_in_user=true`);
  const userInfo = get(data, "data");

  const selectedMenuItems: string[] = [""];
  switch (location.pathname.split("/")[1]) {
    case "user":
      selectedMenuItems[0] = menuAdminUsers;
      break;
    case "personnel":
      selectedMenuItems[0] = "Personnel";
      break;
    case "assign":
      selectedMenuItems[0] = "Personnel";
      break;
    case "role":
      selectedMenuItems[0] = "Role";
      break;
    case "reports":
      selectedMenuItems[0] = "Reports";
      break;
    case "locator":
      selectedMenuItems[0] = "Locator";
      break;
    case "import":
      selectedMenuItems[0] = "Import";
      break;
    case "preference":
      selectedMenuItems[0] = "Preference";
      break;
    case "personalDetails":
      selectedMenuItems[0] = "Personal Details";
      break;
    case "units":
      selectedMenuItems[0] = "Units";
      break;
    case "facility":
      selectedMenuItems[0] = "Facility";
      break;
    case "weapons":
      selectedMenuItems[0] = "Weapons";
      break;
    case "ammunition":
      selectedMenuItems[0] = "Ammunitions";
      break;
    case "transfers":
      selectedMenuItems[0] = "Transfers";
      break;
    case "spotCheck":
      selectedMenuItems[0] = "Spot Check";
      break;
    default:
      selectedMenuItems[0] = "Locator";
  }

  /* The below code is a React useEffect hook that runs when the `selectedMenuItems` state changes. It
retrieves the `selectedMenuItem` value from the local storage and compares it with the first item in
the `selectedMenuItems` array. If they are not equal, it updates the `selectedMenuItem` value in the
local storage and removes other filter arrays from the local storage. This code is likely used to
reset filter values when the user navigates to a different menu item. */
  useEffect(() => {
    const selectedMenuFromLocalStorage: any =
      localStorage?.getItem("selectedMenuItem");
    const parsedData = JSON.parse(selectedMenuFromLocalStorage);
    if (selectedMenuItems[0] !== parsedData) {
      localStorage.setItem(
        "selectedMenuItem",
        JSON.stringify(selectedMenuItems[0])
      );
      localStorage.removeItem("unitFilterArray");
      localStorage.removeItem("facilityFilterArray");
      localStorage.removeItem("facilityFilterArray");
      localStorage.removeItem("weaponsFilterArray");
      localStorage.removeItem("ammunitionsFilterArray");
      localStorage.removeItem("personnelFilterArray");
      localStorage.removeItem("importFilterArray");
      localStorage.removeItem("sendTransfersFilterArray");
      localStorage.removeItem("receiveTransfersFilterArray");
      localStorage.removeItem("weaponSpotCheck_FilterArray");
      localStorage.removeItem("ammunitionSpotCheck_FilterArray");
      localStorage.removeItem("facilitySpotCheck_FilterArray");
      localStorage.removeItem("usersFilterArray");
      localStorage.removeItem("rolesFilterArray");
      if (
        selectedMenuItems[0] !== "Weapons" &&
        selectedMenuItems[0] !== "Ammunitions"
      ) {
        localStorage.removeItem("AmmoTransferList");
        localStorage.removeItem("WeaponTransferList");
      }
    }
  }, [selectedMenuItems]);

  /**
   * This function logs out the user by sending a request to the server and calling the onLogout
   * function if the response status is 200, and handles errors by displaying a toast message.
   */
  const logout = async () => {
    try {
      const response = await request.get(`/api/user/logout?app_type=web`);
      if (response.status === 200) {
        onLogout();
      }
    } catch (error: any) {
      // console.log("error")
    }
  };

  const [openKeys, setOpenKeys] = useState([] as any);

  /* The below code is using the `useEffect` hook to set the `openKeys` state based on the current
`location.pathname`. If the path is either "/user" or "/role", it sets `openKeys` to `["users"]`. If
the path is "/personalDetails", it sets `openKeys` to `["profile"]`. Otherwise, it sets `openKeys`
to `["others"]`. This code is likely used in a navigation component to highlight the current active
menu item. */
  useEffect(() => {
    if (location.pathname === "/user" || location.pathname === "/role") {
      setOpenKeys(["users"]);
    } else if (location.pathname === "/personalDetails") {
      setOpenKeys(["profile"]);
    } else {
      setOpenKeys(["others"]);
    }
  }, [location.pathname]);

  /**
   * The function updates the open keys state based on the latest open key selected by the user.
   * @param {any} keys - an array of keys representing the currently opened submenus
   */
  const onOpenChange = (keys: any) => {
    const latestOpenKey: any = keys.find(
      (key: any) => openKeys.indexOf(key) === -1
    );
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys((prev: any) => prev.filter((key: any) => key !== keys[0]));
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  /* API calls to get the counts to be displayed on the sidebar*/
  useEffect(() => {
    if (userInfo) {
      setUserCredentials(userInfo);

      getUnitListCount(userInfo?.sf_id, userInfo?.div_id, userInfo?.unit_id);

      getFacilityListCount(
        userInfo?.sf_id,
        userInfo?.div_id,
        userInfo?.unit_id
      );
      getWeaponListCount(userInfo?.sf_id, userInfo?.div_id, userInfo?.unit_id);
      getAmmunitionListCount(
        userInfo?.sf_id,
        userInfo?.div_id,
        userInfo?.unit_id
      );
      getPersonnelListCount(
        userInfo?.sf_id,
        userInfo?.div_id,
        userInfo?.unit_id
      );
      getImportListCount();
      getUserListCount(
        userInfo?.level,
        userInfo?.sf_id,
        userInfo?.div_id,
        userInfo?.unit_id
      );
      getRoleListCount(userInfo?.sf_id, userInfo?.div_id, userInfo?.unit_id);
      getTransferListCount(
        userInfo?.sf_id,
        userInfo?.div_id,
        userInfo?.unit_id,
        userInfo?.level
      );
      getSpotcheckListCount(
        userInfo?.sf_id,
        userInfo?.div_id,
        userInfo?.unit_id
      );
    }
  }, [userInfo]);

  /**
   * This function handles a language change by updating the language state and dispatching an action to
   * set the app language.
   */
  const handleLangChange = () => {
    setLangConfirm(false);
    i18n.changeLanguage(lang);
    dispatch(setAppLanguge(lang));
  };

  const unitCount = useSelector((state: any) => state.listing.unitCount);
  const facilityCount = useSelector(
    (state: any) => state.listing.facilityCount
  );
  const weaponCount = useSelector((state: any) => state.listing.weaponCount);
  const ammunitionCount = useSelector(
    (state: any) => state.listing.ammunitionCount
  );
  const importsCount = useSelector((state: any) => state.listing.importsCount);
  const personnelCount = useSelector(
    (state: any) => state.listing.personnelCount
  );
  const userCount = useSelector((state: any) => state.listing.userCount);
  const roleCount = useSelector((state: any) => state.listing.roleCount);
  const transferCount = useSelector(
    (state: any) => state.listing.transferCount
  );
  const spotcheckCount = useSelector(
    (state: any) => state.listing.spotcheckCount
  );

  const listUser =
    userInfo?.permissions?.length === 0 ||
    userInfo?.permissions?.find((o: any) => o.permission_id === 52);
  const listRole =
    userInfo?.permissions?.length === 0 ||
    userInfo?.permissions?.find((o: any) => o.permission_id === 53);
  const listWeapon =
    userInfo?.permissions?.length === 0 ||
    userInfo?.permissions?.find((o: any) => o.permission_id === 55);
  const listAmmunition =
    userInfo?.permissions?.length === 0 ||
    userInfo?.permissions?.find((o: any) => o.permission_id === 56);
  const listFacility =
    userInfo?.permissions?.length === 0 ||
    userInfo?.permissions?.find((o: any) => o.permission_id === 54);
  const listTransfer =
    userInfo?.permissions?.length === 0 ||
    userInfo?.permissions?.find((o: any) => o.permission_id === 60);
  const listImport =
    userInfo?.permissions.length === 0 ||
    userInfo?.permissions?.find((o: any) => o.permission_id === 59);
  const listUnit =
    userInfo?.permissions?.length === 0 ||
    userInfo?.permissions?.find((o: any) => o.permission_id === 58);
  const listPersonnel =
    userInfo?.permissions?.length === 0 ||
    userInfo?.permissions?.find((o: any) => o.permission_id === 51);
  const listReport =
    userInfo?.permissions?.length === 0 ||
    userInfo?.permissions?.find((o: any) => o.permission_id === 62);
  const listSpotcheck =
    userInfo?.permissions?.length === 0 ||
    userInfo?.permissions?.find((o: any) => o.permission_id === 61);

  const collapsedWrapper = useCallback(
    () => setCollapsed(!collapsed),
    [collapsed]
  );

  return (
    <>
      <div className={classNames("app-l-sidebar", { active: collapsed })}>
        <IconButton className="app-c-sidebar-toggle" onClick={collapsedWrapper}>
          <svg className="svg-icon icon-hamburger">
            <use xlinkHref="#iconHamburger">
              <title>Hamburger</title>
            </use>
          </svg>
        </IconButton>
        <h1 className="app-l-sidebar__logo">
          <a>
            {userInfo?.sf_id === 1 ? (
              <img className="sna-logo" src={images.snaLogo} alt="sna" />
            ) : userInfo?.sf_id === 2 ? (
              <img className="spf-logo" src={images.spfLogo} alt="spf" />
            ) : (
              <img className="fgs-logo" src={images.logo} alt="fgs" />
            )}
          </a>
        </h1>
        <div className="app-l-sidebar__menu">
          <ul>
            <li
              className={classNames("", {
                active: selectedMenuItems[0] === "Locator" ? true : false,
              })}
            >
              {!collapsed ? (
                <Tooltip title={t(sidebarHome)} arrow placement="top">
                  <NavLink to="/home" onClick={() => onOpenChange(["others"])}>
                    <svg className="svg-icon icon-locator menu-icon">
                      <use xlinkHref="#iconLocator">
                        <title>{t(sidebarHome)}</title>
                      </use>
                    </svg>
                    <span className="menu-text">{t(sidebarHome)}</span>
                  </NavLink>
                </Tooltip>
              ) : (
                <NavLink to="/home" onClick={() => onOpenChange(["others"])}>
                  <svg className="svg-icon icon-locator menu-icon">
                    <use xlinkHref="#iconLocator">
                      <title>{t(sidebarHome)}</title>
                    </use>
                  </svg>
                  <span className="menu-text">{t(sidebarHome)}</span>
                </NavLink>
              )}
            </li>
            {listPersonnel && (
              <li
                className={classNames("", {
                  active: selectedMenuItems[0] === "Personnel" ? true : false,
                })}
              >
                {!collapsed ? (
                  <Tooltip title={t(sidebarPersonnel)} arrow placement="top">
                    <NavLink
                      to="/personnel"
                      onClick={() => {
                        onOpenChange(["others"]);
                      }}
                    >
                      <svg className="svg-icon icon-personnel menu-icon">
                        <use xlinkHref="#iconPersonnel">
                          <title>{t(sidebarPersonnel)}</title>
                        </use>
                      </svg>
                      <span className="menu-text">{t(sidebarPersonnel)}</span>
                      <span className="menu-count">{personnelCount}</span>
                    </NavLink>
                  </Tooltip>
                ) : (
                  <NavLink
                    to="/personnel"
                    onClick={() => {
                      onOpenChange(["others"]);
                    }}
                  >
                    <svg className="svg-icon icon-personnel menu-icon">
                      <use xlinkHref="#iconPersonnel">
                        <title>{t(sidebarPersonnel)}</title>
                      </use>
                    </svg>
                    <span className="menu-text">{t(sidebarPersonnel)}</span>
                    <span className="menu-count">{personnelCount}</span>
                  </NavLink>
                )}
              </li>
            )}
            {listUnit && (
              <li
                className={classNames("", {
                  active: selectedMenuItems[0] === "Units" ? true : false,
                })}
              >
                {!collapsed ? (
                  <Tooltip title={t(sidebarUnits)} arrow placement="top">
                    <NavLink
                      to="/units"
                      onClick={() => {
                        localStorage.removeItem("unitFilterArray");
                        onOpenChange(["others"]);
                      }}
                    >
                      <svg className="svg-icon icon-unit menu-icon">
                        <use xlinkHref="#iconUnit">
                          <title>{t(sidebarUnits)}</title>
                        </use>
                      </svg>
                      <span className="menu-text">{t(sidebarUnits)}</span>
                      <span className="menu-count">{unitCount}</span>
                    </NavLink>
                  </Tooltip>
                ) : (
                  <NavLink
                    to="/units"
                    onClick={() => {
                      onOpenChange(["others"]);
                    }}
                  >
                    <svg className="svg-icon icon-unit menu-icon">
                      <use xlinkHref="#iconUnit">
                        <title>{t(sidebarUnits)}</title>
                      </use>
                    </svg>
                    <span className="menu-text">{t(sidebarUnits)}</span>
                    <span className="menu-count">{unitCount}</span>
                  </NavLink>
                )}
              </li>
            )}

            {listFacility && (
              <li
                className={classNames("", {
                  active: selectedMenuItems[0] === "Facility" ? true : false,
                })}
              >
                {!collapsed ? (
                  <Tooltip title={t(sidebarFacility)} arrow placement="top">
                    <NavLink
                      to="/facility"
                      onClick={() => {
                        onOpenChange(["others"]);
                      }}
                    >
                      <svg className="svg-icon icon-armoury menu-icon">
                        <use xlinkHref="#iconArmoury">
                          <title>{t(sidebarFacility)}</title>
                        </use>
                      </svg>
                      <span className="menu-text">{t(sidebarFacility)}</span>
                      <span className="menu-count">{facilityCount}</span>
                    </NavLink>
                  </Tooltip>
                ) : (
                  <NavLink
                    to="/facility"
                    onClick={() => {
                      onOpenChange(["others"]);
                    }}
                  >
                    <svg className="svg-icon icon-armoury menu-icon">
                      <use xlinkHref="#iconArmoury">
                        <title>{t(sidebarFacility)}</title>
                      </use>
                    </svg>
                    <span className="menu-text">{t(sidebarFacility)}</span>
                    <span className="menu-count">{facilityCount}</span>
                  </NavLink>
                )}
              </li>
            )}
            {listWeapon && (
              <li
                className={classNames("", {
                  active: selectedMenuItems[0] === "Weapons" ? true : false,
                })}
              >
                {!collapsed ? (
                  <Tooltip title={t(sidebarWeapon)} arrow placement="top">
                    <NavLink
                      to="/weapons"
                      onClick={() => {
                        onOpenChange(["others"]);
                      }}
                    >
                      <svg className="svg-icon icon-weapons menu-icon">
                        <use xlinkHref="#iconWeapons">
                          <title>{t(sidebarWeapon)}</title>
                        </use>
                      </svg>
                      <span className="menu-text">{t(sidebarWeapon)}</span>
                      <span className="menu-count">{weaponCount}</span>
                    </NavLink>
                  </Tooltip>
                ) : (
                  <NavLink
                    to="/weapons"
                    onClick={() => {
                      onOpenChange(["others"]);
                    }}
                  >
                    <svg className="svg-icon icon-weapons menu-icon">
                      <use xlinkHref="#iconWeapons">
                        <title>{t(sidebarWeapon)}</title>
                      </use>
                    </svg>
                    <span className="menu-text">{t(sidebarWeapon)}</span>
                    <span className="menu-count">{weaponCount}</span>
                  </NavLink>
                )}
              </li>
            )}
            {listAmmunition && (
              <li
                className={classNames("", {
                  active: selectedMenuItems[0] === "Ammunitions" ? true : false,
                })}
              >
                {!collapsed ? (
                  <Tooltip title={t(sidebarAmmo)} arrow placement="top">
                    <NavLink
                      to="/ammunition"
                      onClick={() => {
                        onOpenChange(["others"]);
                      }}
                    >
                      <svg className="svg-icon icon-ammunition menu-icon">
                        <use xlinkHref="#iconAmmunition">
                          <title>{t(sidebarAmmo)}</title>
                        </use>
                      </svg>
                      <span className="menu-text">{t(sidebarAmmo)}</span>
                      <span className="menu-count">{ammunitionCount}</span>
                    </NavLink>
                  </Tooltip>
                ) : (
                  <NavLink
                    to="/ammunition"
                    onClick={() => {
                      onOpenChange(["others"]);
                    }}
                  >
                    <svg className="svg-icon icon-ammunition menu-icon">
                      <use xlinkHref="#iconAmmunition">
                        <title>{t(sidebarAmmo)}</title>
                      </use>
                    </svg>
                    <span className="menu-text">{t(sidebarAmmo)}</span>
                    <span className="menu-count">{ammunitionCount}</span>
                  </NavLink>
                )}
              </li>
            )}

            {listImport && (
              <li
                className={classNames("", {
                  active: selectedMenuItems[0] === "Import" ? true : false,
                })}
              >
                {!collapsed ? (
                  <Tooltip title={t(sidebarImports)} arrow placement="top">
                    <NavLink
                      to="/import"
                      onClick={() => {
                        onOpenChange(["others"]);
                      }}
                    >
                      <svg className="svg-icon icon-import-new menu-icon">
                        <use xlinkHref="#iconImportNew">
                          <title>{t(sidebarImports)}</title>
                        </use>
                      </svg>
                      <span className="menu-text">{t(sidebarImports)}</span>
                      <span className="menu-count">{importsCount}</span>
                    </NavLink>
                  </Tooltip>
                ) : (
                  <NavLink
                    to="/import"
                    onClick={() => {
                      onOpenChange(["others"]);
                    }}
                  >
                    <svg className="svg-icon icon-import-new menu-icon">
                      <use xlinkHref="#iconImportNew">
                        <title>{t(sidebarImports)}</title>
                      </use>
                    </svg>
                    <span className="menu-text">{t(sidebarImports)}</span>
                    <span className="menu-count">{importsCount}</span>
                  </NavLink>
                )}
              </li>
            )}
            {listTransfer && (
              <li
                className={classNames("", {
                  active: selectedMenuItems[0] === "Transfers" ? true : false,
                })}
              >
                {!collapsed ? (
                  <Tooltip title={t(sidebarTransfers)} arrow placement="top">
                    <NavLink
                      to="/transfers"
                      onClick={() => {
                        onOpenChange(["others"]);
                      }}
                    >
                      <svg className="svg-icon icon-transfers menu-icon">
                        <use xlinkHref="#iconTransfers">
                          <title>{t(sidebarTransfers)}</title>
                        </use>
                      </svg>
                      <span className="menu-text">{t(sidebarTransfers)}</span>
                      <span className="menu-count">{transferCount}</span>
                    </NavLink>
                  </Tooltip>
                ) : (
                  <NavLink
                    to="/transfers"
                    onClick={() => {
                      onOpenChange(["others"]);
                    }}
                  >
                    <svg className="svg-icon icon-transfers menu-icon">
                      <use xlinkHref="#iconTransfers">
                        <title>{t(sidebarTransfers)}</title>
                      </use>
                    </svg>
                    <span className="menu-text">{t(sidebarTransfers)}</span>
                    <span className="menu-count">{transferCount}</span>
                  </NavLink>
                )}
              </li>
            )}
            {listSpotcheck && (
              <li
                className={classNames("", {
                  active: selectedMenuItems[0] === "Spot Check",
                })}
              >
                {!collapsed ? (
                  <Tooltip title={t(sidebarSpotCheck)} arrow placement="top">
                    <NavLink
                      to="/spotCheck"
                      onClick={() => {
                        onOpenChange(["others"]);
                      }}
                    >
                      <svg className="svg-icon icon-spot-check menu-icon">
                        <use xlinkHref="#iconSpotCheck">
                          <title>{t(sidebarSpotCheck)}</title>
                        </use>
                      </svg>
                      <span className="menu-text">{t(sidebarSpotCheck)}</span>
                      <span className="menu-count">{spotcheckCount}</span>
                    </NavLink>
                  </Tooltip>
                ) : (
                  <NavLink
                    to="/spotCheck"
                    onClick={() => {
                      onOpenChange(["others"]);
                    }}
                  >
                    <svg className="svg-icon icon-spot-check menu-icon">
                      <use xlinkHref="#iconSpotCheck">
                        <title>{t(sidebarSpotCheck)}</title>
                      </use>
                    </svg>
                    <span className="menu-text">{t(sidebarSpotCheck)}</span>
                    <span className="menu-count">{spotcheckCount}</span>
                  </NavLink>
                )}
              </li>
            )}
            {listReport && (
              <li
                className={classNames("", {
                  active: selectedMenuItems[0] === "Reports" ? true : false,
                })}
              >
                {!collapsed ? (
                  <Tooltip title={t(sidebarReports)} arrow placement="top">
                    <NavLink
                      to="/reports/dashboard"
                      onClick={() => onOpenChange(["others"])}
                    >
                      <svg className="svg-icon icon-chart menu-icon">
                        <use xlinkHref="#iconChart">
                          <title>{t(sidebarReports)}</title>
                        </use>
                      </svg>
                      <span className="menu-text">{t(sidebarReports)}</span>
                    </NavLink>
                  </Tooltip>
                ) : (
                  <NavLink
                    to="/reports/dashboard"
                    onClick={() => onOpenChange(["others"])}
                  >
                    <svg className="svg-icon icon-chart menu-icon">
                      <use xlinkHref="#iconChart">
                        <title>{t(sidebarReports)}</title>
                      </use>
                    </svg>
                    <span className="menu-text">{t(sidebarReports)}</span>
                  </NavLink>
                )}
              </li>
            )}
            {(listUser || listRole) && (
              <ClickAwayListener
                touchEvent="touchstart"
                mouseEvent="mousedown"
                onClickAway={() => {
                  setMenuVisible(false);
                }}
              >
                <li
                  className={classNames("", {
                    active:
                      selectedMenuItems[0] === menuAdminUsers ||
                      selectedMenuItems[0] === "Role",
                  })}
                >
                  {!collapsed ? (
                    <Tooltip title={t(sidebarAdmin)} arrow placement="top">
                      <a onClick={() => setMenuVisible((v) => !v)}>
                        <svg className="svg-icon icon-user-management menu-icon">
                          <use xlinkHref="#iconUserManagement">
                            <title>{t(sidebarAdmin)}</title>
                          </use>
                        </svg>
                        <span className="menu-text">{t(sidebarAdmin)}</span>
                        <IconButton className="app-c-menu__dropdown-toggle">
                          <svg className="svg-icon icon-chevron-down">
                            <use xlinkHref="#iconChevronDown">
                              <title>Chevron down</title>
                            </use>
                          </svg>
                        </IconButton>
                      </a>
                    </Tooltip>
                  ) : (
                    <a onClick={() => setMenuVisible((v) => !v)}>
                      <svg className="svg-icon icon-user-management menu-icon">
                        <use xlinkHref="#iconUserManagement">
                          <title>{t(sidebarAdmin)}</title>
                        </use>
                      </svg>
                      <span className="menu-text">{t(sidebarAdmin)}</span>
                      <IconButton className="app-c-menu__dropdown-toggle">
                        <svg className="svg-icon icon-chevron-down">
                          <use xlinkHref="#iconChevronDown">
                            <title>Chevron down</title>
                          </use>
                        </svg>
                      </IconButton>
                    </a>
                  )}

                  <div
                    className={classNames("app-l-sub-menu-blk ", {
                      active: menuVisible,
                    })}
                  >
                    <ul>
                      {listUser && (
                        <li
                          className={classNames("", {
                            active:
                              selectedMenuItems[0] === menuAdminUsers
                                ? true
                                : false,
                          })}
                        >
                          <a
                            onClick={(e) => {
                              history.push("/user");
                            }}
                          >
                            <span className="menu-text">
                              {t("Sidebar.Users")}
                            </span>
                            <span className="menu-count">
                              {userCount ? Number(userCount) : 0}
                            </span>
                          </a>
                        </li>
                      )}
                      {listRole && (
                        <li
                          className={classNames("", {
                            active:
                              selectedMenuItems[0] === "Role" ? true : false,
                          })}
                        >
                          <a
                            onClick={(e) => {
                              history.push("/role");
                            }}
                          >
                            <span className="menu-text">
                              {t("Sidebar.Roles")}
                            </span>
                            <span className="menu-count">{roleCount}</span>
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </li>
              </ClickAwayListener>
            )}
          </ul>
        </div>
        <div
          className={classNames("app-l-sidebar__menu__bottom", {
            active: openKeys.includes("profile"),
          })}
        >
          <div className="profile-menu">
            <a onClick={() => onOpenChange(["profile"])}>
              <img
                src={userInfo?.image_url ? userInfo?.image_url : images.user}
                alt="user profile"
              />
              <span className="menu-text">
                {userInfo ? (
                  `${userInfo?.first_name} ${userInfo?.last_name}`
                ) : (
                  <Skeleton width={120} />
                )}
              </span>
              <IconButton className="app-c-menu__dropdown-toggle">
                <svg className="svg-icon icon-chevron-up">
                  <use xlinkHref="#iconChevronUp">
                    <title>Locator</title>
                  </use>
                </svg>
              </IconButton>
            </a>
            <div className="profile-sub-menu">
              <ul>
                <li>
                  <div className="app-l-language-selection-wrap">
                    <svg className="svg-icon icon-language-selection menu-icon">
                      <use xlinkHref="#iconLanguageSelection">
                        <title>{t("Sidebar.LanguageSelection")}</title>
                      </use>
                    </svg>
                    <div className="language-selection-blk">
                      <span
                        onClick={() => {
                          if (language === "so") {
                            setLang("en");
                            setLangConfirm(true);
                          }
                        }}
                        className={classNames("", {
                          active: language === "en",
                        })}
                      >
                        English
                      </span>
                      <span
                        onClick={() => {
                          if (language === "en") {
                            setLang("so");
                            setLangConfirm(true);
                          }
                        }}
                        className={classNames("", {
                          active: language === "so",
                        })}
                      >
                        Somali
                      </span>
                    </div>
                  </div>
                </li>
                <li
                  className={classNames("", {
                    active:
                      selectedMenuItems[0] === "Personal Details"
                        ? true
                        : false,
                  })}
                >
                  <NavLink to={`/personalDetails`}>
                    <svg className="svg-icon icon-personal-details menu-icon">
                      <use xlinkHref="#iconPersonalDetails">
                        <title>{t("Sidebar.PersonalDetails")}</title>
                      </use>
                    </svg>
                    <span className="menu-text">
                      {t("Sidebar.PersonalDetails")}
                    </span>
                  </NavLink>
                </li>
                <li>
                  <a onClick={() => setOpen(true)}>
                    <svg className="svg-icon icon-chart menu-icon">
                      <use xlinkHref="#iconChart">
                        <title>{t("Sidebar.SignOut")}</title>
                      </use>
                    </svg>
                    <span className="menu-text">{t("Sidebar.SignOut")}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <ConfirmationDialog
        message={t("Sidebar.LogoutConfirm")}
        title={t("Sidebar.Confirmation")}
        open={open}
        setOpen={setOpen}
        dialogBtns={[
          {
            btnTitle: t("Sidebar.No"),
            onClick: () => setOpen(false),
          },
          { btnTitle: t("Sidebar.Yes"), onClick: logout },
        ]}
      />

      <ConfirmationDialog
        message={t("Sidebar.LanguageChangeConfirm")}
        title={t("Sidebar.Confirmation")}
        open={langConfirm}
        setOpen={setLangConfirm}
        dialogBtns={[
          {
            btnTitle: t("Sidebar.No"),
            onClick: () => setLangConfirm(false),
          },
          { btnTitle: t("Sidebar.Yes"), onClick: () => handleLangChange() },
        ]}
      />
    </>
  );
};
export default memo(Sidebar);
