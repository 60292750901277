import {
  GET_IMPORT_PENDING,
  GET_MORE_IMPORT_PENDING,
  GET_IMPORT_SUCCESS,
  GET_MORE_IMPORT_SUCCESS,
  RESET_IMPORT,
} from "../ActionTypes/import";
import { REHYDRATE } from "redux-persist";

interface InitialState {
  importLoading: boolean;
  moreImportLoading: boolean;
  importList: any;
  importListCount: number;
  importPage: number;
  importExportQuery: any;
}

const initialState: InitialState = {
  importLoading: false,
  moreImportLoading: false,
  importList: [],
  importListCount: 0,
  importPage: 1,
  importExportQuery: [],
};

const ImportReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_IMPORT_PENDING:
      return {
        ...state,
        importLoading: true,
      };
    case GET_MORE_IMPORT_PENDING:
      return {
        ...state,
        moreImportLoading: true,
      };
    case GET_IMPORT_SUCCESS:
      return {
        ...state,
        importLoading: false,
        importList: action.payload.import_details,
        importListCount: action.payload.count,
        importExportQuery: action.payload.dynamic_filter_query,
        importPage: 2,
      };
    case GET_MORE_IMPORT_SUCCESS:
      const newImportList = action.payload.import_details;
      const { importList } = state;
      return {
        ...state,
        moreImportLoading: false,
        importList: [...importList, ...newImportList],
        importListCount: action.payload.count,
        importExportQuery: action.payload.dynamic_filter_query,
        importPage: state.importPage + 1,
      };
    case RESET_IMPORT:
      return {
        ...state,
        importLoading: false,
        moreImportLoading: false,
        importList: [],
        importListCount: 0,
        importExportQuery: [],
        importPage: 1,
      };
    case REHYDRATE:
    default:
      return state;
  }
};

export default ImportReducer;
