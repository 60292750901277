/* This code is importing various image files from the `assets/images` directory and assigning them to
variables using the `import` statement. These variables are then used to create an object called
`images` which is exported from the module. This allows other modules to import the `images` object
and use the imported images in their own code. */

import logo from "../../assets/images/logo.svg";
import snaLogo from "../../assets/images/snaLogo.png";
import spfLogo from "../../assets/images/spfLogo.png";
import sidebarLogo from "../../assets/images/sidebar-logo.svg";
import user from "../../assets/images/user.png";
import mapPoint from "../../assets/images/home-pin.svg";
import successCheck from "../../assets/images/success-check.svg";
import pdfThumb from "../../assets/images/pdf-thumb.svg";
import currentLocation from "../../assets/images/currentLocation.svg";
import locationMarked from "../../assets/images/blueIconLocation.svg";
import mapExpand from "../../assets/images/mapExpand.svg";
import h1 from "../../assets/images/1-1.png";
import h2 from "../../assets/images/1-2.png";
import h3 from "../../assets/images/1-3.png";
import h4 from "../../assets/images/1-4.png";
import h5 from "../../assets/images/1-5.png";
import h6 from "../../assets/images/1-6.png";
import removeUser from "../../assets/images/remove-user.png";
import loginFooter from "../../assets/images/login-footer-img.svg";
import loginHeader from "../../assets/images/login-header-img.svg";
import ammoMarker from "../../assets/images/ammo-marker.png";
import weaponMarker from "../../assets/images/weapon-marker.png";
import facilityMarker from "../../assets/images/facility-marker.png";
import imageRotate from "../../assets/images/imageRotate.png";
import close from "../../assets/images/close.png";
import plus from "../../assets/images/plus.png";
import tick from "../../assets/images/tick.png";
import checked from "../../assets/images/checked.png";

const images = {
  logo,
  snaLogo,
  spfLogo,
  sidebarLogo,
  user,
  mapPoint,
  successCheck,
  pdfThumb,
  currentLocation,
  mapExpand,
  locationMarked,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  removeUser,
  loginFooter,
  loginHeader,
  ammoMarker,
  weaponMarker,
  facilityMarker,
  imageRotate,
  close,
  plus,
  tick,
  checked,
};
export default images;
