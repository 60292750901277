import {
  GET_MAP_FACILITY_PENDING,
  GET_MORE_MAP_FACILITY_PENDING,
  GET_MAP_FACILITY_SUCCESS,
  GET_MORE_MAP_FACILITY_SUCCESS,
  RESET_MAP_FACILITY,
} from "../ActionTypes/mapFacility";
import { REHYDRATE } from "redux-persist";

interface InitialState {
  mapFacilityLoading: boolean;
  moreMapFacilityLoading: boolean;
  mapFacilityList: any;
  mapFacilityListCount: number;
  mapFacilityPage: number;
  mapFacilityExportQuery: any;
  mapFacilityMarkers: any;
}

const initialState: InitialState = {
  mapFacilityLoading: false,
  moreMapFacilityLoading: false,
  mapFacilityList: [],
  mapFacilityListCount: 0,
  mapFacilityPage: 1,
  mapFacilityExportQuery: [],
  mapFacilityMarkers: [],
};

const FacilityReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_MAP_FACILITY_PENDING:
      return {
        ...state,
        mapFacilityLoading: true,
      };
    case GET_MORE_MAP_FACILITY_PENDING:
      return {
        ...state,
        moreMapFacilityLoading: true,
      };
    case GET_MAP_FACILITY_SUCCESS:
      return {
        ...state,
        mapFacilityLoading: false,
        mapFacilityList: action.payload.facility_details,
        mapFacilityListCount: action.payload.data_count,
        mapFacilityExportQuery: action.payload.export_query,
        mapFacilityMarkers: action.payload.homepage_facility,
        mapFacilityPage: 2,
      };
    case GET_MORE_MAP_FACILITY_SUCCESS:
      const newFacilityList = action.payload.facility_details;
      const { mapFacilityList } = state;
      return {
        ...state,
        moreMapFacilityLoading: false,
        mapFacilityList: [...mapFacilityList, ...newFacilityList],
        mapFacilityListCount: action.payload.data_count,
        mapFacilityExportQuery: action.payload.export_query,
        mapFacilityPage: state.mapFacilityPage + 1,
      };
    case RESET_MAP_FACILITY:
      return {
        ...state,
        mapFacilityLoading: false,
        moreMapFacilityLoading: false,
        mapFacilityList: [],
        mapFacilityListCount: 0,
        mapFacilityExportQuery: [],
        mapFacilityMarkers: [],
        mapFacilityPage: 1,
      };
    case REHYDRATE:
    default:
      return state;
  }
};

export default FacilityReducer;
