import { REHYDRATE } from "redux-persist";
import {
  GET_DYNAMIC_AMMUNITIONS_LIST,
  GET_DYNAMIC_TRANSFER_AMMUNITIONS,
  GET_DYNAMIC_TRANSFER_WEAPONS,
  RESET_DYNAMIC_AMMUNITIONS_LIST,
  RESET_DYNAMIC_TRANSFER_AMMUNITIONS,
  RESET_DYNAMIC_TRANSFER_WEAPONS,
} from "../ActionTypes";

interface intialState {
  DynamicFilterAmmunitionStatus: any;
  DynamicFilterRegions: any;
  DynamicFilterStockpileList: any;
  DynamicFilterSecurityForces: any;
  DynamicFilterDivisions: any;
  DynamicFilterUnits: any;
  DynamicFilterFacilityList: any;
  DynamicFilterTechnicalStatus: any;
  DynamicFilterHazardDivision: any;
  DynamicFilterCompatibilityGroup: any;
  DynamicFilterCountryList: any;
  DynamicFilterExportingStates: any;
  DynamicFilterManufacturerList: any;
  DynamicFilterAmTagList: any;
  DynamicFilterJvtStatus: any;
  DynamicFilterAmmunitionTypes: any;
  DynamicFilterAmmunitionRole: any;
  DynamicAmmunitionCalibre: any;
  DynamicFilterTransAmmuStatus: any;
  DynamicFilterTransAmmuTypes: any;
  DynamicFilterTransAmmuCalibre: any;
  DynamicFilterTransAmmuRegions: any;
  DynamicFilterTransAmmuSecurityForces: any;
  DynamicFilterTransAmmuDivisions: any;
  DynamicFilterTransAmmuUnits: any;
  DynamicFilterTransAmmuFacility: any;
  DynamicFilterDistrict: any;
  DynamicFilterCapturedRegion: any;
  DynamicFilterRankList: any;
  DynamicFilterLsaNatureList: any;
  DynamicFilterLsaRoleList: any;
  DynamicFilterLsaCategoryList: any;
  DynamicFilterAmmuHeadstamp: any;
  DynamicFilterAmmuModel: any;
  DynamicFilterIsCapturedAmmuList: any;
  DynamicAmmunitionListLoading: boolean;
  DynamicTranAmmunListLoading: boolean;
}
const intialState = {
  DynamicFilterAmmunitionStatus: [],
  DynamicFilterRegions: [],
  DynamicFilterStockpileList: [],
  DynamicFilterSecurityForces: [],
  DynamicFilterDivisions: [],
  DynamicFilterUnits: [],
  DynamicFilterFacilityList: [],
  DynamicFilterTechnicalStatus: [],
  DynamicFilterHazardDivision: [],
  DynamicFilterCompatibilityGroup: [],
  DynamicFilterCountryList: [],
  DynamicFilterExportingStates: [],
  DynamicFilterManufacturerList: [],
  DynamicFilterAmTagList: [],
  DynamicFilterJvtStatus: [],
  DynamicFilterAmmunitionTypes: [],
  DynamicFilterAmmunitionRole: [],
  DynamicAmmunitionCalibre: [],
  DynamicFilterTransAmmuStatus: [],
  DynamicFilterTransAmmuTypes: [],
  DynamicFilterTransAmmuCalibre: [],
  DynamicFilterTransAmmuRegions: [],
  DynamicFilterTransAmmuSecurityForces: [],
  DynamicFilterTransAmmuDivisions: [],
  DynamicFilterTransAmmuUnits: [],
  DynamicFilterTransAmmuFacility: [],
  DynamicFilterDistrict: [],
  DynamicFilterCapturedRegion: [],
  DynamicFilterRankList: [],
  DynamicFilterLsaNatureList: [],
  DynamicFilterLsaRoleList: [],
  DynamicFilterLsaCategoryList: [],
  DynamicFilterAmmuHeadstamp: [],
  DynamicFilterAmmuModel: [],
  DynamicFilterIsCapturedAmmuList: [],
  DynamicAmmunitionListLoading: true,
  DynamicTranAmmunListLoading: true,
};

const DynamicAmmunitionReducer = (state = intialState, action: any) => {
  switch (action.type) {
    case GET_DYNAMIC_AMMUNITIONS_LIST:
      return {
        ...state,
        DynamicFilterAmmunitionStatus: action.payload?.ammunition_status,
        DynamicFilterRegions: action.payload?.regions,
        DynamicFilterStockpileList: action.payload?.stockpile_list,
        DynamicFilterSecurityForces: action.payload?.security_forces,
        DynamicFilterDivisions: action.payload.divisions,
        DynamicFilterUnits: action.payload?.units,
        DynamicFilterFacilityList: action.payload?.facility_list,
        DynamicFilterTechnicalStatus: action.payload?.technical_status,
        DynamicFilterHazardDivision: action.payload?.hazard_division,
        DynamicFilterCompatibilityGroup: action.payload?.compatibility_group,
        DynamicFilterCountryList: action.payload?.countries_list,
        DynamicFilterExportingStates: action.payload?.exporting_state_list,
        DynamicFilterManufacturerList: action.payload?.manufacturer_list,
        DynamicFilterAmTagList: action.payload?.amtag_applied,
        DynamicFilterJvtStatus: action.payload?.jvt_verification_status,
        DynamicFilterAmmunitionTypes: action.payload?.ammunition_types,
        DynamicFilterAmmunitionRole: action.payload?.ammunition_role,
        DynamicAmmunitionListLoading: false,
        DynamicAmmunitionCalibre: action.payload?.ammunition_calibre,
        DynamicFilterDistrict: action.payload?.captured_district,
        DynamicFilterCapturedRegion: action.payload?.captured_region,
        DynamicFilterRankList: action.payload?.rank,
        DynamicFilterLsaNatureList: action.payload?.lsa_nature,
        DynamicFilterLsaRoleList: action.payload?.lsa_role,
        DynamicFilterLsaCategoryList: action.payload?.lsa_category,
        DynamicFilterAmmuHeadstamp: action.payload?.ammu_headstamp,
        DynamicFilterAmmuModel: action.payload?.ammu_model,
        DynamicFilterIsCapturedAmmuList: action.payload?.is_captured_ammunition,
      };
    case RESET_DYNAMIC_AMMUNITIONS_LIST:
      return {
        ...state,
        DynamicFilterAmmunitionStatus: [],
        DynamicFilterRegions: [],
        DynamicFilterStockpileList: [],
        DynamicFilterSecurityForces: [],
        DynamicFilterDivisions: [],
        DynamicFilterUnits: [],
        DynamicFilterFacilityList: [],
        DynamicFilterTechnicalStatus: [],
        DynamicFilterHazardDivision: [],
        DynamicFilterCompatibilityGroup: [],
        DynamicFilterCountryList: [],
        DynamicFilterExportingStates: [],
        DynamicFilterManufacturerList: [],
        DynamicFilterAmTagList: [],
        DynamicFilterJvtStatus: [],
        DynamicFilterAmmunitionTypes: [],
        DynamicFilterAmmunitionRole: [],
        DynamicAmmunitionCalibre: [],
        DynamicFilterDistrict: [],
        DynamicFilterCapturedRegion: [],
        DynamicFilterRankList: [],
        DynamicFilterLsaNatureList: [],
        DynamicFilterLsaRoleList: [],
        DynamicFilterLsaCategoryList: [],
        DynamicFilterAmmuHeadstamp: [],
        DynamicFilterAmmuModel: [],
        DynamicFilterIsCapturedAmmuList: [],
        DynamicAmmunitionListLoading: true,
      };

    case GET_DYNAMIC_TRANSFER_AMMUNITIONS:
      return {
        ...state,
        DynamicFilterTransAmmuStatus: action.payload.ammunition_status,
        DynamicFilterTransAmmuTypes: action.payload.ammunition_types,
        DynamicFilterTransAmmuCalibre: action.payload.ammunition_calibre,
        DynamicFilterTransAmmuRegions: action.payload.regions,
        DynamicFilterTransAmmuSecurityForces: action.payload.security_forces,
        DynamicFilterTransAmmuDivisions: action.payload.divisions,
        DynamicFilterTransAmmuUnits: action.payload.units,
        DynamicFilterTransAmmuFacility: action.payload.facility_list,
        DynamicFilterTransAmmuStockpileList: action.payload.stockpile_name,
        DynamicFilterTransAmmuAmTagList: action.payload.amtag_applied,
        DynamicTranAmmunListLoading: false,
      };
    case RESET_DYNAMIC_TRANSFER_AMMUNITIONS:
      return {
        ...state,
        DynamicFilterTransAmmuStatus: [],
        DynamicFilterTransAmmuTypes: [],
        DynamicFilterTransAmmuCalibre: [],
        DynamicFilterTransAmmuRegions: [],
        DynamicFilterTransAmmuSecurityForces: [],
        DynamicFilterTransAmmuDivisions: [],
        DynamicFilterTransAmmuUnits: [],
        DynamicFilterTransAmmuFacility: [],
        DynamicFilterTransAmmuStockpileList: [],
        DynamicFilterTransAmmuAmTagList: [],
        DynamicTranAmmunListLoading: true,
      };

    case GET_DYNAMIC_TRANSFER_WEAPONS:
      return {
        ...state,
        DynamicFilterTransWeaponStatus: action.payload.weapon_status,
        DynamicFilterTransWeaponTypes: action.payload.weapon_types,
        DynamicFilterTransWeaponCalibre: action.payload.weapon_calibre,
        DynamicFilterTransWeaponCondition: action.payload.condition_status,
        DynamicFilterTransWeaponOwnership: action.payload.ownership_status,
        DynamicFilterTransWeaponRegions: action.payload.regions,
        DynamicFilterTransWeaponSF: action.payload.security_forces,
        DynamicFilterTransWeaponDivisions: action.payload.divisions,
        DynamicFilterTransWeaponUnits: action.payload.units,
        DynamicFilterTransWeaponCaptured: action.payload.captured_status,
        DynamicTranWeaponListLoading: false,
      };
    case RESET_DYNAMIC_TRANSFER_WEAPONS:
      return {
        ...state,
        DynamicFilterTransWeaponStatus: [],
        DynamicFilterTransWeaponTypes: [],
        DynamicFilterTransWeaponCalibre: [],
        DynamicFilterTransWeaponCondition: [],
        DynamicFilterTransWeaponOwnership: [],
        DynamicFilterTransWeaponRegions: [],
        DynamicFilterTransWeaponSF: [],
        DynamicFilterTransWeaponDivisions: [],
        DynamicFilterTransWeaponUnits: [],
        DynamicFilterTransWeaponCaptured: [],
        DynamicTranWeaponListLoading: true,
      };

    case REHYDRATE:
    default:
      return state;
  }
};

export default DynamicAmmunitionReducer;
